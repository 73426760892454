import React from 'react';

import { Card } from './Card';
import { Loader } from '@c';

import styles from './style.module.scss';

const VideoSelector = ({
    videos = [],
    themeName = '',
    loading = false,
    onClick = () => {},
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <p>Список видео по теме &laquo;{themeName}&raquo;</p>
            </div>
            <div className={styles.videos_container}>
                {loading ? (
                    <Loader color="#006eff" />
                ) : videos.length === 0 ? (
                    <div className={styles.empty}>
                        <span>
                            Электронные материалы по данной теме отсутствуют
                        </span>
                    </div>
                ) : (
                    <div className={styles.not_empty}>
                        {videos.map((el, i) => (
                            <Card
                                info={el}
                                key={i}
                                onClick={() => onClick(i)}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export { VideoSelector };
