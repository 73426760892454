import React, { useContext } from 'react'

import { FiMic } from 'react-icons/fi'
import { BsFillKeyboardFill } from 'react-icons/bs'
import { Observer } from '@helps/'
import { DialogContext } from '../../utils'

import styles from './style.module.css'
import { useCallback } from 'react'

const onDialogInputEnter = new Observer()

const Input = ({
    // value = '',
    // setValue = () => { },
    // record = false,
    // setRecord = () => { },
    onEnter = () => { }
}) => {
    const { value, setValue, record, setRecord } = useContext(DialogContext)

    // const keyHandler = useCallback((e) => {
    //     if (e.keyCode === 13) {
    //         onDialogInputEnter.broadcast()
    //         onEnter()
    //     }
    // }, [onDialogInputEnter, onEnter])

    return (
        <div className={styles.questionBlock}>
            <div className={styles.question_block_content}>
                <div className={styles.question_block_content_message}>
                    {/* <p>{}</p> */}
                    <textarea
                        placeholder='Пока вы ничего не произнесли'
                        value={value}
                        onChange={({ target: { value } }) => {
                            setValue(value)
                        }}
                        // onKeyDown={keyHandler}
                    />
                </div>
                <BsFillKeyboardFill
                    size={25}
                />
                <div className={`${styles.mic_container} ${record && styles.mic_container_active}`}>
                    <FiMic
                        className={`${styles.icon} ${record && styles.rec}`}
                        onClick={() => {
                            if (!record) setValue('')
                            setRecord(!record)
                        }}
                        size={25}
                    />
                </div>
            </div>
        </div>
    )
}

export { Input, onDialogInputEnter }