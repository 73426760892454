import React, { useState } from 'react'
import { useEffect } from 'react'

import { ButtonsBlock } from '../'

import styles from './style.module.scss'

const JumbledWordsView = ({
    payload: {
        words = [],
        normalizedWords = [],
        image = '',
        title = '',
        selectedWords = [],
        minWords = 0
    }
}) => {
    const [selectedWordsCount, setSelectedWordsCount] = useState(0)

    useEffect(() => {
        let count = 0
        normalizedWords.forEach(el => selectedWords.indexOf(el) > -1 && (count += 1))
        setSelectedWordsCount(count)
    }, [selectedWords])

    return (
        <div className={styles.main}>
            <div className={styles.image_container}>
                <img src={image} alt={title} />
                <p>{title}</p>
            </div>
            <div className={styles.words}>
                <div>
                    {words.map((el, i) =>
                        <p
                            key={`${i}`}
                            className={selectedWords.indexOf(normalizedWords[i]) > -1 ? styles.selected_word : ''}
                        >
                            {el}
                        </p>
                    )}
                </div>
                <div className={styles.scores}>
                    Выбрано слов:
                    <p className={selectedWordsCount >= minWords ? styles.success : styles.fail}>
                        {selectedWordsCount}
                    </p>
                </div>
            </div>
            <ButtonsBlock />
        </div>
    )
}

export { JumbledWordsView }