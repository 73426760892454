import React, { useState, useEffect } from 'react';

import { getReviews } from '@a/course';
import { Loader } from '@c';

import { Review } from './Review';

import styles from './style.module.scss';

const Reviews = ({ themeId = null }) => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);

    const _getReviews = async () => {
        const result = await getReviews(themeId);

        if (result.status === 200) {
            setReviews(result.data);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (themeId === null) {
            setReviews([]);
            return;
        }

        setLoading(true);
        _getReviews();
    }, [themeId]);

    return (
        <div className={styles.container}>
            {loading ? (
                <Loader color="#006eff" />
            ) : reviews.length === 0 ? (
                <div className={styles.empty}>
                    <span>Отзывов пока нет</span>
                </div>
            ) : (
                <div className={styles.not_empty}>
                    {reviews.map((el, i) => (
                        <Review key={i} review={el} />
                    ))}
                </div>
            )}
        </div>
    );
};

export { Reviews };
