import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from '@h';
import { checkInformationPerceptType } from '@helps/checkInformationPerceptType';
import { Button } from '@c';
import { courseActions } from '@r/courses/courseSlice';
import { profileActions } from '@r/profile/profileSlice';

import { ViewContext, DialogContext } from '../../../utils';
import { ToMenuView } from './ToMenuView';
import { Lesson } from './Lesson';

import styles from './style.module.scss';

import katex from 'katex';
import 'katex/dist/katex.min.css';
window.katex = katex;

const ElectronicLesson = () => {
    const { setView, COMPONENT_NAME, setThemeSelector } =
        useContext(ViewContext);

    const { currentTheme } = useSelector(({ course }) => course);
    const { data: authData } = useSelector(({ auth }) => auth);

    const { resetCurrentTheme } = useActions(courseActions);
    const { changeSelectedCommand } = useActions(profileActions);

    const [toMenu, setToMenu] = useState(false);

    useEffect(() => {
        if (!checkInformationPerceptType(authData.typeInformationPercept)) {
            changeSelectedCommand('Тестирование');
        }
    }, [authData]);

    useEffect(() => {
        if (
            checkInformationPerceptType(authData.typeInformationPercept) &&
            !currentTheme?.id
        ) {
            setView(COMPONENT_NAME.PERFORMANCE);
        }
    }, [currentTheme, authData]);

    const _resetTheme = () => {
        setThemeSelector((prev) => ({
            ...prev,
            theme: null,
            checking: true,
        }));
        resetCurrentTheme();
    };

    return (
        <>
            <div className={styles.menu_button}>
                <Button onClick={() => setToMenu(true)}>В меню</Button>
            </div>
            <div className={styles.wrapper}>
                {toMenu && (
                    <ToMenuView
                        onCancel={() => setToMenu(false)}
                        onExit={() => (
                            setView(''), changeSelectedCommand('Команды')
                        )}
                    />
                )}
                {checkInformationPerceptType(
                    authData.typeInformationPercept
                ) && <Lesson onMenu={_resetTheme} />}
            </div>
        </>
    );
};

export { ElectronicLesson };
