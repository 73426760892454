import React, { useRef, useState, useCallback, useEffect } from 'react';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    removeElements,
    Controls,
    Background,
    updateEdge,
} from 'react-flow-renderer';

import { useAudio } from '@h';

import { Sidebar } from './Sidebar';
import { nodeTypes } from './Node';
import { edgeTypes } from './Edge';
import { ModalProvder } from './Modal';

import katex from 'katex';
import 'katex/dist/katex.min.css';
import { toClassNames } from '@helps';
window.katex = katex;

const _initElements = [
    {
        id: '1',
        type: 'start',
        position: {
            x: 320,
            y: 320,
        },
        data: {},
    },
    {
        id: '2',
        type: 'text',
        position: {
            x: 680,
            y: 320,
        },
        data: {
            text: '<p>Добро пожаловать {firstname} на электронное занятие по теме {theme}, сегодня {data}.</p>',
            images: [],
            videos: [],
            audios: [],
        },
    },
    {
        id: '3',
        type: 'finish',
        position: {
            x: 1280,
            y: 320,
        },
        data: {},
    },
    {
        source: '1',
        sourceHandle: 'out',
        target: '2',
        targetHandle: 'in',
        type: 'customedge',
        data: {},
        id: 'reactflow__edge-1out-2in',
    },
    {
        source: '2',
        sourceHandle: 'out',
        target: '3',
        targetHandle: 'in',
        type: 'customedge',
        data: {},
        id: 'reactflow__edge-2out-3in',
    },
];

const Constructor = ({
    initElements = _initElements,
    disabled = false,
    onSave = () => {},
    className = '',
    style = {},
    onReviews = () => {},
}) => {
    const reactFlowWrapper = useRef(null);

    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    const [elements, setElements] = useState([]);
    const [id, setId] = useState(0);

    const { initAudio } = useAudio();

    useEffect(() => {
        initAudio();
    }, []);

    const onLoad = (_instance) => setReactFlowInstance(_instance);

    const onRemove = (id) =>
        setElements((prev) =>
            removeElements([prev.find((el) => el.id === id)], prev)
        );

    const onChange = (id, _data) => {
        setElements((prev) => {
            const tmp = [...prev];
            const idx = tmp.findIndex((el) => el.id === `${id}`);
            tmp[idx].data = _data;

            return [...tmp];
        });
    };

    const onEdgeRemove = (id) =>
        setElements((prev) => {
            const elem = prev.find((el) => el.id === id);
            return elem ? removeElements([elem], prev) : prev;
        });

    const getId = () => {
        setId(id + 1);
        return `${id + 1}`;
    };

    const onDrop = (event) => {
        event.preventDefault();

        const reactFlowBounds =
            reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData('application/reactflow');
        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });

        const newNode = {
            id: getId(),
            type,
            position,
            data: {
                onChange,
                onRemove,
            },
        };

        setElements((prev) => {
            if (type === 'grade') {
                const questionBlocks = prev.filter((el) => el.type);
            }

            if (prev.length === 0) {
                return [newNode];
            } else {
                return [...prev, newNode];
            }
        });
    };

    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    const onElementsRemove = (elementsToRemove) =>
        setElements((els) => removeElements(elementsToRemove, els));

    const onConnect = (params) =>
        setElements((prev) =>
            !prev.find(
                (el) =>
                    el.sourceHandle === params.sourceHandle &&
                    el.source === params.source
            )
                ? addEdge(
                      { ...params, type: 'customedge', data: { onEdgeRemove } },
                      prev
                  )
                : prev
        );

    const onEdgeUpdate = (old, _new) =>
        setElements((prev) => updateEdge(old, _new, prev));

    const _onSave = useCallback(() => {
        if (reactFlowInstance) {
            let start = 0;
            let finish = 0;

            let sourceCount = 0;

            const edges = [];

            const elems = reactFlowInstance.toObject().elements.map((el) => {
                if (el.type === 'start') start++;
                if (el.type === 'finish') finish++;

                if (el.type === 'customedge') edges.push(el);
                else {
                    switch (el.type) {
                        case 'question':
                        case 'questionWithTypes':
                            sourceCount += 2;
                            break;
                        case 'finish':
                            break;
                        default:
                            sourceCount += 1;
                            break;
                    }
                }

                const tmp = {};
                Object.keys(el.data).map(
                    (key) =>
                        typeof el.data[key] !== 'function' &&
                        (tmp[key] = el.data[key])
                );
                el.data = tmp;
                return el;
            });

            if (start !== 1) {
                alert('У курса должен быть блок начала!\nСохранение прервано');
                return;
            }
            if (finish !== 1) {
                alert('У курса должен быть блок конца!\nСохранение прервано');
                return;
            }

            if (edges.length === sourceCount) onSave(elems);
            else alert('Все блоки должны быть соеденины!\nСохранение прервано');
        }
    }, [reactFlowInstance, onSave]);

    const clearTable = () => setElements([]);

    useEffect(() => {
        const promise = new Promise((res, rej) => {
            clearTable();
            res();
        });

        promise.then(() => {
            let _id = 0;
            const init =
                Array.isArray(initElements) && initElements.length > 0
                    ? initElements
                    : _initElements;

            const tmp = init.map((el) => {
                const tmp = { ...el };
                if (Object.keys(nodeTypes).indexOf(tmp.type) !== -1) {
                    if (tmp.id > _id) _id = parseInt(tmp.id);
                    tmp.data = {
                        ...tmp.data,
                        onChange,
                        onRemove,
                    };
                } else {
                    tmp.data = {
                        ...tmp.data,
                        onEdgeRemove,
                    };
                }

                return tmp;
            });

            setElements(tmp);
            setId(_id);
        });
    }, [initElements]);

    return (
        <ModalProvder>
            <div
                className={toClassNames(className)}
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    ...style,
                }}
            >
                <ReactFlowProvider>
                    <div
                        ref={reactFlowWrapper}
                        style={{ width: '100%', backgroundColor: '#F7F7F7' }}
                    >
                        <ReactFlow
                            onLoad={onLoad}
                            elements={elements}
                            onDrop={onDrop}
                            onDragOver={onDragOver}
                            nodeTypes={nodeTypes}
                            onElementsRemove={onElementsRemove}
                            onConnect={onConnect}
                            edgeTypes={edgeTypes}
                            onEdgeUpdate={onEdgeUpdate}
                            snapToGrid={true}
                            snapGrid={[40, 40]}
                        >
                            <Controls />
                            <Background />
                        </ReactFlow>
                    </div>
                    <Sidebar
                        onSave={_onSave}
                        disabled={disabled}
                        onReviews={onReviews}
                    />
                </ReactFlowProvider>
            </div>
        </ModalProvder>
    );
};

export { Constructor };
