import React, { useEffect, useContext, useMemo, useRef } from 'react';

import { useAudio } from '@h';
import { useLoadAudioFile } from '@h/useLoadAudioFile';
import { AleshaHeadContext, DialogContext } from '../../../utils';

import styles from './style.module.scss';

const TextView = ({ text = [''], voiceURL = '' }) => {
    const { setRecord, setValue, resetText } = useContext(DialogContext);
    const { stopAudio } = useAudio();
    const { setAleshaSpeaking } = useContext(AleshaHeadContext);

    /**@type {HTMLAudioElement} */
    const audio = useMemo(() => new Audio(), []);
    const dataPromiseCancelRef = useRef(null);
    const { getFileUrl } = useLoadAudioFile(dataPromiseCancelRef);

    const getAndPlayAudio = async (text) => {
        getFileUrl(
            text.join(' '),
            () => {
                stopAudio();
                audio.pause();
                audio.currentTime = 0;
            },
            (url) => playAudioFile(url)
        );
    };

    const playAudioFile = (url) => {
        audio.src = url;
        audio.onended = () => {
            // Отключаем анимацию
            setAleshaSpeaking(false);
            // Включаем микрофон
            setRecord(true);
        };
        audio
            .play()
            .then((_) => {
                setAleshaSpeaking(true);
                setRecord(false);
            })
            .catch((err) => {
                console.error(err);
                setAleshaSpeaking(false);
                setRecord(true);
            });
        setValue('');
        resetText();
    };

    const _stopAudio = () => {
        if (
            !dataPromiseCancelRef.current.finished &&
            !dataPromiseCancelRef.current.cancelled
        ) {
            console.log('canceling');
            dataPromiseCancelRef.current.cancel();
        }
        setValue('');
        setAleshaSpeaking(false);
        setRecord(true);
        audio.pause();
        stopAudio();
    };

    useEffect(() => {
        if (voiceURL) playAudioFile(voiceURL);
        else getAndPlayAudio(text);

        return () => {
            _stopAudio();
        };
    }, []);

    return (
        <div className={styles.availableCommands}>
            {text.map((el, idx) => (
                <p key={`${idx}`}>{el}</p>
            ))}
        </div>
    );
};

export { TextView };
