import React, { useEffect, useContext, useCallback, useState } from 'react';

import { useTimer } from '@h';

import { ViewContext, DialogContext } from '../../../../../../utils';

import styles from './style.module.scss';

const ReadingSpeed = ({
    index = null,
    question = '',
    isAudioFinish,
    setValue = () => {},
    value = '',
}) => {
    const { setLessonPayload } = useContext(ViewContext);
    const { setRecord, setTextCleanup } = useContext(DialogContext);

    const { seconds, start, stop, reset } = useTimer();

    const [_value, _setValue] = useState('');

    const onStop = useCallback(
        (seconds) => {
            if (seconds) {
                setLessonPayload((prev) => ({ ...prev, stoped: true }));
                stop();
                setRecord(false);
            }
        },
        [seconds, _value]
    );

    useEffect(() => {
        const minutes = seconds / 60;
        const length = [
            ...value
                .replace(
                    /<\w+\/>|<\w+>|<\/\w+>|[$&+,:;=?@#|'<>.^*()%!-"–—]/gm,
                    ''
                )
                .split('\n')
                .map(
                    (el) =>
                        el
                            .trim()
                            .replace(/\s{2,}/gm, '')
                            .split(' ').length
                ),
        ].reduce((acc, curr) => acc + curr);
        const speed = length / minutes;

        setLessonPayload((prev) => ({
            ...prev,
            speed,
            currentLenght: length,
            stoped: false,
        }));
    }, [seconds]);

    const onReset = () => {
        setValue('');
        reset();
        start();
        setRecord(true);
    };

    useEffect(() => {
        setLessonPayload((prev) => ({ ...prev, onStop, onReset }));
        setTextCleanup(false);

        return () => {
            setTextCleanup(true);
        };
    }, []);

    useEffect(() => {
        if (isAudioFinish) start();
    }, [isAudioFinish]);

    useEffect(() => {
        setLessonPayload((prev) => ({ ...prev, seconds }));
        if (seconds >= 60) {
            onStop(seconds);
        }
    }, [seconds]);

    return (
        <div className={styles.main}>
            <p className={styles.header}>
                {index !== null && index}. {question}
            </p>
        </div>
    );
};

export { ReadingSpeed };
