import React, { createContext, useState, useEffect } from 'react';

import { COMPONENT_NAME } from '../Menu';

/**
 * @typedef {'wiki'|'user'} WikiRecordType
 */

/**
 * @typedef {Object} WikiRecordImages
 * @property {String} main
 * @property {Array<String>} all
 */

/**
 * @typedef {Object} Video
 * @property {String} id
 * @property {String} service
 * @property {String} title
 * @property {String} description
 * @property {String} preview
 * @property {String} viewCount
 * @property {String} likeCount
 */

/**
 * @typedef {Object} WikiRecord
 * @property {Number} id
 * @property {WikiRecordType} type
 * @property {String} name
 * @property {String} extract
 * @property {Number} likes
 * @property {Number} dislikes
 * @property {null|String} updated
 * @property {Array<WikiRecord>} otherRecords
 * @property {WikiRecordImages} [images]
 * @property {Array<Video>} [videos]
 * @property {String} [link]
 * @property {Number} [user_id]
 * @property {String} [user_name]
 * @property {String} [create_date]
 */

/**
 * @callback setInfoCallback
 * @param {WikiRecord} pervState
 * @returns {WikiRecord}
 */

/**Установка значения для info если поиходит функция, передаем в нее
 * предыдущее значение и в info ставим результат выполнения функции, иначе
 * просто значение
 * @callback setInfoFunction
 * @param {setInfoCallback|WikiRecord} callback
 * @returns {void}
 */

/**
 * @typedef {Object} WhoIsPayload
 * @property {Boolean} yesNo - Для переключения логики на компонент диалога
 * @property {null|String} text - Запрос
 * @property {WikiRecord} info - Информация из вики
 * @property {setInfoFunction} setInfo - Информация из вики
 * @property {'display'|'add'|'edit'} type - какой экран отображать
 * @property {Function} playAnswer - проиграть аудио из компонента диалога
 * @property {Boolean} showExpectedResponse - отображать или нет подсказки в диалоге
 * @property {Boolean} answerConfirmed - Пользовательзакончил диктовать свой ответ
 * @property {Function} stopPrevAudio - остановка звука, воспроизводимого до этого
 */

/**
 * @callback WhoIsPayloadSetter
 * @param {WhoIsPayload} pervState
 * @returns {WhoIsPayload}
 */

/**
 * @typedef {Object} ThemeSelector
 * @property {Boolean} checking
 * @property {Boolean} cleaningAfterThemeSelect
 * @property {Array} clarification
 * @property {Array} themes
 * @property {Object|null} course
 * @property {Object|null} theme
 */

const viewInitState = {
    /**@type {WhoIsPayload} */
    whoIsPayload: {
        yesNo: false,
        text: null,
        info: {},
        setInfo(callback = () => {}) {
            if (typeof callback === 'function') {
                this.info = callback(this.info) || null;
            } else {
                this.info = callback;
            }
        },
        type: 'display',
        playAnswer: (command) => {},
        showExpectedResponse: false,
        answerConfirmed: false,
    },
    /**@type {ThemeSelector} */
    themeSelector: {
        cleaningAfterThemeSelect: false,
        checking: true,
        clarification: [],
    },
    disableMenuButton: false,
};

/**
 * @callback setViewFunction
 * @param {string} viewName
 * @returns {void}
 */

/**
 * @typedef {Object} ViewContextObject
 * @property {COMPONENT_NAME} COMPONENT_NAME - названия экрана
 * @property {String} view - выбранные экран
 * @property {setViewFunction} setView - установка экрана меню
 * @property {ThemeSelector} themeSelector
 * @property {Funtion} setThemeSelector
 * @property {Object} lessonPayload
 * @property {Funtion} setLessonPayload
 * @property {Object} control
 * @property {Funtion} setControl
 * @property {WhoIsPayload} whoIsPayload - данные для экранов поиска по вики
 * @property {WhoIsPayloadSetter} setWhoIsPayload - изменение данных поиска по вики
 * @property {Object} avatarsPayload - данные для аватара
 * @property {Funtion} setAvatarsPayload - изменение данных для аватара
 * @property {Boolean} disableMenuButton - данные для аватара
 * @property {Funtion} setDisableMenuButton - изменение данных для аватара
 */

/**@type {ViewContextObject} */
const initContext = {
    COMPONENT_NAME,
    view: '',
    setView: () => {},
    themeSelector: {
        ...viewInitState.themeSelector,
    },
    setThemeSelector: () => {},
    lessonPayload: {},
    setLessonPayload: () => {},
    control: {},
    setControl: () => {},
    whoIsPayload: {
        ...viewInitState.whoIsPayload,
        stopPevAudio: () => {},
    },
    setWhoIsPayload: () => {},
    avatarsPayload: {},
    setAvatarsPayload: () => {},
    disableMenuButton: viewInitState.disableMenuButton,
    setDisableMuneButton: () => {},
};

const ViewContext = createContext(initContext);

const ViewContextProvider = ({ children }) => {
    // Текущий экран меню
    const [view, setView] = useState();
    // Объект для обмена данными между диалогом и экранов в режиме
    // тестирования на тип восприяти
    const [lessonPayload, setLessonPayload] = useState({});
    // Объект для обмена данными между диалогом и экраном
    // ответа на "что такое"
    /**@type {[WhoIsPayload, WhoIsPayloadSetter]} */
    const [whoIsPayload, setWhoIsPayload] = useState({
        ...viewInitState.whoIsPayload,
        stopPevAudio: () => {},
    });
    // Объект для обмена данными между диалогом и экраном прохождения
    // теста на тип восприяти для работы с кнопками перехода к следующему вопросу
    const [control, setControl] = useState({});
    // Объект для обмена данными между диалогом и экраном
    // выбора аватара
    const [avatarsPayload, setAvatarsPayload] = useState({});
    // Объект для обмена данными между диалогом и экраном
    // выбора темы
    /**@type {[ThemeSelector, Any]} */
    const [themeSelector, setThemeSelector] = useState({
        ...viewInitState.themeSelector,
    });
    // Блокирование кнопки меню
    const [disableMenuButton, setDisableMenuButton] = useState(
        viewInitState.disableMenuButton
    );

    return (
        <ViewContext.Provider
            value={{
                COMPONENT_NAME,
                view,
                setView,
                lessonPayload,
                setLessonPayload,
                control,
                setControl,
                whoIsPayload,
                setWhoIsPayload,
                avatarsPayload,
                setAvatarsPayload,
                themeSelector,
                setThemeSelector,
                disableMenuButton,
                setDisableMenuButton,
            }}
        >
            {children}
        </ViewContext.Provider>
    );
};

export { ViewContext, ViewContextProvider, viewInitState };
