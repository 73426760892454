import { useEffect, useState, useCallback, useRef } from 'react';
import Recorder from 'opus-recorder';

import { usePrevious } from './';

/**
 * @typedef {import('socket.io-client').Socket} Socket
 */

/**
 * Перевод голоса в текст
 * @param {Socket} socket
 * @param {'google'|'yandex'} translater
 * @returns
 */
const useSTT = (socket = null, translater = 'yandex') => {
    // Объек рекордера
    /** @type {[null|Object, Function]} */
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const mediaRecorderRef = useRef(null);
    mediaRecorderRef.current = mediaRecorder;
    // Записть аудио вкл/выкл
    const [record, setRecord] = useState(false);
    const prevRecord = usePrevious(record);

    // Объект с переводом голоса в тектс
    const [translated, setTranslated] = useState(null);
    // Только текст ответа
    const [translatedText, setTranslatedText] = useState('');
    // Промежуточное значение
    const [intermediateText, setIntermediateText] = useState('');
    const [finishedText, setFinishedText] = useState('');

    useEffect(() => {
        // Обноружение устройтв
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            console.log('getUserMedia supported.');
            // Разрешение на записть
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then(() => {
                    if (!Recorder.isRecordingSupported()) {
                        alert(
                            'Recording features are not supported in your browser.'
                        );
                    } else {
                        // Создание объекта рекордера
                        setMediaRecorder(
                            new Recorder({
                                encoderPath: 'workers/encoderWorker.min.js',
                                encoderApplication: 2048,
                                encoderSampleRate: 48000,
                                streamPages: true,
                            })
                        );
                    }
                    // setMediaRecorder(new MediaRecorder(stream))
                })
                .catch((err) => {
                    alert('The following getUserMedia error occured: ' + err);
                });
        } else {
            alert('getUserMedia not supported on your browser!');
        }
    }, []);

    useEffect(() => {
        console.log(mediaRecorder);
    }, [mediaRecorder]);

    // Запуск/остановка перевода
    useEffect(() => {
        if (mediaRecorder) {
            console.info('CHANGE RECORD. (PREV/CURR)', prevRecord, record);
            // console.warn('CHANGE RECORD. (PREV/CURR)', prevRecord, record);
            if (record) {
                mediaRecorder.start();
                console.log(mediaRecorder);
                console.log(`recording start with translater ${translater}`);
            } else {
                mediaRecorder.stop();
                setFinishedText('');
                setIntermediateText('');
                setTranslatedText('');
                console.log(`recording stop with translater ${translater}`);
            }
        }
    }, [record, mediaRecorder]);

    // Создание обработчиков
    useEffect(() => {
        if (mediaRecorder && socket) {
            mediaRecorder.audioChannels = 1;

            mediaRecorder.onerror = () => {
                console.log('error');
            };

            mediaRecorder.ondataavailable = function (e) {
                if (record) {
                    console.log(`sending chunk with translater ${translater}`);
                    socket.emit('voice', {
                        translater,
                        blob: new Blob([e]),
                    });
                }
            };

            mediaRecorder.onstop = async (e) => {
                console.log('stop');
                socket.emit('stop-voice');
            };
        }
    }, [mediaRecorder, socket, record]);

    useEffect(() => {
        if (
            finishedText.toLowerCase().trim() !==
            intermediateText.toLowerCase().trim()
        ) {
            setTranslatedText(`${finishedText} ${intermediateText}`.trim());
        }
    }, [finishedText, intermediateText]);

    const [timeout, _setTimeout] = useState(null);

    const updateText = (response) => {
        switch (response.translater) {
            case 'yandex':
                if (response.isFinal) {
                    setFinishedText((prev) => {
                        if (
                            prev
                                .toLowerCase()
                                .includes(finishedText.toLowerCase())
                        ) {
                            return response.alternatives[0].text;
                        } else {
                            return `${prev} ${response.alternatives[0].text}`;
                        }
                    });
                } else {
                    setIntermediateText(response.alternatives[0].text);
                }
                break;
            case 'google':
                setTranslatedText(
                    response.results[0].alternatives[0].transcript
                );
                break;
            default:
                break;
        }
    };

    // Обработка ответов
    useEffect(() => {
        if (socket && mediaRecorder) {
            socket.on('voice-response', (_response) => {
                // setLastResponse(_response);
                _setTimeout(
                    setTimeout(() => {
                        updateText(_response);
                        _setTimeout(null);
                    }, 300)
                );
                if (!timeout) {
                    setTranslated(_response);
                    updateText(_response);
                }

                // if (response.isFinal) {
                //     setRecord(false)
                //     mediaRecorder.stop()
                // }
            });

            socket.on('utterance-end', () => {
                setRecord(false);
                _setTimeout(null);
                mediaRecorder.stop();
            });

            socket.on('restart', () => {
                setRecord(true);
                _setTimeout(null);
                mediaRecorder.start();
            });

            socket.on('error', (err) => {
                console.log(err);
                if (mediaRecorder.state === 'recording') {
                    setRecord(false);
                    _setTimeout(null);
                    mediaRecorder.stop();
                }
            });

            // socket.on('test', (res) => console.log(res))

            // socket.emit('test')
        }
    }, [socket, mediaRecorder]);

    const resetText = useCallback(() => {
        clearTimeout(timeout);
        setTimeout(null);
        setIntermediateText('');
        setTranslatedText('');
        setTranslated(null);
        setFinishedText('');
    }, [setFinishedText, setIntermediateText, mediaRecorder]);

    const deleteRecorder = useCallback(() => {
        setMediaRecorder(null);
    }, [mediaRecorder]);

    const closeRecord = useCallback(() => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.pause();
            mediaRecorderRef.current.close();
            setMediaRecorder(null);
        }
    }, [mediaRecorderRef]);

    return {
        mediaRecorder,
        closeRecord,
        record,
        setRecord,
        translated,
        translatedText,
        resetText,
        deleteRecorder,
    };
};

export { useSTT };
