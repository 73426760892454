import React, { useState } from "react"
import { FiPlay, FiPause } from "react-icons/fi";

import { useAudio } from '@h'

import styles from './style.module.css'

const AudioPlayer = ({
    url = '',
    name = ''
}) => {
    const [isAudioPlay, setIsAudioPlay] = useState(false)
    const onAudioEnded = () => setIsAudioPlay(false)

    const { playAudio, stopAudio } = useAudio(onAudioEnded)

    return (
        <div className={styles.audio}>
            <div
                className={styles.audio_button}
                onClick={() =>
                    isAudioPlay ?
                        (stopAudio(), setIsAudioPlay(false))
                        :
                        (playAudio(
                            /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]/g.test(url) ? url : `${process.env.REACT_APP_API_URL}/${url}`
                        ), setIsAudioPlay(true))}
            >
                {isAudioPlay
                    ?
                    <FiPause
                        className={styles.pause}
                        color="white"
                        size={15}
                    />
                    :
                    <FiPlay
                        className={styles.play}
                        color="white"
                        size={15}
                    />
                }
            </div>
            <p className={styles.audio_title}>{name || 'Воспроизвести'}</p>
        </div>
    )
}

export { AudioPlayer }