import React from 'react';
import YouTube from 'react-youtube';

const YouTubePlayer = ({ videoId = '' }) => {
    return (
        <YouTube
            videoId={videoId}
            showCaptions={false}
            annotations={false}
            showRelatedVideos={false}
            id={'player'}
        />
    );
};

export { YouTubePlayer };
