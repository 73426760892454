import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Chart, Loader, Button } from '@c';
import { DATE } from '@consts/api';
import { useDate, toClassNames, getUserAvatar } from '@helps/';
import { ViewContext } from '../../../../utils';

import styles from './style.module.scss';

const SelectTheme = ({
    themes = [],
    currentCourse = {},
    onThemeSelect = () => {},
    onCourseSelect = () => {},
}) => {
    const { setThemeSelector } = useContext(ViewContext);

    const { isLoading } = useSelector(({ course }) => course);

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <Button
                    className={styles.back_button}
                    onClick={() => {
                        setThemeSelector((prev) => ({
                            ...prev,
                            clarification: [],
                        }));
                        onCourseSelect(null);
                    }}
                >
                    К выбору дисциплины
                </Button>
                <p className={styles.title}>
                    Список занятий по &laquo;{currentCourse.CourseName}&raquo;
                    для самостоятельной подготовки:
                </p>
            </div>
            {isLoading ? (
                <Loader color="#006eff" />
            ) : !!themes && themes.length > 0 ? (
                <div className={styles.tableWrapper}>
                    <div className={styles.row}>
                        <div className={styles.themeDate}>Дата</div>
                        <div className={styles.themeName}>Тема</div>
                        <div className={styles.themeGrade}>Оценка</div>
                        <div
                            className={styles.themeHomework}
                            title="Домашнее задание"
                        >
                            Д/З
                        </div>
                        <div
                            className={styles.kpaz}
                            title="Количество проведенных электронных занятий"
                        >
                            КПЭЗ
                        </div>
                    </div>
                    {themes.map(
                        (item, i) =>
                            item.theme && (
                                <div
                                    key={`${i}`}
                                    className={toClassNames(
                                        styles.row,
                                        item.grade < 4 && styles.parity
                                    )}
                                    onClick={() => onThemeSelect(item)}
                                >
                                    <div className={styles.themeDate}>
                                        {item.lessonDate.split(' ')[0]}
                                    </div>
                                    <div className={styles.themeName}>
                                        {item.theme}
                                    </div>
                                    <div className={styles.themeGrade}>
                                        {item.grade}
                                    </div>
                                    <div className={styles.themeHomework}>
                                        {item.homework}
                                    </div>
                                    <div
                                        className={styles.kpaz}
                                        title="Количество проведенных электронных занятий"
                                    >
                                        {item.attempts ? item.attempts : 0}
                                    </div>
                                </div>
                            )
                    )}
                </div>
            ) : (
                <div className={styles.alert}>
                    <p className={styles.text}>
                        Темы по выбранному предмету не найдены
                    </p>
                </div>
            )}
        </div>
    );
};

const ClarificationCourseSelector = ({ onCourseSelect = () => {} }) => {
    const { date } = useDate();

    const { authData } = useSelector(({ auth }) => ({
        authData: auth.data,
    }));
    const { themeSelector } = useContext(ViewContext);

    return (
        <div className={styles.clarification}>
            <span className={styles.title}>
                Успеваемость {authData.firstname} за период с 01.09.2021г. по{' '}
                {date}г.
            </span>
            <div className={styles.tableWrapper}>
                <div className={styles.row}>
                    <div className={toClassNames(styles.number, styles.header)}>
                        Номер
                    </div>
                    <div className={toClassNames(styles.name, styles.header)}>
                        Название
                    </div>
                </div>

                {themeSelector.clarification.map((el, i) => (
                    <div
                        className={styles.row}
                        key={i}
                        onClick={() =>
                            onCourseSelect(themeSelector.clarification[i])
                        }
                    >
                        <div className={styles.number}>{i + 1}</div>
                        <div className={styles.name}>{el.CourseName}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const ThemeSelector = ({
    selectedCourse = null,
    onCourseSelect = () => {},
    onThemeSelect = () => {},
    themes = [],
}) => {
    const { themeSelector } = useContext(ViewContext);

    const { date } = useDate();

    const { authData } = useSelector(({ auth }) => ({
        authData: auth.data,
    }));
    const { performance, isLoading } = useSelector(({ profile }) => profile);

    const _performance = useMemo(() => {
        let tmp = [...performance];
        tmp = tmp.filter((el) => el.AverageGrade !== null);
        tmp = tmp.map((el) => {
            tmp = { ...el };
            tmp.caption = el.CourseName.replace('*', '').trim();
            tmp.status = el.AverageGrade * 20;
            return tmp;
        });
        return tmp;
    }, [performance]);

    const [img, setImg] = useState(null);

    useEffect(() => {
        if (!img) {
            getUserAvatar(authData?.uid).then((res) => setImg(res));
        }
    }, [authData, img]);

    useEffect(() => {
        onCourseSelect(themeSelector.course);
    }, [themeSelector.course]);

    useEffect(() => {
        onThemeSelect(themeSelector.theme);
    }, [themeSelector.theme]);

    return (
        <>
            {isLoading ? (
                <Loader color="#006eff" />
            ) : selectedCourse ? (
                <SelectTheme
                    themes={themes}
                    currentCourse={selectedCourse}
                    onCourseSelect={(val) => {
                        onCourseSelect(val);
                    }}
                    onThemeSelect={(val) => {
                        onThemeSelect(val);
                    }}
                />
            ) : themeSelector.clarification.length > 0 ? (
                <ClarificationCourseSelector onCourseSelect={onCourseSelect} />
            ) : (
                <div className={styles.radar}>
                    <p className={styles.title}>
                        {/* {`Успеваемость ${authData.firstname} за период с 01.09.2021г. по ${date}г.`} */}
                        {`Успеваемость 
                        ${authData.firstname}
                        за период с 
                        ${moment(DATE, 'YYYY-MM-DD')
                            .subtract(1, 'year')
                            .format('DD.MM.YYYY')}г.
                        по 
                        ${moment(DATE, 'YYYY-MM-DD').format('DD.MM.YYYY')}г.`}
                    </p>
                    {_performance.length === 0 ? (
                        <div className={styles.empty}>
                            <p>Успеваймость за данный период не найдена</p>
                        </div>
                    ) : (
                        <Chart
                            data={_performance}
                            imageUrl={img}
                            onLabelClick={({ index: i }) => {
                                onCourseSelect(_performance[i]);
                            }}
                            style={{
                                position: 'absolute',
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export { ThemeSelector };
