import React, { useState } from 'react';

import { AudioPlayer, Corusel, YouTubeCorusel } from '@c/';
import { addVideoLike, deleteVideoLike, addVideoView } from '@a/course';

import styles from './style.module.scss';

const Text = ({ data = {} }) => {
    const [_data, setData] = useState({ ...data });
    const [likeAdding, setLikeAdding] = useState(false);

    const addLike = (videoId, index) => {
        let action = null;

        if (!_data.videosInfo[index].like_from_user) {
            action = addVideoLike(videoId);
        } else {
            action = deleteVideoLike(videoId);
        }

        action
            .then((res) => {
                if (res.status === 200) {
                    setData((prev) => {
                        const tmp = [...prev.videosInfo];
                        tmp[index] = res.data;
                        return { ...prev, videosInfo: tmp };
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLikeAdding(false);
            });
    };

    return (
        <div className={styles.data_container}>
            <div
                dangerouslySetInnerHTML={{ __html: _data.text }}
                className={styles.text}
            ></div>
            <div className={styles.payload}>
                {_data.images.length > 0 && (
                    <div className={styles.image_container}>
                        <Corusel
                            items={_data.images.map((el, i) => (
                                <img src={el} alt={`courusel ${i}`} />
                            ))}
                        />
                    </div>
                )}
                {_data.videos.length > 0 && (
                    <div className={styles.video_container}>
                        <YouTubeCorusel
                            videos={_data.videos}
                            infos={_data.videosInfo}
                            onLike={(videoId, index) => {
                                setLikeAdding(true);
                                addLike(videoId, index);
                            }}
                            likeAdding={likeAdding}
                        />
                    </div>
                )}
                {_data.audios.length > 0 && (
                    <div className={styles.audio_container}>
                        <AudioPlayer
                            withoutHook={true}
                            audios={_data.audios}
                            containerClassName={styles.audios}
                        />

                        {/* {_data.audios?.map((el, i) => (
                            <AudioPlayer key={i} url={el.src} name={el.name} />
                        ))} */}
                    </div>
                )}
            </div>
        </div>
    );
};

export { Text };
