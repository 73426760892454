import React, { useEffect, useContext, useState, useMemo, useRef } from 'react';

import {
    AleshaHeadContext,
    aleshaHeadContextInitState,
    DialogContext,
    ViewContext,
} from '../../../utils';
import { api } from '../../../../../App';
import { useAudio, useActions } from '@h';
import { useLoadAudioFile } from '@h/useLoadAudioFile';
import { profileActions } from '@r/profile/profileSlice';

import styles from './style.module.scss';

const ChangeAvatarView = ({ onListLoad = () => {} }) => {
    const {
        value,
        setValue,
        resetText,
        setSelectedCommand,
        results,
        setCheckCommands,
        setRecord,
        onStageChange,
        record,
        clean,
    } = useContext(DialogContext);
    const { stopAudio } = useAudio();
    const { setAleshaSpeaking, setHeadData, headData } =
        useContext(AleshaHeadContext);
    const { setView, view, COMPONENT_NAME } = useContext(ViewContext);

    const { changeVoiceOptions } = useActions(profileActions);

    const [list, setList] = useState([]);
    const [variants, setVariant] = useState(['назад']);
    const [selectedVariant, setSelectedVariand] = useState(null);
    const [loaded, setLoaded] = useState(false);

    /**@type {HTMLAudioElement} */
    const audio = useMemo(() => new Audio(), []);
    const dataPromiseCancelRef = useRef(null);
    const { getFileUrl } = useLoadAudioFile(dataPromiseCancelRef);

    const getList = () => {
        api.get('/commands/avatarlist').then((res) => {
            if (res.status === 200) {
                setList(res.data);
                onListLoad(res.data);
                setVariant((prev) => [
                    ...prev,
                    ...res.data.map((el) => el.name.toLowerCase()),
                ]);
            }
        });
    };

    const getAndPlayAudio = (text = '') => {
        getFileUrl(
            text === '' ? 'Выбери один из вариантов или скажи "назад".' : text,
            () => {
                stopAudio();
                audio.pause();
                audio.currentTime = 0;
                setRecord(false);
                setAleshaSpeaking(false);
                !loaded && setLoaded(true);
            },
            (url) => playAudioFile(url)
        );
    };

    const playAudioFile = (url) => {
        audio.src = url;
        audio.onended = () => {
            // Отключаем анимацию
            setAleshaSpeaking(false);
            // Включаем микрофон
            setRecord(true);
        };
        audio.play();
        setAleshaSpeaking(true);
        setRecord(false);
        setValue('');
        resetText();
    };

    const _stopAudio = () => {
        if (
            !dataPromiseCancelRef.current.finished &&
            !dataPromiseCancelRef.current.cancelled
        ) {
            console.log('canceling');
            dataPromiseCancelRef.current.cancel();
        }
        setValue('');
        setAleshaSpeaking(false);
        setRecord(true);
        audio.pause();
        stopAudio();
    };

    useEffect(() => {
        if (view !== COMPONENT_NAME.AVATARS) {
            setValue('');
            setSelectedCommand(null);
        }
    }, [view]);

    useEffect(() => {
        onStageChange(variants);
    }, [variants]);

    useEffect(() => {
        setCheckCommands(false);
        setValue('');
        getList();
        getAndPlayAudio();

        return () => {
            _stopAudio();

            setView(null);
            setCheckCommands(true);
            resetText();
        };
    }, []);

    useEffect(() => {
        if (selectedVariant === 1) {
            _stopAudio();
            setHeadData(aleshaHeadContextInitState.headData);
            changeVoiceOptions('Алёша');
            setValue('');
        } else if (selectedVariant) {
            _stopAudio();
            setHeadData(list[selectedVariant - 1]);
            changeVoiceOptions(list[selectedVariant - 1]?.name);
            setValue('');
        }
    }, [selectedVariant]);

    useEffect(() => {
        if (selectedVariant !== null) {
            getAndPlayAudio('Хорошо, теперь я буду выглядеть так.');
        }
    }, [headData]);

    useEffect(() => {
        if (loaded && results.filter((el) => el >= 0.5).length > 0) {
            setSelectedVariand(results.indexOf(Math.max(...results)));
            clean([value]);
            setValue('');
            resetText();
        }
    }, [results]);

    useEffect(() => {
        if (/назад/gm.test(value.toLowerCase())) {
            clean([value]);
            setValue('');
            resetText();
            setSelectedCommand(null);
        }
    }, [value]);

    return (
        <div className={styles.availableCommands}>
            <p>Выбери один из вариантов или скажи "назад"</p>
            <div className={styles.commands_list}>
                {list.map((el, i) => (
                    <div
                        key={`${i}`}
                        className={styles.list_item}
                        onClick={() => setSelectedVariand(i + 1)}
                    >
                        <p>{el?.name}</p>
                    </div>
                ))}
            </div>
            <div
                className={styles.cancel}
                onClick={() => setSelectedCommand(null)}
            >
                <p>Назад</p>
            </div>
        </div>
    );
};

export { ChangeAvatarView };
