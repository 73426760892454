import React from 'react';

import { useDrop } from 'react-dnd';

import { Item } from '../Item';

import styles from './style.module.scss';

const Container = ({
    accept = [],
    droppedItems = [],
    onDrop = () => {},
    description = '',
    audio = null,
}) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        drop: onDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    return (
        <div className={styles.root} ref={drop} role="Container">
            <img
                className={styles.basket}
                src={`${process.env.PUBLIC_URL}/images/корзина.png`}
                onDragStart={(e) => e.preventDefault()}
                style={{
                    width: isOver ? '160%' : '150%',
                }}
            />
            <p className={styles.description}>{description}</p>
            {droppedItems.map((el, i) => (
                <Item
                    key={`${i}`}
                    item={el}
                    isDropped={false}
                    isOverOnContainer={isOver}
                    audio={audio}
                />
            ))}
        </div>
    );
};

export { Container };
