import React, { useEffect, useState } from "react"
import { Handle, useStoreState } from 'react-flow-renderer'
import YouTube from 'react-youtube'

import { FiTrash2 } from 'react-icons/fi'

import styles from './style.module.css'

const VideoDemonstration = ({
    onDragStart = () => { }
}) => {
    return (
        <div className={styles.container_demonstration} onDragStart={onDragStart} draggable>
            <p>Видео</p>
        </div>
    )
}

const dataInit = {
    url: '',
    id: '',
    onChange: () => { },
    onRemove: () => { }
}

const Video = ({
    id = '',
    data = {}
}) => {
    const [_data, setData] = useState({ ...dataInit, ...data })

    const [, , zoom] = useStoreState(state => state.transform)
    const showContent = zoom >= 0.6

    useEffect(() => {
        _data.onChange(id, _data)
    }, [_data, id])

    return (
        <div className={styles.container}>
            <Handle type='target' position='left' id='in' className={styles.point_in} />
            <FiTrash2
                className={styles.icon}
                onClick={() => data.onRemove(id)}
            />
            <p>Видео</p>
            {showContent ?
                <>
                    <input
                        value={_data.url}
                        onChange={({ target: { value } }) => {
                            const matchs = /(\.\w+\/\w+\?v=)([^ ]*)|(\.\w+\/)([^ ]*)/g.exec(value)
                            let id = matchs[2] || matchs[4] || ''
                            setData(prev => ({ ...prev, url: value, id }))
                        }}
                        placeholder='Ссылка на видео'
                    />
                    {_data.id ?
                        <YouTube containerClassName={styles.youtube} videoId={_data.id} />
                        :
                        <div className={`${styles.skeleton} ${styles.skeleton__image}`} />
                    }
                </>
                :
                <>
                    <div className={`${styles.skeleton} ${styles.skeleton__input}`} />
                    <div className={`${styles.skeleton} ${styles.skeleton__image}`} />
                </>
            }
            <Handle type='source' position='right' id='out' className={styles.point_out} />
        </div>
    )
}

export { Video, VideoDemonstration }