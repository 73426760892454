import React, { useEffect, useState } from 'react';

import { Button2, Quill, Button } from '@c';

import { toClassNames } from '@helps/classNames';
import { FiTrash2, FiEdit } from 'react-icons/fi';
import styles from './style.module.scss';

const Multi = ({ data = {}, setData = () => {}, uniq = '' }) => {
    const [answers, setAnswers] = useState({
        correct: data.correctAnswers,
        texts: data.answers,
    });
    const [standartInput, setStandartInput] = useState(
        data.answers.map((el) => !/(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/gm.test(el))
    );

    useEffect(() => {
        setData((prev) => ({
            ...prev,
            answers: answers.texts,
            correctAnswers: answers.correct,
        }));
    }, [answers]);

    return (
        <div className={styles.container}>
            {answers.texts.length > 2 && (
                <div className={styles.answers}>
                    <div className={styles.answer}>
                        <div className={styles.header}>
                            <div>
                                <div>
                                    <input
                                        className={styles.answer_is_correct}
                                        type="checkbox"
                                        onChange={({ target: { checked } }) =>
                                            checked
                                                ? setData((prev) => ({
                                                      ...prev,
                                                      shuffle: true,
                                                  }))
                                                : setData((prev) => ({
                                                      ...prev,
                                                      shuffle: false,
                                                  }))
                                        }
                                        checked={data.shuffle === true}
                                    />
                                    <span>Перемешивать ответы</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className={styles.answers}>
                {answers.texts.map((el, i) => (
                    <div
                        key={i}
                        className={toClassNames(
                            styles.answer,
                            answers.correct.find((el) => el === i) !==
                                undefined && styles.answer__correct
                        )}
                    >
                        <div className={styles.header}>
                            <div>
                                <div>
                                    <input
                                        className={styles.answer_is_correct}
                                        type="checkbox"
                                        onChange={({ target: { checked } }) =>
                                            checked
                                                ? setAnswers((prev) => ({
                                                      ...prev,
                                                      correct: [
                                                          ...prev.correct,
                                                          i,
                                                      ],
                                                  }))
                                                : setAnswers((prev) => ({
                                                      ...prev,
                                                      correct:
                                                          prev.correct.filter(
                                                              (el) => el !== i
                                                          ),
                                                  }))
                                        }
                                        checked={
                                            answers.correct.find(
                                                (el) => el === i
                                            ) !== undefined
                                        }
                                    />
                                    <span>Правильный ответ</span>
                                </div>
                                <div>
                                    <Button
                                        className={toClassNames(
                                            styles.button,
                                            styles.button__editor
                                        )}
                                        onClick={() =>
                                            setStandartInput((prev) => {
                                                const tmp = [...prev];
                                                tmp[i] = !tmp[i];
                                                return [...tmp];
                                            })
                                        }
                                    >
                                        <span>
                                            {standartInput[i]
                                                ? 'Расширенный редактор'
                                                : 'Стандартный редактор'}
                                        </span>
                                        <FiEdit
                                            size={20}
                                            className={styles.icon}
                                        />
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <Button
                                    className={toClassNames(
                                        styles.button,
                                        styles.button__delete
                                    )}
                                    onClick={() => {
                                        setAnswers((prev) => ({
                                            ...prev,
                                            correct: prev.correct.filter(
                                                (el) => el !== i
                                            ),
                                            texts: prev.texts.filter(
                                                (_, idx) => idx !== i
                                            ),
                                        }));
                                    }}
                                >
                                    <span>Удалить</span>
                                    <FiTrash2
                                        size={20}
                                        className={styles.icon}
                                    />
                                </Button>
                            </div>
                        </div>
                        {standartInput[i] ? (
                            <input
                                value={el}
                                className={styles.answer_input}
                                onChange={({ target: { value } }) =>
                                    setAnswers((prev) => {
                                        const texts = [...prev.texts];
                                        texts[i] = value;
                                        return {
                                            ...prev,
                                            texts,
                                        };
                                    })
                                }
                            />
                        ) : (
                            <Quill
                                className={toClassNames(styles.text_editor)}
                                uniq={`${uniq}_${i}`}
                                value={el}
                                onChange={(value) =>
                                    setAnswers((prev) => {
                                        const texts = [...prev.texts];
                                        texts[i] = value;
                                        return {
                                            ...prev,
                                            texts,
                                        };
                                    })
                                }
                            />
                        )}
                    </div>
                ))}
            </div>
            {answers.texts.length < 10 ? (
                <Button2
                    className={styles.button}
                    title="Добавить ответ"
                    onClick={() => {
                        setAnswers((prev) => ({
                            ...prev,
                            texts: [...prev.texts, ''],
                        }));
                        setStandartInput((prev) => [...prev, true]);
                    }}
                />
            ) : (
                <p>Максимальное количество ответов - 10</p>
            )}
        </div>
    );
};

export { Multi };
