/**
 * @typedef {Object} CallbacksProperty
 * @property {Promise} promise - выполняемый промис
 * @property {Function} [cancel] - функция для отмены
 * @property {Any} [result] - результат
 */

/**
 * @callback callback
 * @param {CallbacksProperty} [props] - параметры
 * @returns {void}
 */

/**
 * @typedef {Object} Callbacks
 * @property {callback} beforeStart - функция, которую необходимо выполнить перед запуком промиса
 * @property {callback} onCancel - функция, которую необходимо выполнить при отмене
 * @property {callback} onResolve - функция, которую необходимо выполнить resolve
 * @property {callback} onReject - функция, которую необходимо выполнить reject
 */

/**
 * Вызывает первый аргумент,если он существует и он функция
 * @param {Function} func
 * @param {Object} params
 */
const tryCall = (func, params = {}) => {
    if (func && typeof func === 'function') {
        try {
            func(params);
        } catch (e) {
            console.error(e);
            func();
        }
    }
};

/**
 * Возврашает промис и функцию для его отмены
 * @param {Promise} promise
 * @param {Callbacks} callbacks - функция которые необходимо выполнить в
 * @returns
 */
const getCanceledPromise = (
    promise,
    callbacks = {
        beforeStart: () => {},
        onCancel: () => {},
        onResolve: () => {},
        onReject: () => {},
    }
) => {
    if (promise instanceof Promise !== true) {
        throw Error('Bad parameter "promise"');
    }

    const controller = new AbortController();
    const { signal } = controller;

    const task = new Promise((resolve, reject) => {
        const _resolve = (res) => {
            tryCall(callbacks.onResolve, { promise, result: res });
            resolve(res);
        };
        const _reject = (res) => {
            tryCall(callbacks.onReject, { promise, result: res });
            reject(res);
        };

        signal.addEventListener('abort', () => {
            tryCall(callbacks.onCancel);
            _reject({ reason: 'cancelled' });
        });

        tryCall(callbacks.beforeStart, {
            promise: promise,
            cancel: () => {
                controller.abort();
            },
        });

        return promise.then(_resolve).catch(_reject);
    });

    const withAborted = (func) => {
        return (data) => !signal.aborted && func(data);
    };

    return {
        promise: task,
        cancel: () => controller.abort(),
        withCanceled: withAborted,
        canceled: controller.signal.aborted,
    };
};

export { getCanceledPromise };
