import { Provider } from 'react-redux'
import { configureApi } from './api'
import { store } from './redux/store'
import { Routes } from './routes/Routes'
import { SnackbarProvider } from 'notistack'

import './style.module.scss'

export const api = configureApi(store)

export const App = () => {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={5}>
        <Routes />
      </SnackbarProvider>
    </Provider>
  )
}
