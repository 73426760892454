import React, { useContext, useEffect, useRef, useMemo } from 'react';

import { AleshaHeadContext, DialogContext, useCommands } from '../../../utils';
import { useAudio } from '@h';
import { useLoadAudioFile } from '@h/useLoadAudioFile';

import styles from './style.module.scss';

const CommandsView = ({ withAudio = true, withRecord = true }) => {
    const { setValue, setRecord, resetText } = useContext(DialogContext);
    const { setAleshaSpeaking } = useContext(AleshaHeadContext);

    const { commandsListForHelp } = useCommands();
    const { stopAudio } = useAudio();

    /**@type {HTMLAudioElement} */
    const audio = useMemo(() => new Audio(), []);
    const dataPromiseCancelRef = useRef(null);
    const { getFileUrl } = useLoadAudioFile(dataPromiseCancelRef);

    const getAndPlayAudio = () => {
        getFileUrl(
            'Список команд, которые я могу выполнить',
            () => {
                stopAudio();
                audio.pause();
                audio.currentTime = 0;
                setRecord(false);
                setAleshaSpeaking(false);
            },
            (url) => playAudioFile(url)
        );
    };

    const playAudioFile = (url) => {
        audio.src = url;
        audio.onended = () => {
            // Отключаем анимацию
            setAleshaSpeaking(false);
            // Включаем микрофон
            setRecord(true);
        };
        audio.play();
        setAleshaSpeaking(true);
        setRecord(false);
        setValue('');
        resetText();
    };

    const _stopAudio = () => {
        if (
            !dataPromiseCancelRef.current.finished &&
            !dataPromiseCancelRef.current.cancelled
        ) {
            console.log('canceling');
            dataPromiseCancelRef.current.cancel();
        }
        setValue('');
        setAleshaSpeaking(false);
        setRecord(true);
        audio.pause();
        stopAudio();
    };

    useEffect(() => {
        if (withAudio) getAndPlayAudio();
        else if (!withRecord) setRecord(false);

        return () => {
            if (withAudio) {
                _stopAudio();
            }
        };
    }, []);

    return (
        <div className={styles.availableCommands}>
            Вот что я умею:
            <div className={styles.commands_list}>
                {commandsListForHelp.map((el, i) => (
                    <div
                        key={`${i}`}
                        className={styles.list_item}
                        onClick={() => {
                            setValue(el);
                        }}
                    >
                        <p>{el}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export { CommandsView };
