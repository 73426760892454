class Observer {
    constructor() {
        this.observers = []
    }

    subscribe(fn) {
        this.observers.push(fn)
    }

    unsubsctibe(fn) {
        this.observers = this.observers.filter(sud => sud !== fn)
    }

    broadcast(data) {
        this.observers.forEach(sub => sub(data))
    }
}

export { Observer }