import React, { useEffect, useRef } from 'react';

import { toClassNames } from '@helps';

import styles from './Loader.module.scss';

export const Loader = ({
    color = '',
    className = '',
    size = null,
    borderWidth = null,
}) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.style.setProperty('--color', color);
            if (!isNaN(parseInt(size))) {
                ref.current.style.setProperty('--size', `${size}px`);
            }
            if (!isNaN(parseInt(borderWidth))) {
                ref.current.style.setProperty(
                    '--border-width',
                    `${borderWidth}px`
                );
            }
        }
    }, [ref, color, size]);

    return (
        <div ref={ref} className={toClassNames(styles.loader, className)}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
