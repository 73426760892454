const checkName = (name) => {
    let splitName = name.split(' ');

    if (splitName.length > 4 || name.length > 25) {
        name = '';

        for (const el of splitName) {
            if (name.length <= 25) name += `${el} `;
        }

        name = name.trim();
        name += '...';
    }

    return name;
};

const performanceParse = (arr) => {
    arr = arr.map((el) => ({
        ...el,
        // CourseName: (() => checkName(el.CourseName))(),
        CourseName: el.CourseName,
        CourseID: el.CourseID ? parseInt(el.CourseID) : null,
        PeriodGradeTypes: el.PeriodGradeTypes
            ? parseFloat(el.PeriodGradeTypes.replace(',', '.'))
            : null,
        Grade: el.Grade ? parseInt(el.Grade) : null,
        GradeType: el.GradeType ? parseInt(el.GradeType) : null,
        GradeOrder: el.GradeOrder ? parseInt(el.GradeOrder) : null,
        Personal: el.Personal ? parseInt(el.Personal) : null,
        AverageGrade: el.AverageGrade
            ? parseFloat(el.AverageGrade.replace(',', '.'))
            : null,
        PeriodID: el.PeriodID ? parseInt(el.PeriodID) : null,
    }));

    return arr;
};

function minRate(arr) {
    arr = arr.filter((el) => !isNaN(parseInt(el.AverageGrade)));

    arr.sort((a, b) => a.AverageGrade - b.AverageGrade);

    return arr[0];
}

function minRates(arr = [], elems = 0) {
    arr = arr.filter((el) => !isNaN(parseInt(el.AverageGrade)));

    arr.sort((a, b) => a.AverageGrade - b.AverageGrade);

    return arr.slice(0, elems);
}

export { performanceParse, minRate, minRates, checkName };
