import { getThemes } from '@a/course';
import { performanceParse } from '@helps/minRate';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getPerformance } from '../../api/profile';
import { profileActions } from './profileSlice';

import { DATE } from '@consts/api';

const {
    performanceStart,
    setPerformance,
    toggleLoading,
    getThemesStart,
    setThemes,
} = profileActions;

export function* profileWatcher() {
    yield takeLatest(performanceStart.type, setPerformanceWorker);
    yield takeLatest(getThemesStart.type, setThemesWorker);
}

function* setPerformanceWorker() {
    try {
        yield put(toggleLoading(true));
        const response = yield call(getPerformance, DATE);
        yield put(setPerformance(performanceParse(response.data)));
    } catch (e) {
        yield console.log('При загрузке успеваемости произошла ошибка', e);
    } finally {
        yield put(toggleLoading(false));
    }
}

function* setThemesWorker(action) {
    const courseId = action.payload;
    try {
        yield put(toggleLoading(true));
        const response = yield call(getThemes, courseId);
        yield put(setThemes(response.data.reverse()));
    } catch (e) {
        yield console.log('При загрузке тем произошла ошибка', e);
    } finally {
        yield put(toggleLoading(false));
    }
}
