import React, { useContext } from 'react';

import { Loader } from '@c';

import { Demonstaration } from '../Node';
import { ModalContext } from '../Modal';

import styles from './style.module.scss';

const Sidebar = ({ disabled = false, onSave = () => {}, onReviews = null }) => {
    const { showing } = useContext(ModalContext);

    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <div className={styles.main_container}>
            {disabled && (
                <div className={styles.loader_background}>
                    <Loader color="#007aff" />
                </div>
            )}
            <div className={styles.main}>
                <div className={styles.description}>
                    Для того, чтобы добавить новый блок, перетащите его на поле.
                </div>
                <div className={styles.nodes}>
                    <Demonstaration.StartDemonstration
                        onDragStart={(event) => onDragStart(event, 'start')}
                        draggable
                    />
                    <Demonstaration.TextDefaultDemonstaration
                        onDragStart={(event) => onDragStart(event, 'text')}
                        draggable
                    />
                    <Demonstaration.TextWithTypesDemonstration
                        onDragStart={(event) =>
                            onDragStart(event, 'textWithTypes')
                        }
                        draggable
                    />
                    <Demonstaration.QuestionDefaultDemonstration
                        onDragStart={(event) => onDragStart(event, 'question')}
                        draggable
                    />
                    <Demonstaration.QuestionWithTypesDemonstaration
                        onDragStart={(event) =>
                            onDragStart(event, 'questionWithTypes')
                        }
                        draggable
                    />
                    <Demonstaration.ImageDemonstration
                        onDragStart={(event) => onDragStart(event, 'image')}
                        draggable
                    />
                    <Demonstaration.VideoDemonstration
                        onDragStart={(event) => onDragStart(event, 'video')}
                        draggable
                    />
                    {/* <Demonstaration.GradeDemonstration
                    onDragStart={(event) => onDragStart(event, 'grade')}
                    draggable
                /> */}
                    <Demonstaration.FinishDemonstration
                        onDragStart={(event) => onDragStart(event, 'finish')}
                        draggable
                    />
                </div>
                <div className={styles.footer}>
                    {!!onReviews && typeof onReviews === 'function' && (
                        <button
                            className={styles.button}
                            onClick={() => onReviews()}
                            disabled={disabled}
                        >
                            Посмотреть отзывы
                        </button>
                    )}
                    <button
                        className={styles.button}
                        onClick={onSave}
                        disabled={disabled}
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    );
};

export { Sidebar };
