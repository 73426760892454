import React from "react";
import {
    getBezierPath,
    getEdgeCenter,
    getMarkerEnd,
    // getSmoothStepPath
} from 'react-flow-renderer'
import { FiTrash2 } from "react-icons/fi";

import styles from './style.module.css'

const foreignObjectSize = 22


const CustomEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data = {},
    arrowHeadType,
    markerEndId,
}) => {
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    })
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    })

    const onEdgeClick = (evt, id) => {
        evt.stopPropagation()
        data.onEdgeRemove(id)
    }

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - foreignObjectSize / 2}
                y={edgeCenterY - foreignObjectSize / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <FiTrash2
                    className={styles.button}
                    onClick={(event) => onEdgeClick(event, id)}
                />
            </foreignObject>
        </>
    )
}

export { CustomEdge }