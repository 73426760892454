import React, { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';

import { updateWikiRecord } from '@a/commands';
import { Button } from '@c';
import { ViewContext } from '../../../../utils';

import styles from './style.module.scss';

const EditAnswer = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { whoIsPayload, setWhoIsPayload } = useContext(ViewContext);

    const [value, setValue] = useState(whoIsPayload.info.extract);

    const onEdit = async () => {
        return new Promise((res, rej) => {
            // Запрос на изменение ответа на серере
            updateWikiRecord(whoIsPayload.info.id, value)
                // Если статус не 200 - ошибка
                .then((_res) => (_res.status === 200 ? res(_res.data) : rej()))
                .catch((err) => (console.log(err), rej(err)));
        })
            .then((data) => {
                // Присваиваем новое значение
                whoIsPayload.setInfo((prev) => ({
                    ...prev,
                    ...data,
                    otherRecords: prev.otherRecords,
                }));
                // Показываем, что все сохранилось
                enqueueSnackbar('Изменения успешно сохранены', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    autoHideDuration: 1000,
                });
            })
            .catch(() => {
                // Показываем ошибку
                enqueueSnackbar('При сохранении изменений произошла ошибка', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    autoHideDuration: 1000,
                });
                // Сбрасываем новое значение
                setValue(whoIsPayload.info.extract);
            });
    };

    const onBack = () => {
        // Меняем экран
        setWhoIsPayload((prev) => ({ ...prev, type: 'display' }));
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.buttons}>
                <Button onClick={onBack}>Назад</Button>
            </div>
            <div className={styles.main}>
                <span>
                    Редактирование ответа на вопрос &laquo;
                    {whoIsPayload.text}&raquo;
                </span>
                <textarea
                    value={value}
                    onChange={({ target: { value } }) => setValue(value)}
                />
                <Button className={styles.save} onClick={onEdit}>
                    Подтвердить
                </Button>
            </div>
        </div>
    );
};

export { EditAnswer };
