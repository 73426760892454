import { useState, useEffect } from "react";
import { useFormik } from "formik";
import styles from "./TeacherForm.module.css";



const TeacherForm = ({ schoolList, authStart }) => {
  
  const [openList, setopenList] = useState(false);
  const [schoolName, setSchoolName] = useState(localStorage.getItem('name') ? localStorage.getItem('name') : "");
  const [schoolTitle, setSchoolTitle] = useState(localStorage.getItem('title') ? localStorage.getItem('title') : "");
  const [filteredList, setFilteredList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);

  const formik = useFormik({
    initialValues: {
      password: "",
      schooltype: "lms",
      username: "",
      school: "",
    },
    onSubmit: (values) => {
      authStart({ ...values, school: schoolName });
      setSchoolTitle("");
      localStorage.setItem('name', schoolName)
      localStorage.setItem('title', schoolTitle)
    },
  });

  const selectSchool = (item) => {
    setopenList(false);
    setSchoolTitle(item.Title);
    setSchoolName(item.Name);
  }

  const inputSchoolName = (e) => {
    setopenList(true);
    setSelectedItem(0)
    setSchoolTitle(e.target.value)
  }

  useEffect(() => {
    if (schoolList.map(i => i.Title).includes(schoolTitle)) {
      setFilteredList(schoolList)
    } else if (schoolTitle) {
      const filteredList = schoolList.filter((item) =>
        item.Title.toLowerCase().includes(schoolTitle.toLowerCase())
      );
      setFilteredList(filteredList);
    } else {
      setFilteredList(schoolList);
    }
  }, [schoolTitle, schoolList]);

  useEffect(() => {
    const handler = (event) => {
      if (event.keyCode === 40) {
        selectedItem >= filteredList.length-1 ? setSelectedItem(filteredList.length-1) : setSelectedItem(selectedItem + 1);
      } else if (event.keyCode === 38) {
        selectedItem <= 0 ? setSelectedItem(0) : setSelectedItem(selectedItem - 1); 
      } else if (event.keyCode === 13) {
        if (openList===true) {
          event.preventDefault()
        setopenList(false);
        setSchoolTitle(filteredList[selectedItem]?.Title);
        setSchoolName(filteredList[selectedItem]?.Name);
        }
      } else if (event.keyCode === 27) {
        setopenList(false);
      } else return
    };

    document.addEventListener("keydown", handler);

    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [selectedItem, filteredList]);

  return (
    <form className={styles.login_form} autoComplete="on" onSubmit={formik.handleSubmit}>
      <div className={styles.title}>Выбрать учебное заведение:</div>
      <div
        className={styles.select}
        onMouseEnter={() => setopenList(true)}
        onMouseLeave={() => setopenList(false)}
      >
        <input
          className={styles.selected}
          placeholder="Учебное заведение не выбрано"
          onChange={(e) => inputSchoolName(e)}
          value={schoolTitle}
          autoComplete="school"
        />
        {openList && (
          <div className={styles.list}>
            {filteredList.map((item, i) => (
              <div
                className={
                  i === selectedItem
                    ? styles.listElement + " " + styles.choice
                    : styles.listElement
                }
                key={item.Name}
                onClick={() => selectSchool(item)}
                onMouseEnter={() => setSelectedItem(i)}
              >
                {item.Title}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.login_title2}>Введите логин/пароль:</div>
      <input
        className={styles.login_input}
        name="username"
        value={formik.values.username}
        onChange={formik.handleChange}
        placeholder="Логин"
      />
      <input
        className={styles.pass_input}
        name="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        type="password"
        placeholder="Пароль"
      />
      <button className={styles.input_btn}>Войти</button>
    </form>
  );
};

export default TeacherForm;