import React, { useEffect, useState } from 'react';

import { FiStar } from 'react-icons/fi';

import { toClassNames } from '@helps/';

import styles from './style.module.scss';

const Review = ({ value = {}, onChange = {} }) => {
    const [hoverIndex, setHoverIndex] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [review, setReview] = useState('');
    const [clicked, setClicked] = useState(false);
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        onChange({
            review,
            rating: selectedIndex + 1,
            selected: clicked,
        });
    }, [selectedIndex, review, clicked]);

    return (
        <div className={styles.container}>
            <div className={styles.rating}>
                <span>Как тебе занятие?</span>
                <div
                    className={styles.stars}
                    onMouseLeave={() => setHoverIndex(0)}
                >
                    {Array(5)
                        .fill(null)
                        .map((el, i) => (
                            <div
                                className={styles.container}
                                key={i}
                                onMouseEnter={() => (
                                    setHovered(true), setHoverIndex(i)
                                )}
                                onMouseLeave={() => (
                                    setHovered(false), setHoverIndex(null)
                                )}
                                onClick={() => {
                                    setClicked(true);
                                    setSelectedIndex(i);
                                }}
                            >
                                {(clicked || hovered) &&
                                ((hoverIndex !== null && hoverIndex >= i) ||
                                    selectedIndex >= i) ? (
                                    <FiStar
                                        size={40}
                                        className={toClassNames(
                                            styles.star,
                                            styles.star__selected
                                        )}
                                    />
                                ) : (
                                    <FiStar
                                        size={30}
                                        className={toClassNames(styles.star)}
                                    />
                                )}
                            </div>
                        ))}
                </div>
                <textarea
                    className={styles.comment}
                    placeholder="Оставить комментарий..."
                    value={review}
                    onChange={({ target: { value } }) => setReview(value)}
                />
            </div>
        </div>
    );
};

export { Review };
