import React from 'react'

import styles from './style.module.scss'

const Image = ({
    data
}) => {
    return (
        <div>
            <img className={styles.image} src={data.url} alt='o_O' />
        </div>
    )
}

export { Image }