import React, { useState } from 'react';

import { FiTrash2 } from 'react-icons/fi';

import { Button2 } from '@c';

const Images = React.memo(
    ({
        value = [],
        onChange = () => {},
        imageClassName = '',
        iconClassName = '',
        buttonClassName = '',
        buttonsClassName = '',
        imagesClassName = '',
    }) => {
        const [isAddImage, setIsAddImage] = useState(false);
        const [link, setLink] = useState('');

        return (
            <>
                <div className={imagesClassName}>
                    {value.map((el, i) => (
                        <div key={i} className={imageClassName}>
                            <FiTrash2
                                className={iconClassName}
                                size={25}
                                color={'red'}
                                onClick={() =>
                                    onChange(
                                        value.filter((_, idx) => idx !== i)
                                    )
                                }
                            />
                            <img src={el} alt="Изображение" />
                        </div>
                    ))}
                </div>
                {isAddImage ? (
                    <>
                        <input
                            value={link}
                            onChange={({ target: { value } }) => setLink(value)}
                            placeholder="Ссылка на изображение"
                        />
                        <div className={buttonsClassName}>
                            <Button2
                                title="Добавить"
                                className={buttonClassName}
                                onClick={() => {
                                    onChange([...value, link]);
                                    setLink('');
                                    setIsAddImage(false);
                                }}
                            />
                            <Button2
                                title="Отмена"
                                className={buttonClassName}
                                onClick={() => setIsAddImage(false)}
                            />
                        </div>
                    </>
                ) : (
                    <Button2
                        title="Добавить изображение"
                        className={buttonClassName}
                        onClick={() => setIsAddImage(true)}
                    />
                )}
            </>
        );
    }
);

export { Images };
