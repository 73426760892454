import { call, put, takeLatest } from 'redux-saga/effects';

import {
    getNode,
    updateNode,
    replayScheme,
    getThemes,
    getElectronicMaterials,
    getTheoryMaterials,
} from '@a/course';
import { filterThemes } from '@helps/filterThemes';

import { courseActions } from './courseSlice';

const {
    themesStart,
    setThemes,

    currentNodeStart,
    currentNodeUpdate,
    replaySchemeStart,
    resetCurrentTheme,
    setCurrentNode,
    toggleLoading,

    theoryThemesStart,
    theoryMaterialsStart,
    setTheoryMeterials,
    setTheoryThemes,

    videoLessonThemesStart,
    setVideoLessonThemes,

    electronicMaterialsThemesStart,
    setElectronicMaterialsThemes,
    electronicMaterialsStart,
    setElectronicMaterials,
} = courseActions;

export function* courseWatcher() {
    yield takeLatest(themesStart.type, themesWorker);

    yield takeLatest(currentNodeUpdate.type, setCurrentNodeWorker);
    yield takeLatest(currentNodeStart.type, setCurrentNodeStartWorker);
    yield takeLatest(replaySchemeStart.type, replaySchemeWorker);

    yield takeLatest(theoryThemesStart.type, themesWorker);
    yield takeLatest(theoryMaterialsStart.type, theoryMaterialsWorker);

    yield takeLatest(videoLessonThemesStart.type, themesWorker);

    yield takeLatest(electronicMaterialsThemesStart.type, themesWorker);
    yield takeLatest(electronicMaterialsStart.type, electronicMaterialsWorker);
}

export function* themesWorker({ payload: { id, type, callback = () => {} } }) {
    try {
        yield put(toggleLoading(true));
        const response = yield call(getThemes, id);
        const themes = yield filterThemes(yield response.data.reverse(), id);

        if (type === 'theory') yield put(setTheoryThemes(themes));
        if (type === 'videos') yield put(setVideoLessonThemes(themes));
        if (type === 'materials')
            yield put(setElectronicMaterialsThemes(themes));
        if (type === 'performance') yield put(setThemes(themes));
        yield call(callback);
    } catch (e) {
        yield console.log('При загрузке тем произошла ошибка', e);
    } finally {
        yield put(toggleLoading(false));
    }
}

export function* setCurrentNodeStartWorker({ payload: { courseId, type } }) {
    yield put(toggleLoading(true));
    if (type === null) return;
    try {
        const response = yield call(getNode, courseId, type);
        yield console.log(response);

        if (response.status === 200 && Object.keys(response.data).length > 0) {
            yield put(setCurrentNode(response.data));
        } else {
            yield put(setCurrentNode(null));
        }
    } catch (error) {
        yield console.error(
            'При загрузке текущей ноды произошла ошибка',
            error
        );
    } finally {
        yield put(toggleLoading(false));
    }
}

export function* setCurrentNodeWorker({
    payload: { courseId, answer, type, prev = false },
}) {
    yield put(toggleLoading(true));
    try {
        let res;
        res = yield call(updateNode, { courseId, answer, type, prev });
        yield console.log(res);
        if (res.status === 204) {
            res = yield call(getNode, courseId, type);
            yield console.log(res);
            yield put(setCurrentNode(res.data));
        }
    } catch (error) {
        yield console.error('При получении новой ноды произошла ошибка', error);
    } finally {
        yield put(toggleLoading(false));
    }
}

export function* replaySchemeWorker({
    payload: { courseId, type, withReset = false },
}) {
    yield put(toggleLoading(true));
    try {
        let res;
        res = yield call(replayScheme, courseId);
        if (res.status === 204) {
            res = yield call(getNode, courseId, type);
            yield put(setCurrentNode(res.data));
            if (withReset) {
                yield put(resetCurrentTheme());
            }
        }
    } catch (error) {
        yield console.error(
            'При загрузке текущего курса произошла ошибка',
            error
        );
    } finally {
        yield put(toggleLoading(false));
    }
}

export function* theoryMaterialsWorker({ payload: { courseId } }) {
    yield put(toggleLoading(true));
    try {
        const result = yield call(getTheoryMaterials, courseId);
        if (result.status === 200) {
            yield put(setTheoryMeterials(result.data));
        }
    } catch (e) {
        yield console.error('При загрузке материалов произошла ошибка', e);
    } finally {
        yield put(toggleLoading(false));
    }
}

export function* electronicMaterialsWorker({
    payload: { scheduleId, weekId, courseId, themeId },
}) {
    yield put(toggleLoading(true));
    try {
        const result = yield call(
            getElectronicMaterials,
            weekId,
            scheduleId,
            courseId,
            themeId
        );
        if (result.status === 200) {
            yield put(setElectronicMaterials(result.data));
        }
    } catch (e) {
        yield console.error('При загрузке материалов произошла ошибка', e);
    } finally {
        yield put(toggleLoading(false));
    }
}
