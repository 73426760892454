import React, { useState, useEffect } from 'react'

import styles from './style.module.css'

const Answers = ({
    answers = [],
    results = [],
    resultsTrue = 0.1,
    selectAnswer = () => { }
}) => {
    return (
        <div className={styles.answers}>
            {Array.isArray(answers) && answers.map((el, i) =>
                <div key={`${i}`}
                    onClick={() => Math.max(...results) > resultsTrue && selectAnswer(i)}
                    className={`${styles.answer} ${results[i] !== 0 && results[i] === Math.max(...results) && styles.max_correct_answer}`}
                >
                    <p htmlFor={`${i}`} >{i + 1}. {el}</p>
                    {results &&
                        <p
                            className={styles.result}
                            style={{
                                color: results[i] !== 0 ?
                                    (results[i] > resultsTrue ?
                                        `rgba(103, 228, 30, 1)`
                                        :
                                        `rgba(255, 136, 0, ${results[i] / resultsTrue})`)
                                    :
                                    'white',
                            }}
                        >
                            {((results[i] * 100) || 0).toFixed(2)}%
                        </p>
                    }
                </div>
            )
            }
        </div >
    )
}

export { Answers }