import React, {
    createContext,
    useCallback,
    useContext,
    useState,
    useEffect,
} from 'react';

const MediaStreamContext = createContext({
    stream: null,
    start: () => {},
    stop: () => {},
});

export const useMediaStream = () => useContext(MediaStreamContext);

export const MediaStreamProvider = ({ children, audio, video }) => {
    const [stream, setStream] = useState(null);

    // useEffect(() => {
    //     start()
    // }, [])

    useEffect(() => {
        return () => {
            console.log(stream);
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
        };
    }, [stream]);

    const start = useCallback(() => {
        navigator.mediaDevices
            .getUserMedia({
                audio,
                video,
            })
            .then((res) => setStream(res))
            .catch((err) => {
                console.error(err);
                setStream(null);
            });
    }, [audio, video]);

    const stop = useCallback(() => {
        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
            setStream(null);
        }
    }, [stream]);

    return (
        <MediaStreamContext.Provider value={{ stream, start, stop }}>
            {children}
        </MediaStreamContext.Provider>
    );
};

export default MediaStreamContext;
