import React from 'react';

import { FiThumbsDown, FiThumbsUp, FiUser } from 'react-icons/fi';
import styles from './style.module.scss';

const OtherVariant = ({ data = {}, onClick = () => {} }) => {
    return (
        <div className={styles.info} onClick={onClick}>
            <div className={styles.header}>
                <div className={styles.author_container}>
                    <div className={styles.avatar}>
                        <FiUser size={30} />
                    </div>
                    <span className={styles.author}>
                        {data.type === 'wiki' ? (
                            <a
                                href={data.link}
                                title={data.name}
                                target="_blank"
                            >
                                Википедия
                            </a>
                        ) : (
                            data.user_name
                        )}
                    </span>
                </div>
                <div className={styles.line} />
            </div>
            <div className={styles.extract}>
                <span>{data.extract}</span>
            </div>
            <div className={styles.likes}>
                <div>
                    {data.likes}
                    <FiThumbsUp size={18} />
                </div>
                <div>
                    {data.dislikes} <FiThumbsDown size={18} />
                </div>
            </div>
        </div>
    );
};

export { OtherVariant };
