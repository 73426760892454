import { createContext, useMemo } from 'react';

/**
 * @callback onEnd - функция, которая будет вызвана при завершении проигрывания
 * @returns {void}
 */
/**
 * @callback stopAudio - остановить воспроизведение
 * @returns {void}
 */
/**
 * @callback playAudio - произрать аудио по ссылке
 * @param {String} url
 * @param {onEnd} [onEnd]
 * @returns {void}
 */

/**
 * @typedef {Object} InitState
 * @property {stopAudio} stopAudio
 * @property {playAudio} playAudio
 */

/** @type {InitState} */
const initValue = {
    stopAudio: () => {},
    playAudio: (url, onEnd = () => {}) => {},
};

const TestContext = createContext({ ...initValue });

const TestContextProvider = ({ children }) => {
    /**@type {HTMLAudioElement} */
    const audio = useMemo(() => new Audio(), []);

    const stopAudio = () => {
        audio.pause();
        audio.currentTime = 0;
    };

    const playAudio = (url, onEnd = () => {}) => {
        try {
            stopAudio();

            audio.onended = () => {
                onEnd();
            };

            audio.src = url;
            audio.play();
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <TestContext.Provider
            value={{
                stopAudio,
                playAudio,
            }}
        >
            {children}
        </TestContext.Provider>
    );
};

export { TestContext, TestContextProvider, initValue };
