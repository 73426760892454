import React from 'react'

import { ButtonsBlock } from '../'

import styles from './style.module.scss'

const DefaultView = ({
    question = '',
    answers = []
}) => {
    return (
        <div className={styles.main}>
            <div className={styles.question_container}>
                <div className={styles.question}>
                    <p>{question}</p>
                </div>
                <div className={styles.answers}>
                    {answers.map((el, i) =>
                        <p key={`${i}`}>{i + 1}. {el}</p>
                    )}
                </div>
            </div>
            <ButtonsBlock />
        </div>
    )
}

export { DefaultView }