import React from 'react'

import { Answers } from '../../Answers'

import styles from './style.module.scss'

const Default = ({
    index = null,
    question = '',
    answers = [],
    results = [],
    selectAnswer,
    showAnswers
}) => {
    return (
        <div className={styles.main}>
            <p className={styles.header}>{index !== null && index}. {question}</p>
            {showAnswers &&
                <Answers
                    answers={answers}
                    results={results}
                    selectAnswer={selectAnswer}
                />
            }
        </div>
    )
}

export { Default }