import React, { useContext, useEffect } from 'react';

import { ViewContext, AleshaHeadContext, DialogContext } from '../../../utils';
import { toClassNames } from '@helps/';
import { useActions } from '@h';
import { profileActions } from '@r/profile/profileSlice';

import styles from './style.module.scss';

const Avatars = () => {
    const { avatarsPayload } = useContext(ViewContext);
    const { headData, setHeadData } = useContext(AleshaHeadContext);
    const { changeVoiceOptions } = useActions(profileActions);

    return (
        <div className={styles.wrapper}>
            {avatarsPayload.list && (
                <div className={styles.avatars_contaier}>
                    {avatarsPayload.list.map((el, i) => (
                        <div
                            key={i}
                            className={toClassNames(
                                styles.avatar_container,
                                ((headData && headData.idle === el.idle) ||
                                    (headData === undefined &&
                                        el.idle === 'head_idle_vp9.webm')) &&
                                    styles.avatar_container__selected
                            )}
                            onClick={() => {
                                setHeadData(el);
                                changeVoiceOptions(el.name);
                            }}
                        >
                            <img
                                src={`/media/avatar_previews/${
                                    el.idle.split('.')[0]
                                }.png`}
                            />
                            <span>{el.name}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export { Avatars };
