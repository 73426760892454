import React, { createRef, useEffect, useRef, useState } from 'react';

import styles from './style.module.scss';

const Tab = ({ tabRef, name = '', children, uniq }) => {
    const ref = createRef(null);

    useEffect(() => {
        if (ref.current) tabRef(ref);
    }, [ref]);

    return (
        <div ref={ref} className={styles.tab}>
            {React.cloneElement(children, { uniq: uniq })}
        </div>
    );
};

/**
 * @param {Object} props
 * @param {Array<JSX.Element>} props.children
 * @returns {JSX.Element}
 */
const Tabs = ({ children }) => {
    const [tabs, setTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const tabsRef = useRef([]);
    const containerRef = useRef(null);

    useEffect(() => {
        if (!children) return;

        let tmp = [];
        if (!Array.isArray(children)) {
            tmp.push(children);
        } else {
            tmp = [...children];
        }

        let _tabs = [];

        const toTabs = (els = []) => {
            for (const el of els) {
                if (React.isValidElement(el)) {
                    if (
                        el.type.name === Tab.name &&
                        typeof el.props.name === 'string' &&
                        el.props.name !== ''
                    ) {
                        _tabs.push(el);
                    } else {
                        console.error(
                            `Компонент Tabs может содержать в себе только компоненты Tab с пропсом name`
                        );
                    }
                } else {
                    if (Array.isArray(el)) {
                        toTabs(el);
                    }
                }
            }
        };

        toTabs(tmp);

        tabsRef.current = [];
        _tabs = _tabs.map((el, i) =>
            React.cloneElement(el, {
                tabRef: (element) => tabsRef.current.push(element),
                uniq: i,
            })
        );

        setTabs(_tabs);
    }, [children]);

    const disableScroll = (e) => {
        let keys = [32, 33, 34, 35, 37, 38, 39, 40];
        if (keys.includes(e.keyCode)) {
            e.preventDefault();
            return false;
        }
    };

    useEffect(() => {
        return () => {
            tabsRef.current = [];
            if (containerRef.current) {
                containerRef.current.removeEventListener(
                    'keydown',
                    disableScroll
                );
            }
        };
    }, []);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener('keydown', disableScroll);
        }
    }, [containerRef]);

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                {tabs.map((el, i) => (
                    <div
                        key={i}
                        onClick={() => {
                            if (tabsRef.current && tabsRef.current[i].current) {
                                tabsRef.current[i].current.scrollIntoView({
                                    block: 'center',
                                    behavior: 'smooth',
                                });
                                setSelectedTab(i);
                            }
                        }}
                        data-selected={i === selectedTab}
                    >
                        <span>{el.props.name}</span>
                    </div>
                ))}
            </div>
            <div ref={containerRef} className={styles.tabs}>
                {tabs}
            </div>
        </div>
    );
};

export { Tabs, Tab };
