import { useCallback, useEffect, useState } from 'react'

import { limiter } from '@helps'

/**
 * @typedef {import('socket.io-client').Socket} Socket
 */

/**
 * 
 * @param {Socket} socket 
 * @returns
 */
const useCompare = (socket = null) => {
    const [results, setResults] = useState([])
    const [resultsWithWords, setResultsWithWords] = useState([])

    // const compareTextsLimited = limiter((socket, msg) => socket.emit('comparetexts', msg), 200)

    const compareTexts = useCallback((texts) => {
        if (socket) {
            // compareTextsLimited(socket, texts)
            socket.emit('comparetexts', { ...texts, onlyScore: 'normalizedWords' })
        }
    }, [socket])

    useEffect(() => {
        if (socket !== null) {
            socket.on('comparetextsresult', ({ score }) => {
                setResults(score.map(el => el.score))
                // console.log(score)
                setResultsWithWords(score)
            })
        }
    }, [socket])

    const resetResults = useCallback(() => {
        setResults([])
    }, [setResults])

    return { results, compareTexts, resultsWithWords, resetResults }
}

export { useCompare }