import React from 'react'

import styles from './style.module.scss'

const VoidView = () => {
    return (
        <div className={styles.availableCommands}>
            <p>Для того, чтобы получить список команд скажите "команды"</p>
        </div>
    )
}

export { VoidView }