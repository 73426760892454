import { Finish, FinishDemonstration } from './Finish';
import { Grade, GradeDemonstration } from './Grade';
import { Image, ImageDemonstration } from './Image';
import {
    QuestionDefault,
    QuestionDefaultDemonstration,
    QuestionWithTypes,
    QuestionWithTypesDemonstaration,
} from './Question';
import { Start, StartDemonstration } from './Start';
import {
    TextWithTypes,
    TextWithTypesDemonstration,
    TextDefault,
    TextDefaultDemonstaration,
} from './Text';
import { Video, VideoDemonstration } from './Video';

const Demonstaration = {
    FinishDemonstration,
    ImageDemonstration,
    QuestionDefaultDemonstration,
    QuestionWithTypesDemonstaration,
    StartDemonstration,
    TextWithTypesDemonstration,
    TextDefaultDemonstaration,
    VideoDemonstration,
    GradeDemonstration,
};

const Nodes = {
    finish: Finish,
    image: Image,
    question: QuestionDefault,
    questionWithTypes: QuestionWithTypes,
    start: Start,
    text: TextDefault,
    textWithTypes: TextWithTypes,
    video: Video,
    grade: Grade,
};

export { Demonstaration, Nodes };
