import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AleshasHead } from '@c';
import { useActions } from '@h';
import { appVersion } from '@consts/version';
import { profileActions } from '@r/profile/profileSlice';

import { Dialog } from './Dialog';
import { Menu } from './Menu';
import {
    AleshaHeadContextProvider,
    DialogContextProvider,
    ViewContextProvider,
} from './utils';

import styles from './style.module.scss';

const HomeWrapper = () => {
    return (
        <ViewContextProvider>
            <AleshaHeadContextProvider>
                <DialogContextProvider>
                    <Home />
                </DialogContextProvider>
            </AleshaHeadContextProvider>
        </ViewContextProvider>
    );
};

const Home = () => {
    const userId = useSelector(
        ({
            auth: {
                data: { mid },
            },
        }) => mid
    );
    const { performanceStart } = useActions(profileActions);

    useEffect(() => {
        performanceStart(userId);
    }, [performanceStart, userId]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.leftBlock}>
                <AleshasHead className={styles.avatar} />
                <Dialog />
            </div>
            <Menu />
            <footer className={styles.footer}>{appVersion}</footer>
        </div>
    );
};

export { HomeWrapper as Home };
