import React, { useState } from 'react';

import { toClassNames } from '@helps/';
import { Player } from './Player';

import {
    FiUser,
    FiEye,
    FiHeart,
    FiChevronDown,
    FiChevronUp,
} from 'react-icons/fi';
import styles from './style.module.scss';

const Video = ({ video = {}, likeAdding = false, onLike = () => {} }) => {
    const [fullDescription, setFullDescription] = useState(false);

    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <div className={styles.player}>
                    <Player type={video.service} videoId={video.service_id} />
                </div>
                <div className={styles.title}>
                    <span>{video.title}</span>
                </div>
                <div className={styles.stat_info}>
                    <a
                        className={styles.author}
                        href={video.author_link}
                        target={'_blank'}
                    >
                        <div className={styles.avatar}>
                            <FiUser size={35} color={'#006eff'} />
                        </div>
                        <span>{video.author_name}</span>
                    </a>
                    <div className={styles.stats}>
                        <div className={styles.stat}>
                            <FiEye className={styles.view} />
                            <span>
                                {video.view_count + ' '}
                                {video.service_view_count && (
                                    <span
                                        className={styles.from_service}
                                        title="В источнике"
                                    >
                                        ({video.service_view_count})
                                    </span>
                                )}
                            </span>
                        </div>
                        <div className={styles.stat}>
                            <FiHeart
                                className={toClassNames(
                                    styles.like,
                                    video.like_from_user &&
                                        styles.like__selected,
                                    likeAdding && styles.like__updating
                                )}
                                onClick={() => {
                                    if (!likeAdding) onLike();
                                }}
                            />
                            <span>
                                {video.like_count + ' '}
                                {video.service_like_count && (
                                    <span
                                        className={styles.from_service}
                                        title="В источнике"
                                    >
                                        ({video.service_like_count})
                                    </span>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={styles.description_container}>
                    <span
                        className={toClassNames(
                            styles.description,
                            !fullDescription && styles.description__hide
                        )}
                    >
                        {video.description}
                    </span>
                    <div
                        className={styles.full}
                        onClick={() => setFullDescription(!fullDescription)}
                    >
                        {fullDescription ? (
                            <>
                                <span>Свернуть</span>
                                <FiChevronUp size={20} />
                            </>
                        ) : (
                            <>
                                <span>Развернуть</span>
                                <FiChevronDown size={20} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export { Video };
