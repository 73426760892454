import React from 'react'

import styles from './style.module.scss'

const Finish = () => {
    return (
        <div className={styles.data_container}>
            <p>Поздавляю, вы прошли курс!</p>
        </div>
    )
}

export { Finish }