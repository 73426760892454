import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import AuthForm from './AuthForm/AuthForm';
import { AuthByFace } from './AuthByFace';
import { ViewContext } from './utils';

import { appVersion } from '@consts/version';

import styles from './Auth.module.css';

export const Auth = () => {
    const { data: authData } = useSelector(({ auth }) => auth);

    const [webcamAuth, setWebcamAuth] = useState(false);
    const [loginPassAuth, setLoginPassAuth] = useState(false);
    const [alert, setAlert] = useState({});
    const [alertTimer, setAlertTimer] = useState(null);

    useEffect(() => {
        if (alert.show) {
            if (alertTimer) clearTimeout(alertTimer);
            setAlertTimer(
                setTimeout(
                    () => setAlert((prev) => ({ ...prev, show: false })),
                    5000
                )
            );
        }
    }, [alert]);

    useEffect(() => {
        return () => {
            setAlert({});
            alertTimer && clearTimeout(alertTimer);
        };
    }, []);

    if (authData) return <Redirect to="/" />;

    return (
        <div className={styles.wrapper}>
            <div className={styles.webcam}>
                <ViewContext.Provider
                    value={{
                        setLoginPassAuth,
                        setWebcamAuth,
                        alert,
                        setAlert,
                        alertTimer,
                        setAlertTimer,
                    }}
                >
                    {!loginPassAuth && !webcamAuth && (
                        <div className={styles.auth_buttons}>
                            <div>
                                <button
                                    className={styles.btn}
                                    onClick={() => setWebcamAuth(true)}
                                >
                                    Авторизация по лицу
                                </button>
                                <button
                                    className={styles.btn}
                                    onClick={() => setLoginPassAuth(true)}
                                >
                                    Авторизация по логину
                                </button>
                            </div>
                        </div>
                    )}
                    {loginPassAuth && <AuthForm />}
                    {webcamAuth && <AuthByFace />}
                </ViewContext.Provider>
            </div>
            <div className={styles.footer}>{appVersion}</div>
        </div>
    );
};
