import styles from './style.module.css'

const Button = ({
    title = '',
    onClick = () => { },
    className = ''
}) => {
    return <button className={`${styles.button} ${className}`} onClick={onClick}>{title}</button>
}

export { Button }