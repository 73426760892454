import React, { useEffect, useMemo } from 'react';
import SpiderChart from 'react-spider-chart';

import { checkName } from '@helps/minRate';

import './style.css';

const Chart = ({
    data = [],
    imageUrl = '',
    onLabelClick = () => {},
    style = {},
}) => {
    const options = useMemo(() => {
        const _data = data;

        const props = {
            size: 700,
            axes: true, // show axes?
            scales: 5, // show scale circles?
            captions: true, // show captions?
            captionIndex: false, // show caption indexes
            icons: false, // show icons
            circleFill: false,
            zoomDistance: 1, // where on the axes are the captions?
            drawSize: 3.5,
            captionMargin: 0,
            axisProps: () => ({ className: 'axis' }),
            circleProps: () => ({ className: ' circle' }),
            indexProps: () => ({ className: 'index', fontSize: 12 }),
            scaleProps: () => ({ className: 'scale', fill: 'none' }),
            shapeProps: () => ({ className: 'shape' }),
            iconProps: () => ({ className: 'icon' }),
            fillCircleProps: (style) => ({ className: style }),
            captionProps: (style) => ({
                className: style,
                textAnchor: 'middle',
                fontSize: 12,
                fontFamily: 'Source Sans Pro',
            }),
        };

        return {
            data: _data,
            props,
        };
    }, [data]);

    useEffect(() => {
        const svg = document
            .getElementById('chart')
            .getElementsByTagName('svg')[0]
            .getElementsByTagName('g')[0];
        /**@type {Array<HTMLElement>} */
        const texts = [...svg.getElementsByTagName('text')];
        const _texts = texts.map((el) => el.innerHTML);

        if (texts.length === 0) return;

        const clickHandler = ({ target }) => {
            const tmp = {};
            let text = target.textContent.replace('...', '');
            tmp.index = _texts.findIndex((el) => el.indexOf(text) > -1);
            tmp.textContent = _texts[tmp.index];
            onLabelClick(tmp);
        };

        const foreignObjects = [];

        for (const text of texts) {
            const foreignObject = document.createElementNS(
                'http://www.w3.org/2000/svg',
                'foreignObject'
            );
            foreignObject.setAttribute(
                'x',
                text.attributes.x.value <= 0
                    ? text.attributes.x.value - 90
                    : text.attributes.x.value > 50
                    ? text.attributes.x.value - 30
                    : text.attributes.x.value
            );
            foreignObject.setAttribute('y', text.attributes.y.value - 10);
            foreignObject.setAttribute('width', '130');
            foreignObject.setAttribute('height', '55');

            const div = document.createElementNS(
                'http://www.w3.org/1999/xhtml',
                'div'
            );
            div.className = 'name_div';

            const p = document.createElement('p');
            p.innerHTML = checkName(text.innerHTML);
            p.className = 'text';
            p.addEventListener('click', clickHandler);
            div.append(p);

            foreignObject.append(div);
            foreignObjects.push(foreignObject);

            text.addEventListener('click', clickHandler);
        }

        while (svg.getElementsByTagName('g')[3].firstChild) {
            svg.getElementsByTagName('g')[3].removeChild(
                svg.getElementsByTagName('g')[3].lastChild
            );
        }

        foreignObjects.forEach((el) =>
            svg.getElementsByTagName('g')[3].append(el)
        );

        return () => {
            for (const text of texts) {
                text.removeEventListener('click', clickHandler);
            }
        };
    }, []);

    useEffect(() => {
        const svg = document
            .getElementById('chart')
            .getElementsByTagName('svg')[0]
            .getElementsByTagName('g')[0];

        const defs = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'defs'
        );
        const circle = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'circle'
        );
        circle.setAttribute('id', 'circle');
        circle.setAttribute('cx', '50');
        circle.setAttribute('cy', '50');
        circle.setAttribute('r', '50');
        circle.setAttribute('vector-effect', 'non-scaling-stroke');
        const clipPath = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'clipPath'
        );
        clipPath.setAttribute('id', 'circle-clip');
        clipPath.append(circle);
        defs.append(clipPath);
        svg.append(defs);

        const image = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'image'
        );
        image.setAttribute('clip-path', 'url(#circle-clip)');
        image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', imageUrl);
        // image.setAttribute('height', '100');
        image.setAttribute('width', '100');
        image.setAttribute('visibility', 'visible');
        image.setAttribute('style', 'transform: translate(-50px, -50px)');
        image.setAttribute('preserveAspectRatio', 'xMidYMid slice');
        svg.append(image);

        // return () => {
        // svg.innerHTML = ''
        // }
    }, [imageUrl]);

    return (
        <div
            id="chart"
            style={{
                margin: '0 auto',
                ...style,
            }}
        >
            <SpiderChart data={options.data} props={options.props} />
        </div>
    );
};

export { Chart };
