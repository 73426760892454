import { useCallback, useState, useMemo, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import * as Views from '../Dialog/Views';
import { ViewContext, AleshaHeadContext } from './';
import { useActions } from '@h';
import { TTS } from '@helps/';
import { testsActions } from '@r/tests/testsSlice';
import { api } from '@src/App';

/**
 * @typedef {object} User - объект с информацие о пользователе
 * @property {Number} uid - id пользователя
 * @property {String} firstname - имя пользователя
 * @property {String} patronymic - отчество пользователя
 * @property {String} lastname - фамилия пользователя
 * @property {Number} group - id класса
 * @property {String} class - название класса
 * @property {String} school - название школы
 * @property {String|'null'|null|'Аудиал'|'Визуал'|'Дискрет'|'Кинестетик'} typeInformationPercept - тип восприяти
 * @property {String} username - логин
 * @property {String} schoolID - id школы
 * @property {String|'student'|'teacher'} type - тип
 * @property {boolean} isteacher
 */

/**
 * @typedef {Object} MenuObject - функии и значения для меню
 * @property {Function} setView - устанока view меню
 * @property {Object} COMPONENT_NAME - названия view
 */

/**
 * @typedef {Object} PayloadsObject - полезная нагрузка для некоторых view из меню
 * @typedef {Function} setWhoIsPayload - установка значения для команд кто такая/что такое/кто такой
 * @typedef {Function} setAvatarsPayload - установка значения для команды сменить аватар
 */

/**
 * @typedef {Object} HeadObject - объект управления головой
 * @typedef {Function} setAleshaSpeaking - установка анимации разовора
 */

/**
 * @typedef {Object} MicroObject - объект управления микрофоном
 * @typedef {Function} setRecord - установка распознование голоса
 */

/**
 * Возвращает объект с командами и их обработкой
 * @param {User} user - информация о пользователе
 * @param {Array<JSON>} test - тест на тип воприятия
 * @param {MenuObject} menu - функии и значения для меню
 * @param {PayloadsObject} payloads - полезная нагрузка для некоторых view из меню
 * @param {HeadObject} head - объект управления головой
 * @param {MicroObject} micro - объект управления микрофоном
 * @returns
 */
const getCommands = (
    user = {},
    test = [],
    menu = {
        setView: () => {},
        COMPONENT_NAME: {},
    },
    payloads = {
        setWhoIsPayload: () => {},
        setAvatarsPayload: () => {},
    },
    head = {
        setAleshaSpeaking: () => {},
    },
    micro = {
        setRecord: () => {},
    }
) => {
    const audio = new Audio();
    audio.addEventListener('play', () => head.setAleshaSpeaking(true));

    const playAudio = async (text, callback = () => {}) => {
        const res = (await TTS(text)).data;
        const url = URL.createObjectURL(res);
        audio.currentTime = 0;
        audio.src = url;
        audio.play();

        audio.addEventListener('ended', () => {
            head.setAleshaSpeaking(false);
            callback();
        });
        audio.addEventListener('pause', () => {
            head.setAleshaSpeaking(false);
            callback();
        });
    };

    return {
        '': {
            visableInList: false,
            visableInHelps: false,
            getComponent: async () => {
                return <Views.VoidView />;
            },
        },
        loading: {
            visableInList: false,
            visableInHelps: false,
            getComponent: async () => {
                return <Views.LoadingView />;
            },
        },
        Команды: {
            visableInList: true,
            visableInHelps: true,
            getComponent: async (props) => {
                return <Views.CommandsView {...props} />;
            },
        },
        'Как тебя зовут?': {
            visableInList: true,
            visableInHelps: true,
            getComponent: async (props) => {
                props.text = [
                    'Меня зовут Алёша',
                    'Я голосовой помощник, который поможет тебе подтянуть учебу',
                ];

                return <Views.TextView {...props} />;
            },
        },
        'Сменить аватар': {
            visableInList: true,
            visableInHelps: true,
            checkOtherCommands: false,
            getComponent: async (props) => {
                menu.setView(menu.COMPONENT_NAME.AVATARS);
                return (
                    <Views.ChangeAvatarView
                        onListLoad={(list) =>
                            payloads.setAvatarsPayload((prev) => ({
                                ...prev,
                                list,
                            }))
                        }
                        {...props}
                    />
                );
            },
        },
        'Электронное занятие': {
            visableInList: true,
            visableInHelps: true,
            reference: 'Успеваемость',
        },
        Успеваемость: {
            visableInList: true,
            visableInHelps: true,
            getComponent: async (props) => {
                menu.setView(menu.COMPONENT_NAME.PERFORMANCE);
                return <Views.ThemeSelectorView />;
            },
        },
        Тестирование: {
            visableInList: true,
            visableInHelps: true,
            checkOtherCommands: false,
            getComponent: async (props) => {
                props.test = test;
                props.userInfo = user;

                menu.setView(menu.COMPONENT_NAME.TESTING_VIEW);

                return <></>;
            },
        },
        'Повторное тестирование': {
            visableInList: true,
            visableInHelps: false,
            checkOtherCommands: false,
            getComponent: async (props) => {
                props.test = test;
                props.userInfo = user;

                menu.setView(menu.COMPONENT_NAME.TESTING_VIEW);
                return <Views.StudentTypeTestView {...props} />;
            },
        },
        Приветствие: {
            visableInList: false,
            visableInHelps: false,
            getComponent: async (props) => {
                props.text = [
                    `Привет, ${user.firstname}, я цифровой голосовой асистент Алёша.`,
                    'Я буду помогать тебе в учебе!',
                    'Чтобы узнать что я могу, скажи "команды".',
                ];

                return <Views.TextView {...props} />;
            },
        },
        'Изучение теоретического материала': {
            visableInList: true,
            visableInHelps: false,
            reference: 'Теоретические материалы',
        },
        'Теоретические материалы': {
            visableInList: true,
            visableInHelps: true,
            getComponent: async () => {
                menu.setView(menu.COMPONENT_NAME.THEORY_VIEW);
                return <Views.ThemeSelectorView />;
            },
        },
        Видеоуроки: {
            visableInList: true,
            visableInHelps: false,
            reference: 'Видео уроки',
        },
        'Видео уроки': {
            visableInList: true,
            visableInHelps: true,
            getComponent: async () => {
                menu.setView(menu.COMPONENT_NAME.VIDEO_LESSON_VIEW);
                return <Views.ThemeSelectorView />;
            },
        },
        'Электронные материалы': {
            visableInList: true,
            visableInHelps: true,
            getComponent: async () => {
                menu.setView(menu.COMPONENT_NAME.ELECTRONIC_MATERIALS_VIEW);
                return <Views.ThemeSelectorView />;
            },
        },
        'Сколько будет два плюс два': {
            visableInList: true,
            visableInHelps: true,
            reference: '*',
        },
        'Что такое *': {
            visableInList: true,
            visableInHelps: true,
            reference: '*',
        },
        'Кто такой *': {
            visableInList: true,
            visableInHelps: true,
            reference: '*',
        },
        'Кто такая *': {
            visableInList: true,
            visableInHelps: false,
            reference: '*',
        },
        '*': {
            visableInList: false,
            visableInHelps: false,
            checking: true,
            getComponent: async (props) => {
                menu.setView(null);

                switch (props.type) {
                    case 'dialog':
                        return <Views.TextView text={[props.result]} />;
                    case 'wiki':
                        let ended = false;
                        micro.setRecord(false);
                        await playAudio(
                            'Секунду. Ищу ответ в интернете',
                            () => {
                                if (!ended) {
                                    payloads.setWhoIsPayload((prev) => ({
                                        ...prev,
                                        text: props.result,
                                        stopPevAudio: () => audio.pause(),
                                    }));
                                    menu.setView(
                                        menu.COMPONENT_NAME.WHOIS_VIEW
                                    );
                                    ended = true;
                                }
                            }
                        );
                        micro.setRecord(false);

                        return <Views.WhoIs />;
                }
            },
        },
    };
};

const useCommands = (
    setRecord = () => {},
    values = { value: '', setValue: () => {}, resetText: () => {} }
) => {
    const {
        setView,
        COMPONENT_NAME,
        setWhoIsPayload,
        view,
        setAvatarsPayload,
    } = useContext(ViewContext);
    const { setAleshaSpeaking } = useContext(AleshaHeadContext);
    const { user, test, selectedCommand } = useSelector(
        ({ auth, tests, profile }) => ({
            user: auth.data,
            test: tests.perсeptionType,
            selectedCommand: profile.selectedCommand,
        })
    );
    const { getPerceptionType } = useActions(testsActions);

    useEffect(() => {
        if (test.length === 0) getPerceptionType();
    }, [test]);

    const [currentView, setCurrentView] = useState(<></>);
    const [current, setCurrent] = useState({ name: 'loading', props: {} });
    const [nextViewName, setNextViewName] = useState('');
    const [nextProps, setNextProps] = useState({});

    // Получение объекта команд
    const _commands = useMemo(
        () =>
            getCommands(
                user,
                test,
                { setView, COMPONENT_NAME },
                {
                    setWhoIsPayload,
                    setAvatarsPayload,
                },
                { setAleshaSpeaking },
                { setRecord }
            ),
        [user, test]
    );
    // Получение списка поманд, которые можно отображать для пользователя
    const _commandsList = useMemo(
        () =>
            Object.keys(_commands)
                .filter((el) => _commands[el].visableInList)
                .sort(),
        [_commands]
    );
    // Получение индексов команд для пользователя, которые ожидают дополнения при вызове
    const _commandsListIndexWithSubstitution = useMemo(
        () =>
            Object.keys(_commands)
                .filter((el) => _commands[el].visableInList)
                .sort()
                .map((el, i) => {
                    if (/\*/gm.test(el)) return i;
                })
                .filter((el) => el !== undefined),
        [_commands]
    );
    // Получение команд для пользователя, которые ожидают дополнения при вызове
    const _commandsListWithSubstitution = useMemo(
        () =>
            Object.keys(_commands)
                .filter(
                    (el) =>
                        (_commands[el].visableInList ||
                            _commands[el].checking) &&
                        /\*/gm.test(el)
                )
                .map((el) => el.replace(/\*/, '').trim())
                .sort(),
        [_commands]
    );
    // Получение списка команд, при выводе которых не нужно проверять другие команды
    const _notCommandsCheckedList = useMemo(
        () =>
            Object.keys(_commands)
                .filter((el) => _commands[el]?.checkOtherCommands === false)
                .sort(),
        [_commands]
    );

    const _commandsListForHelp = useMemo(
        () =>
            Object.keys(_commands).filter(
                (el) => !!_commands[el].visableInHelps
            ),
        []
    );

    // Установка экрана загрузки для диалога
    const loading = async () => {
        const command = _commands['loading'];
        const _view = await command.getComponent({});
        setCurrentView(_view);
    };
    const commands = async () => {
        const command = _commands['Команды'];
        const _view = await command.getComponent({
            withAudio: false,
            withRecord: true,
        });
        setCurrentView(_view);
    };

    // Установка команды
    const _setCurrentViewName = useCallback(
        (name, props) => {
            // Проверка, что не пытаются установить ту же команду
            if (
                current.name === name &&
                JSON.stringify(current.props) === JSON.stringify(props)
            ) {
                return;
            }

            if (view === COMPONENT_NAME.WHOIS_VIEW || !/\*/g.test(name)) {
                setView('');
            }

            setCurrent(() => ({ name, props: props ? props : undefined }));
        },
        [nextProps, nextViewName]
    );

    const getView = useCallback(async () => {
        // if (/назад/gm.test(current?.props?.text?.toLowerCase())) return;
        // Устанавливаем лоадер
        await loading();
        // Создаем копию
        const tmp = { ...current };

        // Если устанавливается загрузка, rturn
        if (current.name === 'loading') return;
        // Если имя команды есть, достаем команду
        let command = _commands[tmp.name ? tmp.name : ''];
        // Если у команды есть референс, колучаем компонент референса
        if (command.reference !== undefined) {
            tmp.props.text = tmp.name;
            tmp.name = command.reference;
            command = _commands[command.reference];
        }

        // Если командаы такой команды нет
        if (command === undefined) {
            console.log(`%command "%s" not found`, 'color: red', tmp.name);
            return;
        }

        // Если в названии есть зведочка
        if (/\*/gm.test(tmp.name) || tmp.name === '') {
            // Текст пустой или содержит "назад"
            if (
                !tmp?.props?.text ||
                /назад|в меню/gm.test(tmp?.props?.text?.toLowerCase())
            ) {
                // values?.resetText();
                // Выводим компонент команд
                commands();
                return;
            }
            // Заменяем звездочку
            const test = tmp?.props?.text.replace('*', '').trim();
            if (test.split(' ').length <= 2) {
                if (
                    ['это', 'что такое', 'кто такой', 'кто такая'].includes(
                        test.toLowerCase().trim()
                    )
                ) {
                    commands();
                    return;
                }
            }

            // делаем запрос на получение ответа
            const params = new URLSearchParams({
                search: tmp?.props?.text,
            }).toString();
            const response = await api.get(`/commands/ask?${params}`);

            if (response.status === 200) {
                // получаем компонент
                const _view = await command.getComponent(response.data);
                // Устанавливаем его
                setCurrentView(_view);
                return _view;
            }
        } else {
            if (typeof command.getComponent === 'function') {
                const _view = await command.getComponent(
                    current.props
                        ? JSON.parse(JSON.stringify(current.props))
                        : {}
                );

                setCurrentView(_view);
                return _view;
            }
        }
    }, [current]);

    // Переключение к следующей команде
    const toNextCommand = () => {
        setCurrent(() => ({ name: nextViewName, props: nextProps }));
    };

    useEffect(() => {
        getView();
    }, [current]);
    useEffect(() => {
        if (selectedCommand !== current.name) {
            setCurrent((prev) => ({ ...prev, name: selectedCommand }));
        }
    }, [selectedCommand]);

    // useEffect(() => {
    //     console.log(nextViewName, nextProps);
    //     console.log(
    //         `%cNext command: %c%s`,
    //         'color: aqua',
    //         'color: darkorange',
    //         nextViewName
    //     );
    // }, [nextViewName, nextProps]);
    // useEffect(() => {
    //     console.log(
    //         `%cCurrent command: %c%s`,
    //         'color: aqua',
    //         'color: darkorange',
    //         currentViewName
    //     );
    //     console.log(
    //         `%cCurrent command view: %c%s`,
    //         'color: aqua',
    //         'color: darkorange',
    //         currentView?.type?.name
    //     );
    // }, [currentView]);

    return {
        commands: _commands,
        commandsList: _commandsList,
        notCommandsCheckedList: _notCommandsCheckedList,
        commandsListWithSubstitution: _commandsListWithSubstitution,
        commandsListIndexWithSubstitution: _commandsListIndexWithSubstitution,
        commandsListForHelp: _commandsListForHelp,
        currentView,
        currentViewName: current.name,
        setCurrentViewName: _setCurrentViewName,
        toNextCommand,
        setNextProps,
        nextViewName,
        setNextViewName,
    };
};

export { useCommands };
