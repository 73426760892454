import { api } from '../App';

const getNode = (courseId, type) => {
    return api.get(`courses/${courseId}/schemes/node?type=${type}`);
};

const updateNode = ({ courseId, answer, type, prev = false }) => {
    return api.put(`courses/${courseId}/schemes/node?prev=${prev.toString()}`, {
        answer,
        type,
    });
};

const replayScheme = (courseId) => {
    return api.delete(`courses/${courseId}/schemes/node`);
};

const getThemes = (courseId) => {
    return api.get(`/courses/${courseId}/grades`);
};

const getTheoryMaterials = (courseId) => {
    return api.get(`/courses/${courseId}/files`);
};

const getElectronicMaterials = (weekId, scheduleId, courseId, themeId) => {
    return api.get(
        `/courses/${courseId}/${themeId}/files?week=${weekId}&schedule=${scheduleId}`
    );
};

const createReview = (courseId, review) => {
    return api.post(`courses/${courseId}/review`, review);
};

const getReviews = (courseId) => {
    return api.get(`courses/${courseId}/reviews`);
};

const getVideos = (courseId) => {
    return api.get(`courses/${courseId}/videos`);
};

const addVideoView = (videoId) => {
    return api.put(`courses/video/${videoId}/view`);
};

const addVideoLike = (videoId) => {
    return api.put(`courses/video/${videoId}/like`);
};

const deleteVideoLike = (videoId) => {
    return api.delete(`courses/video/${videoId}/like`);
};

export {
    getNode,
    updateNode,
    replayScheme,
    getThemes,
    getTheoryMaterials,
    getElectronicMaterials,
    createReview,
    getReviews,
    getVideos,
    addVideoView,
    addVideoLike,
    deleteVideoLike,
};
