import React, { useContext, useEffect } from 'react';

import { ViewContext, viewInitState, DialogContext } from '../../../utils';

import { useActions } from '@h';
import { profileActions } from '@r/profile/profileSlice';

import { AddAnswer } from './AddAnswer';
import { DispalyAnswer } from './DisplayAnswer';
import { EditAnswer } from './EditAnswer';

const types = {
    display: DispalyAnswer,
    add: AddAnswer,
    edit: EditAnswer,
};

const WhoIs = () => {
    const { whoIsPayload, setWhoIsPayload } = useContext(ViewContext);
    const { clean } = useContext(DialogContext);
    const { changeSelectedCommand } = useActions(profileActions);

    useEffect(() => {
        return () => {
            setWhoIsPayload((prev) => ({
                ...prev,
                ...viewInitState.whoIsPayload,
            }));
            clean(['назад']);
            changeSelectedCommand('');
        };
    }, []);

    return React.createElement(types[whoIsPayload.type]);
};

export { WhoIs };
