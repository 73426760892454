import { call, put, takeLatest } from "redux-saga/effects";
import {
    getPeriods,
    getCourses,
    getScheme,
    saveScheme,
} from "../../api/teacher";
import { teacherActions } from "./teacherSlice";

const {
    periodsStart,
    coursesStart,
    schemeStart,
    saveSchemeStart,
    saveChangeStart,
    setPeriods,
    setSchemeId,
    setCourses,
    setScheme,
    toggleLoading,
} = teacherActions;

export function* teacherWatcher() {
    yield takeLatest(periodsStart.type, setPeriodsWorker);
    yield takeLatest(coursesStart.type, setCoursesWorker);
    yield takeLatest(schemeStart.type, setSchemeWorker);
    yield takeLatest(saveSchemeStart.type, saveSchemeWorker);
    yield takeLatest(saveChangeStart.type, changeSchemeWorker);
}

function* setPeriodsWorker() {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getPeriods);
        yield put(setPeriods(response.data));
    } catch (error) {
        yield console.log("что-то пошло не так", error);
    } finally {
        yield put(toggleLoading(false));
    }
}

function* setCoursesWorker(action) {
    const yearId = action.payload;
    yield put(toggleLoading(true));
    const response = yield call(getCourses, yearId);
    yield put(setCourses(response.data));
    yield put(toggleLoading(false));
}

function* setSchemeWorker(action) {
    try {
        const courseId = action.payload;
        yield put(toggleLoading(true));
        const response = yield call(getScheme, courseId);
        if (response.status === 200) {
            yield put(setScheme(response.data));
            yield put(setSchemeId(courseId))
        }
    } catch (error) {
        yield console.log("что-то пошло не так", error)
    } finally {
        yield put(toggleLoading(false));
    }
}

function* changeSchemeWorker(action) {
    const newScheme = action.payload;
    yield put(setScheme(newScheme));
}

function* saveSchemeWorker(action) {
    const { data, schemeId } = action.payload;
    try {
        yield put(toggleLoading(true));
        const response = yield call(saveScheme, { data, schemeId });
        yield put(setScheme(response.data))
        yield put(toggleLoading(false));
    } catch (error) {
        yield alert("что-то пошло не так");
        yield put(toggleLoading(false));
    }
}