import React, { useState, useEffect } from 'react'

import { Button, AudioVisualiser } from '@c'
import { toClassNames } from '@helps'

import styles from '../style.module.scss'

const ReadingView = ({
    timerSeconds = 0,
    title = '',
    author = '',
    text = '',
    length = 0,
    onStop = () => { }
}) => {
    const [_timerSeconds, setTimerSeconds] = useState('00')
    const [_timerMinutes, setTimerMinutes] = useState('00')

    useEffect(() => {
        const minutes = Math.floor(timerSeconds / 60).toFixed(0)
        setTimerMinutes(minutes > 9 ? minutes : `0${minutes}`)
        const _seconds = 60 - timerSeconds % 60
        setTimerSeconds(_seconds > 9 ? _seconds : `0${_seconds}`)
    }, [timerSeconds])

    return (
        <div className={styles.main}>
            <div className={styles.timer}>
                <p>{_timerMinutes}:{_timerSeconds}</p>
            </div>
            <div className={styles.text_container}>
                <p className={styles.title}>{title}</p>
                <p className={styles.author}>{author}</p>
                {/* <div className={styles.reading_text_container}> */}
                {/* <ScaledText widthOnly={true} minFontSize={8} maxFontSize={25}> */}
                {/* {text} */}
                <p dangerouslySetInnerHTML={{ __html: text }} className={styles.text} />
                {/* </ScaledText> */}
                {/* </div> */}
                <p className={styles.text_lenght}>{length} слов</p>
            </div>
            <AudioVisualiser />
            <Button
                onClick={onStop}
                className={toClassNames(styles.button, styles.button__reding)}
            >
                Остановить таймер
            </Button>
        </div>
    )
}

export { ReadingView }