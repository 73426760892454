import { api } from '@src/App';

/**
 * Получение аватара пользователя
 * @param {String} userId
 */
const getUserAvatar = (userId) => {
    return api
        .get(`people/photo/${userId}`, { responseType: 'arraybuffer' })
        .then((response) =>
            Buffer.from(response.data, 'binary').toString('base64')
        )
        .then((base64) => 'data:image/jpeg;base64,' + base64);
};

export { getUserAvatar };
