import React, { useEffect, useState, useContext } from 'react';
import { Handle } from 'react-flow-renderer';
import { FiTrash2, FiEdit } from 'react-icons/fi';

import { Tab, Tabs, ModalWrapper } from '../components';
import { toClassNames } from '@helps';

import { Form } from './Form';
import { ModalContext } from '../../../Modal';
import { types } from '../utils';

import styles from './style.module.scss';

const QuestionDefaultDemonstration = ({ onDragStart = () => {} }) => {
    return (
        <div
            className={styles.container_demonstration}
            onDragStart={onDragStart}
            draggable
        >
            <p>Вопрос</p>
        </div>
    );
};

const QuestionWithTypesDemonstaration = ({ onDragStart = () => {} }) => {
    return (
        <div
            className={styles.container_demonstration}
            onDragStart={onDragStart}
            draggable
        >
            <p>Вопрос (по типам восприятия)</p>
        </div>
    );
};

const Handles = ({ children }) => {
    return (
        <>
            <Handle
                type="target"
                position="left"
                id="in"
                className={styles.point_in}
            />
            {children}
            <Handle
                type="source"
                position="right"
                id="true"
                className={toClassNames(
                    styles.point_out,
                    styles.point_out__true
                )}
                style={{ top: '40%' }}
            />
            <Handle
                type="source"
                position="right"
                id="false"
                className={toClassNames(
                    styles.point_out,
                    styles.point_out__false
                )}
                style={{ top: '60%' }}
            />
        </>
    );
};

const defaultDataInitValue = {
    text: '',
    correctAnswer: '',
    images: [],
    videos: [],
    type: 'multi',
    answers: [],
    solution: '',
    shuffle: false,
    correctAnswers: [],
    onChange: () => {},
    onRemove: () => {},
};

const dataByTypesInitValue = {
    audial: { ...defaultDataInitValue },
    visual: { ...defaultDataInitValue },
    kinesthetic: { ...defaultDataInitValue },
    discrete: { ...defaultDataInitValue },
    onChange: () => {},
    onRemove: () => {},
};

const QuestionDefault = ({ id = '', data = {} }) => {
    const { show, close } = useContext(ModalContext);
    const [_data, setData] = useState({
        ...defaultDataInitValue,
        ...data,
    });

    useEffect(() => {
        _data.onChange(id, _data);
    }, [_data, id]);

    const editOpen = () => {
        show(
            <ModalWrapper onButtonClick={() => close()}>
                <Form
                    data={_data}
                    onChange={(val) => setData((prev) => ({ ...prev, ...val }))}
                />
            </ModalWrapper>
        );
    };

    return (
        <div className={styles.container}>
            <Handles>
                <FiTrash2
                    className={styles.icon}
                    onClick={() => data.onRemove(id)}
                    size={30}
                    color="red"
                />
                <FiEdit
                    className={styles.edit}
                    size={30}
                    color={'#3b82f6'}
                    onClick={() => editOpen()}
                />
                <p className={styles.header}>Вопрос</p>
                <p className={styles.description}>
                    {data.text &&
                        data.text.replace(/<[^>]+>/gm, '').slice(0, 200)}
                </p>
            </Handles>
        </div>
    );
};

const QuestionWithTypes = ({ id, data = {} }) => {
    const { show, close } = useContext(ModalContext);
    const [_data, setData] = useState({ ...dataByTypesInitValue, ...data });

    useEffect(() => {
        _data.onChange(id, _data);
    }, [_data, id]);

    const editOpen = () =>
        show(
            <ModalWrapper onButtonClick={() => close()}>
                <Tabs>
                    {Object.keys(types).map((key) => (
                        <Tab name={types[key]} key={key}>
                            <Form
                                data={{ ..._data[key] }}
                                onChange={(val) =>
                                    setData((prev) => ({
                                        ...prev,
                                        [key]: { ...prev[key], ...val },
                                    }))
                                }
                            />
                        </Tab>
                    ))}
                </Tabs>
            </ModalWrapper>
        );

    return (
        <div className={styles.container}>
            <Handles>
                <FiTrash2
                    className={styles.icon}
                    onClick={() => data.onRemove(id)}
                    size={30}
                    color={'red'}
                />
                <FiEdit
                    className={styles.edit}
                    size={30}
                    color={'#3b82f6'}
                    onClick={() => editOpen()}
                />
                <p className={styles.header}>Вопрос (по типам восприятия)</p>
            </Handles>
        </div>
    );
};

export {
    QuestionDefault,
    QuestionDefaultDemonstration,
    QuestionWithTypes,
    QuestionWithTypesDemonstaration,
};
