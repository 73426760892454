/**
 * Перевод из байт в мб и т.д.
 * @param {Number} bytes - размер в байтах
 * @param {Number} decimals - цифр после запятой
 * @param {1000|1024} k - размер
 * @returns
 */
const formatBytesCount = (bytes, decimals = 2, k = 1024) => {
    if (bytes === 0) return '0 Bytes';

    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export { formatBytesCount };
