import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

export const PrivateRoute = ({ component, render, ...props }) => {
  const authData = useSelector(({ auth }) => auth.data)

  const Component = render ? render : component
  const authDataExists = authData !== null && authData !== undefined

  return <Route {...props} render={props => (authDataExists ? <Component {...props} /> : <Redirect to={'/auth'} />)} />
}
