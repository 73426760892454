import React, { useEffect } from 'react'

import { Button } from '@c'
import { toClassNames, TTS } from '@helps/'
import { useAudio } from '@h'

import styles from './style.module.scss'

const ToMenuView = ({
    onCancel = () => { },
    onExit = () => { }
}) => {
    const { playAudio } = useAudio()

    const getAudio = async () => {
        const audio = await TTS('Вы действительно хотите выйти в меню?')
        const url = URL.createObjectURL(audio.data)
        playAudio(url)
    }

    useEffect(() => {
        getAudio()
    }, [])

    return (
        <div className={styles.root}>
            <p>Вы действительно хотите выйти в меню?</p>
            <div className={styles.buttons_container}>
                <Button
                    className={toClassNames(styles.button, styles.button__yes)}
                    onClick={onExit}
                >Да</Button>
                <Button
                    className={toClassNames(styles.button, styles.button__no)}
                    onClick={onCancel}
                >Назад</Button>
            </div>
        </div>
    )
}

export { ToMenuView }