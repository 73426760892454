import React, { useEffect } from 'react';
import YouTube from 'react-youtube';

import styles from './style.module.css';

import { AudioPlayer } from '@c/';

const Text = ({ data = {} }) => {
    return (
        <div className={styles.data_container}>
            <p>{data.text}</p>
            <div className={styles.image_container}>
                {data.images?.map((el, i) => (
                    <img key={i} src={el} alt="Изображение" />
                ))}
            </div>
            <div className={styles.video_container}>
                {data.videos?.map((el, i) => (
                    <YouTube
                        key={i}
                        containerClassName={styles.youtube}
                        videoId={el}
                    />
                ))}
            </div>
            <div className={styles.audio_container}>
                {data.audios?.map((el, i) => (
                    <AudioPlayer url={el.src} name={el.name} />
                ))}
            </div>
        </div>
    );
};

export { Text };
