import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';

import { Button, AleshasHead } from '@c';
import { toClassNames } from '@helps';
import { useActions } from '@h';
import { courseActions } from '@r/courses/courseSlice';

import { Header } from './Header';
import { Course } from './Course';
import { LessonContext, CONSTANTS } from './utils';
import { DialogContext } from '../../../../utils';

import styles from './style.module.scss';

const Lesson = ({ onMenu = () => {} }) => {
    const { setCheckCommands, clean } = useContext(DialogContext);

    const { data: authData } = useSelector(({ auth }) => auth);
    const { currentTheme, currentNode } = useSelector(({ course }) => course);
    const { replaySchemeStart, toggleInProcess } = useActions(courseActions);

    const [visable, setVisable] = useState(false);
    const [exit, setExit] = useState(false);
    const [headAmin, setHeadAnim] = useState(false);

    useEffect(() => {
        toggleInProcess(true);
        setCheckCommands(false);
        setTimeout(() => setVisable(true), 500);

        return () => {
            toggleInProcess(false);
            setCheckCommands(true);
        };
    }, []);

    const _onEnd = useCallback(() => {
        setVisable(false);
        setExit(true);
        replaySchemeStart({
            courseId: currentTheme.id,
            type: authData.typeInformationPercept,
            withReset: true,
        });
        setTimeout(() => {}, 400);
    });

    const _onMenu = useCallback(() => {
        if (currentNode?.type === 'finish') {
            _onEnd();
            return;
        }

        clean(CONSTANTS.WORDS_TO_CONTROL);
        setVisable(false);
        setExit(true);
        setTimeout(() => {
            onMenu();
        }, 400);
    }, [onMenu, currentNode, _onEnd]);

    return (
        <div className={toClassNames(styles.root, exit && styles.root__exit)}>
            {visable && (
                <>
                    <div className={styles.main_container}>
                        <Header />
                        <LessonContext.Provider
                            value={{
                                setHeadAnim,
                            }}
                        >
                            <div className={styles.main}>
                                <div className={styles.menu}>
                                    <Button
                                        className={styles.button}
                                        onClick={() => _onMenu()}
                                    >
                                        К выбору темы
                                    </Button>
                                    <Button
                                        className={styles.button}
                                        onClick={() =>
                                            replaySchemeStart({
                                                courseId: currentTheme.id,
                                                type: authData.typeInformationPercept,
                                            })
                                        }
                                    >
                                        Начать занятие с начала
                                    </Button>
                                    <AleshasHead
                                        className={styles.head}
                                        aleshaSpeaking={headAmin}
                                    />
                                </div>
                                <div className={styles.content}>
                                    <Course
                                        onCurseEnd={() => _onEnd()}
                                        onMenu={() => _onMenu()}
                                    />
                                </div>
                            </div>
                        </LessonContext.Provider>
                    </div>
                </>
            )}
        </div>
    );
};

export { Lesson };
