import React, { createContext, useState } from 'react';

import styles from './style.module.scss';

const ModalContext = createContext({
    showing: false,
    show: (element = <></>) => {},
    close: () => {},
});

const Modal = ({ children }) => {
    return <div className={styles.modal}>{children}</div>;
};

const ModalProvder = ({ children }) => {
    const [modalContent, setModalContent] = useState(<></>);
    const [display, setDisplay] = useState(false);

    const show = (content = <></>) => {
        setModalContent(content);
        setDisplay(true);
    };

    const close = () => {
        setModalContent(<></>);
        setDisplay(false);
    };

    return (
        <ModalContext.Provider
            value={{
                showing: display,
                show,
                close,
            }}
        >
            {display && (
                <div className={styles.modal_container}>
                    <div className={styles.modal}>{modalContent}</div>
                </div>
            )}
            {children}
        </ModalContext.Provider>
    );
};

export { ModalProvder, ModalContext };
