import React, { useState, useEffect, useCallback, useRef } from 'react';

/**@type {HTMLAudioElement|null} */
let audio = null;

const useAudio = (onAudioEnded = () => {}, onAudioStart = () => {}) => {
    const ref = useRef(null);
    useEffect(() => {
        ref.current = {
            state: {
                play: false,
                init: false,
                url: '',
            },
            setState(func = () => {}) {
                this.state = func(this.state);
            },
            stopped: undefined,
            setStopped(val) {
                this.stopped = val;
            },
        };
    }, []);

    const endPlay = useCallback(() => {
        ref.current?.setState((prev) => ({ ...prev, play: false, url: '' }));
    }, [ref]);

    const initAudio = useCallback(() => {
        if (!audio) {
            audio = new Audio();
            audio.onended = () => {
                ref.current?.setState((prev) => ({ ...prev, play: false }));
            };
            ref.current?.setState((prev) => ({ ...prev, init: true }));
        }
    }, [ref]);

    useEffect(() => {
        const onError = () => {
            ref.current?.setState((prev) => ({
                ...prev,
                play: false,
                url: '',
            }));
        };

        if (audio) {
            audio.addEventListener('ended', onAudioEnded);
            audio.addEventListener('ended', endPlay);
            audio.addEventListener('error', onError);
        }

        return () => {
            audio?.removeEventListener('ended', endPlay);
            audio?.removeEventListener('ended', onAudioEnded);
            audio?.removeEventListener('error', onError);
        };
    }, [onAudioEnded]);

    const playAudio = useCallback(
        (url, base64 = false) => {
            if (
                url !== ref.current?.state?.url &&
                ref.current?.state?.url !== ''
            ) {
                onAudioEnded();
            }

            onAudioStart();
            ref.current?.setStopped(false);

            if (base64) {
                audio.src = `data:audio/ogg;codecs=opus;base64,${url}`;
            } else {
                audio.src = url;
            }

            ref.current?.setState((prev) => ({ ...prev, play: true, url }));
            audio.onloadeddata = () => {
                if (!ref.current?.stopped) {
                    audio.currentTime = 0;
                    audio.play();
                }
            };
        },
        [onAudioEnded, ref]
    );

    const stopAudio = useCallback(() => {
        onAudioStart();

        if (audio === null) {
            initAudio();
            ref.current?.setStopped(true);
            ref.current?.setState((prev) => ({ ...prev, play: false }));
            return;
        }

        audio.pause();
        audio.currentTime = 0;
        ref.current?.setStopped(true);
        ref.current?.setState((prev) => ({ ...prev, play: false }));
    }, [ref]);

    return { initAudio, playAudio, stopAudio, state: ref.current?.state || {} };
};

export { useAudio };
