import React from 'react'
import styles from './style.module.scss'

const Finish = () => {
    return (
        <div>
            <p className={styles.message}>Поздавляю, вы прошли курс!</p>
        </div>
    )
}

export { Finish }