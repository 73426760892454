import React, { useState } from 'react';

import YouTube from 'react-youtube';

import { Button2 } from '@c';

import { FiTrash2 } from 'react-icons/fi';

const Videos = ({
    value = [],
    onChange = () => {},
    videosClassName = '',
    videoClassName = '',
    iconClassName = '',
    frameClassName = '',
    buttonClassName = '',
    buttonsClassName = '',
}) => {
    const [isAddVideo, setIsAddVideo] = useState(false);
    const [link, setLink] = useState('');

    return (
        <>
            <div className={videosClassName}>
                {value.map((el, i) => (
                    <div key={i} className={videoClassName}>
                        <FiTrash2
                            className={iconClassName}
                            size={25}
                            color="white"
                            onClick={() =>
                                onChange(value.filter((_, idx) => idx !== i))
                            }
                        />
                        <YouTube
                            containerClassName={frameClassName}
                            videoId={el}
                        />
                    </div>
                ))}
            </div>
            {isAddVideo ? (
                <>
                    <input
                        value={link}
                        onChange={({ target: { value } }) => setLink(value)}
                        placeholder="Ссылка на видео"
                    />
                    <div className={buttonsClassName}>
                        <Button2
                            title="Добавить"
                            className={buttonClassName}
                            onClick={() => {
                                const matchs =
                                    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/g.exec(
                                        link
                                    );
                                let id = matchs[7] || '';
                                onChange([...value, id]);
                                setLink('');
                                setIsAddVideo(false);
                            }}
                        />
                        <Button2
                            title="Отмена"
                            className={buttonClassName}
                            onClick={() => setIsAddVideo(false)}
                        />
                    </div>
                </>
            ) : (
                <Button2
                    title="Добавить видео"
                    className={buttonClassName}
                    onClick={() => setIsAddVideo(true)}
                />
            )}
        </>
    );
};

export { Videos };
