import React, { useContext, useEffect } from 'react';

import { ViewContext } from '../../../../utils';
import * as ViewTypes from './PayloadViews';

import styles from './style.module.scss';

const TypeTestView = ({ onTestFinishProp = () => {} }) => {
    const { lessonPayload } = useContext(ViewContext);

    useEffect(() => {
        return () => {
            onTestFinishProp();
        };
    }, []);

    return (
        <div className={styles.main}>
            {!lessonPayload.typeInformationPerceptChange ? (
                lessonPayload.type ? (
                    React.createElement(
                        ViewTypes[lessonPayload.type],
                        lessonPayload
                    )
                ) : (
                    <p className={styles.message}>
                        Сначала необходимо пройти тест по определению типа
                        восприятия.
                    </p>
                )
            ) : (
                <p className={styles.message}>
                    По результатам теста ты -{' '}
                    {lessonPayload.typeInformationPerceptChange}!
                </p>
            )}
        </div>
    );
};

export { TypeTestView };
