import React, { useState, useEffect, useRef } from 'react';
import YouTube from 'react-youtube';

import { AudioPlayer, Corusel, Button } from '@c/';

import { FiChevronsDown } from 'react-icons/fi';

import styles from './style.module.scss';

const Question = ({ data = {}, onAnswerChange = () => {}, value }) => {
    const answersRef = useRef(null);
    const blockRef = useRef(null);
    const payloadRef = useRef(null);
    const textRef = useRef(null);

    const [checked, setChecked] = useState([]);
    const [displayButton, setDisplayButton] = useState(true);
    const [showSolution, setShowSolution] = useState(false);

    useEffect(() => {
        if (data.type === 'multi') {
            onAnswerChange(checked);
        }
    }, [checked]);

    useEffect(() => {
        if (
            blockRef.current &&
            answersRef.current &&
            payloadRef.current &&
            textRef.current
        ) {
            const blockHeight = blockRef.current.offsetHeight;
            const answersHeight = answersRef.current.offsetHeight;
            const payloadHeight = payloadRef.current.offsetHeight;
            const textheight = textRef.current.offsetHeight;

            const scrollHandler = () => {
                if (
                    payloadHeight +
                        textheight -
                        answersHeight / 2 -
                        blockRef.current.scrollTop <=
                    blockHeight
                ) {
                    setDisplayButton(false);
                } else {
                    setDisplayButton(true);
                }
            };

            blockRef.current.addEventListener('scroll', scrollHandler);

            return () => {
                if (
                    blockRef.current &&
                    answersRef.current &&
                    payloadRef.current &&
                    textRef.current
                ) {
                    blockRef.current.removeEventListener(
                        'scroll',
                        scrollHandler
                    );
                }
            };
        }
    }, [blockRef, answersRef, payloadRef, textRef]);

    useEffect(() => {
        value.split(' ').forEach((el) => {
            let _val = parseInt(el);

            if (!isNaN(_val)) {
                _val -= 1;

                if (data.answers[_val]) {
                    if (checked.indexOf(_val) < 0) {
                        setChecked((prev) => {
                            if (data.oneAnswer)
                                return [data.answers[_val].index];
                            else return [...prev, data.answers[_val].index];
                        });
                    } else {
                        setChecked((prev) => {
                            let tmp = prev.filter(
                                (el) => el !== data.answers[_val].index
                            );
                            return tmp;
                        });
                    }
                }
            }
        });
    }, [value]);

    return (
        <div ref={blockRef} className={styles.data_container}>
            <button
                onClick={() => {
                    answersRef.current.scrollIntoView({
                        block: 'center',
                        behavior: 'smooth',
                    });
                }}
                style={{
                    display: displayButton ? 'block' : 'none',
                }}
                className={styles.scroll_button}
            >
                <FiChevronsDown color={'white'} size={'70%'} />
            </button>
            <div
                ref={textRef}
                dangerouslySetInnerHTML={{ __html: data.text }}
                className={styles.text}
            />
            <div ref={payloadRef} className={styles.payload}>
                {data?.images?.length > 0 && (
                    <div className={styles.image_container}>
                        <Corusel
                            items={data.images.map((el) => (
                                <img src={el} />
                            ))}
                        />
                    </div>
                )}
                {data?.videos?.length > 0 && (
                    <div className={styles.video_container}>
                        <Corusel
                            items={data.videos.map((el) => (
                                <YouTube
                                    containerClassName={styles.youtube}
                                    videoId={el}
                                />
                            ))}
                        />
                    </div>
                )}
                {data?.audios?.length > 0 && (
                    <div className={styles.audio_container}>
                        {data.audios?.map((el, i) => (
                            <AudioPlayer key={i} url={el.src} name={el.name} />
                        ))}
                    </div>
                )}
                {data?.solution?.length > 0 && (
                    <div className={styles.solution_container}>
                        {showSolution ? (
                            <div className={styles.content}>
                                <div className={styles.header}>
                                    <Button
                                        className={styles.button}
                                        onClick={() => setShowSolution(false)}
                                    >
                                        <span>Скрыть решение</span>
                                    </Button>
                                </div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: data.solution,
                                    }}
                                ></div>
                            </div>
                        ) : (
                            <Button
                                className={styles.button}
                                onClick={() => setShowSolution(true)}
                            >
                                <span>Показать решение</span>
                            </Button>
                        )}
                    </div>
                )}
                {data.type === 'multi' && (
                    <div ref={answersRef} className={styles.answers}>
                        <span>
                            Для выбора ответа вы можете сказать его номер. Для
                            отмены ответа также необходимо сказать его номер.
                        </span>
                        {data.answers.map((el, i) => (
                            <div className={styles.answer} key={i}>
                                <span className={styles.index}>{i + 1}</span>
                                <input
                                    className={styles.answer_is_correct}
                                    type="checkbox"
                                    onChange={({ target: { checked } }) =>
                                        checked
                                            ? setChecked((prev) => {
                                                  if (data.oneAnswer)
                                                      return [el.index];
                                                  else
                                                      return [
                                                          ...prev,
                                                          el.index,
                                                      ];
                                              })
                                            : setChecked((prev) => {
                                                  let tmp = prev.filter(
                                                      (index) =>
                                                          el.index !== index
                                                  );
                                                  return tmp;
                                              })
                                    }
                                    checked={
                                        checked.find(
                                            (index) => el.index === index
                                        ) !== undefined
                                    }
                                />
                                {!/(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/gm.test(
                                    el.title
                                ) ? (
                                    <span>{el.title}</span>
                                ) : (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: el.title,
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export { Question };
