import React, {
    useContext,
    useMemo,
    useState,
    useRef,
    useCallback,
    useEffect,
} from 'react';
import { useSelector } from 'react-redux';

import { useActions } from '@h';
import { useLoadAudioFile } from '@h/useLoadAudioFile';
import { audiosActions } from '@r/audios/audiosSlice';
import { ViewContext, AleshaHeadContext, DialogContext } from '../../../utils';

import styles from './style.module.scss';

const ThemeSelectorView = () => {
    const { themeSelector, setThemeSelector } = useContext(ViewContext);
    const { setAleshaSpeaking } = useContext(AleshaHeadContext);
    const {
        record,
        setRecord,
        value,
        setValue,
        resetText,
        setSelectedCommand,
        results,
        setCheckCommands,
        onStageChange,
        setCheckFullText,
        clean,
    } = useContext(DialogContext);

    const { performance, audioUrls } = useSelector(
        ({ profile: { performance }, audios: { themeSelector } }) => ({
            performance,
            audioUrls: themeSelector,
        })
    );
    const [selectedVariant, setSelectedVariand] = useState(
        themeSelector.course
    );
    const {
        setThemeSelector: setAudioUrls,
        clearThemeSelector: clearAudiosUrls,
    } = useActions(audiosActions);

    const [_performance, setPerformance] = useState([]);

    /**@type {HTMLAudioElement} */
    const audio = useMemo(() => new Audio(), []);
    const dataPromiseCancelRef = useRef(null);
    const { getFileUrl } = useLoadAudioFile(dataPromiseCancelRef);

    // Фразы
    const texts = useMemo(
        () => ({
            clarification:
                'Я не смог точно определить какой предмет ты имеешь ввиду. Назови номер предмета.',
            course: 'Здесь представлены твои успехи в учебе. Назови предмет, чтобы продолжить или скажи назад.',
            theme: 'Выбери тему из представленных на экране или скажи назад',
        }),
        []
    );

    const [resultCheck, setResultCheck] = useState(true);

    // Воспроизведение аудио
    const getAndPlayAudio = useCallback(
        // Аргумент для отладки
        (from = '') => {
            // Выбор текста
            let text = !selectedVariant
                ? themeSelector.clarification.length > 0
                    ? 'clarification'
                    : 'course'
                : 'theme';

            // Файла с текстом нет
            if (!audioUrls[text]) {
                // Получение url
                getFileUrl(
                    texts[text],
                    () => {
                        // Остановка воспроизведения
                        audio.pause();
                        audio.currentTime = 0;
                        setRecord(false);
                        setAleshaSpeaking(false);
                    },
                    (url) => (
                        // Сохранение в редакс
                        setAudioUrls({ key: text, value: url }),
                        // Воспроизведение по url
                        playAudioFile(url, from)
                    )
                );
            } else {
                // Воспроизведение по url
                playAudioFile(audioUrls[text], from);
            }
        },
        [selectedVariant, themeSelector, audioUrls]
    );

    // Воспроизведение файла по url
    const playAudioFile = (url, from = '') => {
        // Для отладки
        // console.log('start', from);
        // Меняем урл
        audio.src = url;
        audio.onended = () => {
            // Отключаем анимацию
            setAleshaSpeaking(false);
            // Включаем микрофон
            setRecord(true);
        };
        audio.play();

        // Включаем анимацию, отключаем микрофон
        setAleshaSpeaking(true);
        record && setRecord(false);
        setValue('');
        resetText();
    };

    // Остановка воспроизведения
    const stopAudio = (withRecordTrue = true, from = '') => {
        // Для отладки
        console.log('stop', from);
        // Остановка получения файла
        if (
            !dataPromiseCancelRef.current.finished &&
            !dataPromiseCancelRef.current.cancelled
        ) {
            console.log('canceling');
            dataPromiseCancelRef.current.cancel();
        }
        audio.pause();
        // Включение микрофона
        setValue('');
        setAleshaSpeaking(false);
        if (withRecordTrue) {
            // console.log('enable micro');
            record && setRecord(true);
        }
    };

    useEffect(() => {
        // Отключаем проверку других команд
        setCheckCommands(false);
        // Сбрасываем значение инпута
        setValue('');
        setCheckFullText(false);

        return () => {
            // Останавливаем воспроизведение аудио
            stopAudio(false, 'unmount');

            // Удаляем url'ы из редакса
            // TODO оптимизировать
            // Предполагалось, что ссылки будет удалять только при изменении аватара
            // Но возникает проблема:
            //      Созданная ссылка на объект удаляется при размонтировании компонента
            clearAudiosUrls();

            // Отключаем анимацию
            setAleshaSpeaking(false);
            setCheckCommands(true);
            setCheckFullText(true);
            if (!themeSelector.cleaningAfterThemeSelect) {
                clean(['в меню', 'назад'], null);
            }
        };
    }, []);

    useEffect(() => {
        // получаем успеваемость
        setPerformance(performance.filter((el) => el.AverageGrade !== null));
    }, [performance]);

    useEffect(() => {
        // Устанавливаем названия предметов для сравнения
        onStageChange(_performance.map((el) => el.CourseName));
    }, [_performance]);

    useEffect(() => {
        // При изменении курса извне устанавливаем тут
        setSelectedVariand(themeSelector.course);
    }, [themeSelector.course]);

    useEffect(() => {
        console.log(3, selectedVariant, themeSelector.theme);
        // Аналогично в обратном порядке
        setThemeSelector((prev) => ({ ...prev, course: selectedVariant }));
        if (!themeSelector.theme) {
            if (
                selectedVariant !== undefined &&
                themeSelector.theme !== undefined
            ) {
                // stopAudio(true, 'useEffect selectedVariant');
                getAndPlayAudio('useEffect selectedVariant');
            }
        }
    }, [selectedVariant]);

    useEffect(() => {
        // При выборе темы извне устанавливаем названия тем для сранения
        if (selectedVariant && themeSelector.themes) {
            onStageChange(themeSelector.themes.map((el) => el.theme));
        }
    }, [themeSelector.themes]);

    useEffect(() => {
        if (!themeSelector.checking) return;
        // Проверка сравнения
        if (
            record &&
            resultCheck &&
            results.filter((el) => el >= 0.5).length > 0 &&
            _performance.length > 0
        ) {
            // Выбор темы
            if (
                selectedVariant &&
                themeSelector.themes &&
                themeSelector.themes.length > 0
            ) {
                const item =
                    themeSelector.themes[results.indexOf(Math.max(...results))];
                setValue('');
                resetText();
                if (themeSelector.cleaningAfterThemeSelect) {
                    clean([value], null);
                }
                setThemeSelector((prev) => ({ ...prev, theme: item }));
                return;
            }

            // Выбор уточненного предмета
            if (themeSelector.clarification.length > 0) {
                setSelectedVariand(
                    themeSelector.clarification[
                        results.indexOf(Math.max(...results))
                    ]
                );
                setValue('');
                resetText();
            } else {
                // Если итог сравнения показывает только один предмет
                if (results.filter((el) => el >= 0.15).length > 1) {
                    const tmp = results
                        .map((el, i) => (el >= 0.15 ? _performance[i] : null))
                        .filter((el) => el !== null);
                    onStageChange(tmp.map((_, i) => `${i + 1}`));
                    setThemeSelector((prev) => ({
                        ...prev,
                        clarification: tmp,
                    }));
                    setValue('');
                    resetText();
                    return;
                }

                if (!/в меню|назад/gm.test(value.toLowerCase())) {
                    // Устанавливаем предметы для сравнения
                    setSelectedVariand(
                        _performance[results.indexOf(Math.max(...results))]
                    );
                    setValue('');
                    resetText();
                }
            }
        }
    }, [results]);

    useEffect(() => {
        if (!themeSelector.checking) return;
        // Выход в меню
        if (resultCheck && record && /в меню/gm.test(value.toLowerCase())) {
            // Исключаем повторное выполнение проверки и попытки
            // остановить воспроизведение
            setResultCheck(false);
            resetText();
            setValue('');
            setSelectedCommand(null);
            return;
        }
        // Назад
        if (resultCheck && record && /назад/gm.test(value.toLowerCase())) {
            // Исключаем повторное выполнение проверки и попытки
            // остановить воспроизведение
            setResultCheck(false);
            resetText();
            setValue('');
            setTimeout(() => {
                setResultCheck(true);
            }, 1500);

            // Если выбрана тема, откатываемся к выбору темы
            if (themeSelector.course && themeSelector.theme) {
                setThemeSelector((prev) => ({ ...prev, theme: null }));
                resetText();
                setValue('');
                return;
            }

            // Если уточняется предмет, к выбору предмета
            if (themeSelector.clarification.length > 0) {
                setThemeSelector((prev) => ({
                    ...prev,
                    clarification: [],
                }));
                resetText();
                setValue('');
                onStageChange(_performance.map((el) => el.CourseName));
                return;
            }

            // Если выбран предмет, к выбору предмета
            if (selectedVariant) {
                setSelectedVariand(null);
                resetText();
                setValue('');
                onStageChange(_performance.map((el) => el.CourseName));
                return;
            }

            // Если предмет не выбран, выходим в меню
            resetText();
            setValue('');
            setSelectedCommand(null);
        }
    }, [value]);

    useEffect(() => {
        // Проигрывание фразы
        if (themeSelector.clarification.length > 0) {
            getAndPlayAudio('useEffect themeSelector.clarification');
        } else if (!themeSelector.theme) {
            setResultCheck(true);
            onStageChange(_performance.map((el) => el.CourseName));
            stopAudio(true, 'useEffect themeSelector.clarification');
        }
    }, [themeSelector.clarification]);

    useEffect(() => {
        // Проигрывание фразы
        if (themeSelector.course && themeSelector.theme === null) {
            getAndPlayAudio('useEffect themeSelector.theme');
        } else if (themeSelector.course && themeSelector.theme) {
            stopAudio(false, 'useEffect themeSelector.theme');
        }
    }, [themeSelector.theme]);

    return (
        <div className={styles.availableCommands}>
            {_performance.length > 0 && !selectedVariant && (
                <p>Выбери предмет</p>
            )}
            {selectedVariant && themeSelector.themes && <p>Выбери тему</p>}
            Для выхода скажи "назад" или "в меню"
        </div>
    );
};

export { ThemeSelectorView };
