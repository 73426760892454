import React, { useContext, useEffect, useState } from 'react';

import {
    ViewContext,
    AleshaHeadContext,
    viewInitState,
    DialogContext,
} from '../../../../utils';
import { Button } from '@c';
import { createWikiRecord } from '@a/commands';

import styles from './style.module.scss';

const AddAnswer = () => {
    const { whoIsPayload, setWhoIsPayload } = useContext(ViewContext);
    const { setAleshaSpeaking } = useContext(AleshaHeadContext);
    const { value: sttValue, setTextCleanup } = useContext(DialogContext);

    const [value, setValue] = useState('');
    const [saveSuccess, setSaveSuccess] = useState(null);

    useEffect(() => {
        // Очищаем инпут
        setTextCleanup(false);

        return () => {
            // Отключаем анимацию головы
            setAleshaSpeaking(false);
            // Сбрасываем стейт ответов на вопрос
            setWhoIsPayload({
                ...viewInitState.whoIsPayload,
            });
            // Очищаем инпут
            setTextCleanup(true);
        };
    }, []);

    const onCreate = () => {
        // Если воод закончен
        if (whoIsPayload.answerConfirmed === true) {
            // Делаем запрос на создание ответа
            createWikiRecord(whoIsPayload.text, value)
                .then((res) => res.data)
                .then((res) => {
                    // Проигрываем текст
                    whoIsPayload.playAnswer('answerSaveSuccess');
                    // Устанавливаем новую инфу в контекст
                    whoIsPayload.setInfo(res);
                    // Показываем, что все сохранилоаь
                    setSaveSuccess(true);
                });
        } else {
            // Проигрываем просьбу проверить
            whoIsPayload.playAnswer('answerConfirm');
            // Ставим статус, что ввод закончен
            setWhoIsPayload((prev) => ({
                ...prev,
                answerConfirmed: true,
            }));
            // Очищаем текст
            setTextCleanup(true);
        }
    };

    // При изменение распознаного текста, устанавливаем его инпуту,
    // в случае, если статус "ввнод не закончен"
    useEffect(() => {
        if (!whoIsPayload.answerConfirmed) {
            setValue(sttValue);
        }
    }, [sttValue]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.main}>
                {saveSuccess === null ? (
                    <>
                        <span>
                            Скажи правильный по твоему мнению ответ на вопрос
                            &laquo;
                            {whoIsPayload.text}&raquo;
                        </span>
                        <textarea
                            value={value}
                            onChange={({ target: { value } }) =>
                                setValue(value)
                            }
                        />
                        <Button className={styles.save} onClick={onCreate}>
                            Подтвердить
                        </Button>
                    </>
                ) : (
                    <div className={styles.answer}>
                        <span>
                            Твой ответ на вопрос &laquo;
                            {whoIsPayload.text}&raquo;:
                        </span>
                        <div className={styles.text_container}>
                            {whoIsPayload.info?.extract
                                ?.split('\n')
                                ?.map((el, i) => (
                                    <span key={i}>{el}</span>
                                ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export { AddAnswer };
