import { combineReducers } from '@reduxjs/toolkit';
import { audios } from './audios/audiosSlice';
import { auth } from './auth/authSlice';
import { profile } from './profile/profileSlice';
import { teacher } from './teacher/teacherSlice';
import { course } from './courses/courseSlice';
import { tests } from './tests/testsSlice';

export const rootReducer = combineReducers({
    audios,
    auth,
    profile,
    teacher,
    course,
    tests,
});
