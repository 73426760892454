import React, { useContext } from 'react';

import { Button } from '@c';
import { toClassNames } from '@helps/';

import { ViewContext } from '../../../../../../utils';

import styles from './style.module.scss';

const ButtonsBlock = ({ className = '', containerStyle = {} }) => {
    const { control } = useContext(ViewContext);

    return (
        <div
            className={toClassNames(styles.buttons, className)}
            style={containerStyle}
        >
            <Button
                onClick={() => control?.toPrev()}
                disabled={control.currentIdx === 0}
            >
                Назад
            </Button>
            <Button
                onClick={() => control?.toNext()}
                disabled={!control?.transitionToNext}
            >
                Вперед
            </Button>
        </div>
    );
};

export { ButtonsBlock };
