import React, { useRef, useState } from 'react';

import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import styles from './style.module.scss';
import { toClassNames } from '@helps';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

const Corusel = ({
    items = [],
    swiperClassName = '',
    swiperStyle = {},
    containerClassName = '',
    containerStyle = {},
    autoHeight = true,
}) => {
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    const [currentItem, setCurrentItem] = useState(0);

    return (
        <div
            className={toClassNames(styles.corusel, containerClassName)}
            style={containerStyle}
        >
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                loop={true}
                keyboard={{
                    enabled: true,
                }}
                onSlideChange={({ realIndex }) => setCurrentItem(realIndex)}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
                className={toClassNames(styles.my_swiper, swiperClassName)}
                style={swiperStyle}
                autoHeight={autoHeight}
            >
                {items.map((el, i) => (
                    <SwiperSlide className={styles.slide} key={i}>
                        {/* <div className={styles.slide_content}>{el}</div> */}
                        {el}
                    </SwiperSlide>
                ))}
                <div
                    ref={navigationPrevRef}
                    className={styles.swiper_button_prev}
                >
                    <FiChevronLeft />
                </div>
                <div
                    ref={navigationNextRef}
                    className={styles.swiper_button_next}
                >
                    <FiChevronRight />
                </div>
            </Swiper>
            <div className={styles.pagination}>
                {items.map((el, i) => (
                    <div
                        key={i}
                        className={
                            i !== currentItem
                                ? styles.pagination_item
                                : styles.pagination_item_selected
                        }
                    >
                        {i === currentItem && <div />}
                    </div>
                ))}
            </div>
        </div>
    );
};

export { Corusel };
