import React, { useContext, useEffect, useMemo, useState } from 'react';

import { ViewContext, DialogContext, AleshaHeadContext } from '../../../utils';
import { addWikiDislike, addWikiLike } from '@a/commands';
import { TTS } from '@helps';

import styles from './style.module.scss';

const WhoIs = () => {
    const { whoIsPayload, setWhoIsPayload } = useContext(ViewContext);
    const {
        setCheckCommands,
        setRecord,
        record,
        value,
        resetText,
        setValue,
        setSelectedCommand,
        onStageChange,
        results,
    } = useContext(DialogContext);
    const { setAleshaSpeaking } = useContext(AleshaHeadContext);
    const audio = useMemo(() => new Audio(), []);

    const yes = useMemo(() => ['да', 'конечно', 'вроде', 'вроде да'], []);
    const no = useMemo(() => ['нет', 'не особо'], []);
    const yesNo = useMemo(() => [...yes, ...no], [yes, no]);

    const questions = useMemo(
        () => ({
            '': 'Я правильно ответил на твой вопрос?',
            yes: 'Отлично! Запомню это',
            no: 'Жаль. Не хочешь помочь мне, рассказав, какой ответ по твоему мнению правильный?',
            // noYes: 'Я очень рад, но, к сожалению сейчас не могу запомнить то, что ты расскажешь.',
            noYes: 'Я очень рад, расскажи свой ответ на этот вопрос. Когда закончишь рассказ, нажми кнопку "Подтвердить"',
            noNo: 'Хорошо, тогда запомню, что это не правильный ответ',
        }),
        []
    );
    const answers = useMemo(() => ({
        answerSaveSuccess: 'Спасибо, что рассказал, буду теперь отвечать так',
        answerConfirm:
            'Перечитай то что я понял, если что то не так, поправь и еще раз нажми кнопку подтвердить',
    }));
    const [currentQuestion, setCurrentQuestion] = useState('');

    const [tellMe, setTellMe] = useState(false);

    const getAndPlayAudio = async (text, onEnd = () => {}) => {
        // console.log(whoIsPayload);
        typeof whoIsPayload?.stopPevAudio === 'function' &&
            whoIsPayload.stopPevAudio();
        audio.pause();
        audio.currentTime = 0;
        setAleshaSpeaking(false);

        const res = (await TTS(text)).data;
        const url = URL.createObjectURL(res);

        audio.src = url;
        audio.onended = () => {
            typeof onEnd === 'function' && onEnd();
            setAleshaSpeaking(false);
            setRecord(true);
        };

        audio.play();
        setAleshaSpeaking(true);
        setRecord(false);
        setValue('');
        resetText();
    };

    useEffect(() => {
        setCheckCommands(false);
        setWhoIsPayload((prev) => ({
            ...prev,
            playAnswer: (command) => getAndPlayAudio(answers[command]),
        }));

        return () => {
            setCheckCommands(true);
            setRecord((prev) => prev || true);
        };
    }, []);

    useEffect(() => {
        onStageChange(yesNo);
    }, [yesNo]);

    useEffect(() => {
        if (
            record &&
            whoIsPayload.yesNo &&
            results.filter((el) => el >= 0.5).length > 0
        ) {
            if (tellMe) {
                if (results.indexOf(Math.max(...results)) < yes.length) {
                    setCurrentQuestion('noYes');
                    setWhoIsPayload((prev) => {
                        return {
                            ...prev,
                            type: 'add',
                            showExpectedResponse: false,
                        };
                    });
                    getAndPlayAudio(
                        questions.noYes,
                        () => {
                            addWikiDislike(whoIsPayload.info.id)
                                .then((res) => res.data)
                                .then((res) => {
                                    whoIsPayload.setInfo((prev) => ({
                                        ...prev,
                                        likes: res.likes,
                                        dislikes: res.dislikes,
                                    }));
                                });
                        }
                        // setSelectedCommand('');
                    );
                } else {
                    setCurrentQuestion('noNo');
                    getAndPlayAudio(questions.noNo, () => {
                        addWikiDislike(whoIsPayload.info.id)
                            .then((res) => res.data)
                            .then((res) =>
                                whoIsPayload.setInfo((prev) => ({
                                    ...prev,
                                    likes: res.likes,
                                    dislikes: res.dislikes,
                                    showExpectedResponse: true,
                                }))
                            );
                    });
                }

                return;
            }
            if (results.indexOf(Math.max(...results)) < yes.length) {
                setCurrentQuestion('yes');
                getAndPlayAudio(questions.yes, () => {
                    addWikiLike(whoIsPayload.info.id)
                        .then((res) => res.data)
                        .then((res) =>
                            whoIsPayload.setInfo((prev) => ({
                                ...prev,
                                likes: res.likes,
                                dislikes: res.dislikes,
                                showExpectedResponse: true,
                            }))
                        );
                    setWhoIsPayload((prev) => ({ ...prev, yesNo: false }));
                });
            } else {
                setCurrentQuestion('no');
                getAndPlayAudio(questions.no, () => {
                    setTellMe(true);
                });
            }
        }
    }, [results]);

    useEffect(() => {
        if (/назад/gm.test(value.toLowerCase())) {
            resetText();
            setValue('');

            setSelectedCommand('Команды', {});
        }
    }, [value]);

    return (
        <div className={styles.availableCommands}>
            <div>
                {typeof whoIsPayload.yesNo === 'boolean' &&
                whoIsPayload.yesNo ? (
                    <span>{questions[currentQuestion]}</span>
                ) : (
                    <span>{whoIsPayload.text}</span>
                )}
                <span>Для выхода скажи "назад"</span>
            </div>
            {typeof whoIsPayload.showExpectedResponse === 'boolean' &&
                whoIsPayload.showExpectedResponse && (
                    <div className={styles.expected_responses}>
                        <p className={styles.header}>Ожидаемые ответы:</p>
                        <div className={styles.list}>
                            {yesNo.map((el, i) => (
                                <div
                                    key={`${i}`}
                                    className={styles.list_item}
                                    onClick={() => {
                                        setValue(el);
                                    }}
                                >
                                    <p>{el}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
        </div>
    );
};

export { WhoIs };
