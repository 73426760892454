import { createSlice } from '@reduxjs/toolkit'
import { getVoiceByAvatar } from '@helps/'

const initialState = {
    // Успеваемость
    performance: [],
    // Темы
    themes: [],
    // Текущая команда
    selectedCommand: null,
    // Параметры озвучки, дуфолтное значение - Алёша
    voiceOptions: (() => getVoiceByAvatar())(),
    // Загрузка
    isLoading: false,
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {
        performanceStart: () => {},
        setPerformance: (state, { payload }) => { state.performance = payload },

        getThemesStart: () => {},
        setThemes: (state, { payload }) => { state.themes = payload },
        deleteThemes: (state, _) => { state.themes = null },

        changeSelectedCommand: (state, { payload }) => { state.selectedCommand = payload },

        toggleLoading: (state, { payload }) => { state.isLoading = payload },

        changeVoiceOptions: (state, { payload }) => { state.voiceOptions = getVoiceByAvatar(payload) }
    },

})

export const profileActions = profileSlice.actions

export const profile = profileSlice.reducer