import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Loader } from '@c';
import { ReadingView, FinishView, ErrorView } from './Views';
import { useActions } from '@h';
import { testsActions } from '@r/tests/testsSlice';

const ReadingSpeedView = ({
    seconds = 0,
    result = 0,
    speed = 0,
    value = '',
    stoped = false,
    onStop = () => {},
    onReset = () => {},
}) => {
    const {
        textIndex = 0,
        payload: { title = '', author = '', text = '', length = 0 },
    } = useSelector(
        ({
            tests: {
                perсeptionType: {
                    1: { textIndex, payload },
                },
            },
        }) => ({
            textIndex,
            payload,
        })
    );
    const { getNewPerceptionText } = useActions(testsActions);

    const [stopped, setStopped] = useState(false);
    const [_text, setText] = useState();
    const [_valueText, setValueText] = useState();
    const [textLoaded, setTextLoaded] = useState(false);

    const clearText = (text = '') => {
        return text
            .toLowerCase()
            .replace(
                /<\w+\/>|<\w+>|<\/\w+>|[$&+,:;=?@#|'<>.^*()%!-"–—\u00ad]/gm,
                ' '
            )
            .replace(/ё/gm, 'е')
            .replace(/\n/gm, '')
            .replace(/\s{2,}/gm, ' ');
    };

    useEffect(() => {
        onReset();
        setStopped(false);
        const tmp = clearText(text);
        setText(tmp);
        setTextLoaded(true);
    }, [text]);

    useEffect(() => {
        setStopped(stoped);
    }, [stoped]);

    useEffect(() => {
        const tmp = clearText(value);

        setValueText(tmp);
    }, [value]);

    return (
        <>
            {stopped ? (
                // currentLenght /*&& currentLenght < 0.8 * length*/ ?
                // <ErrorView
                //     onRestart={() => {
                //         onReset()
                //         setStopped(false)
                //     }}
                // />
                // :
                <FinishView
                    timerSeconds={seconds}
                    title={title}
                    author={author}
                    length={length}
                    text1={_text}
                    text2={_valueText}
                    speed={speed}
                    result={result}
                    onRestart={() => {
                        getNewPerceptionText(textIndex);
                        setTextLoaded(false);
                    }}
                />
            ) : textLoaded ? (
                <ReadingView
                    timerSeconds={seconds}
                    title={title}
                    author={author}
                    text={text}
                    length={length}
                    onStop={() => {
                        onStop(seconds);
                        setStopped(true);
                    }}
                />
            ) : (
                <Loader />
            )}
        </>
    );
};

export { ReadingSpeedView };
