import { createSlice } from '@reduxjs/toolkit';

const testsSlice = createSlice({
    name: 'tests',
    initialState: {
        perсeptionType: [],
        isLoading: false,
    },
    reducers: {
        toggleLoading: (state, { payload }) => {
            state.isLoading = payload;
        },

        getPerceptionType: () => {},
        setPerceptionType: (state, { payload }) => {
            state.perсeptionType = payload;
        },

        getNewPerceptionText: () => {},
        setNewPerceptionTest: (state, { payload }) => {
            console.log(payload);
            state.perсeptionType[1] = {
                ...state.perсeptionType[1],
                ...payload,
            };
        },
    },
});

const testsActions = testsSlice.actions;

const tests = testsSlice.reducer;

export { testsSlice, testsActions, tests };
