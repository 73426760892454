import React, { useState, useRef, useEffect } from 'react';

import { AudioPlayer, Corusel, YouTubeCorusel } from '@c/';
import { addVideoLike, deleteVideoLike, addVideoView } from '@a/course';

import { FiChevronsDown } from 'react-icons/fi';

import styles from './style.module.scss';

const Text = ({ data = {} }) => {
    const blockRef = useRef(null);
    const payloadRef = useRef(null);
    const textRef = useRef(null);

    const [displaButton, setDisplayButton] = useState(true);
    const [_data, setData] = useState({ ...data });
    const [likeAdding, setLikeAdding] = useState(false);

    useEffect(() => {
        if (blockRef.current && payloadRef.current && textRef.current) {
            const blockHeight = blockRef.current.offsetHeight;
            const payloadHeight = payloadRef.current.offsetHeight;
            const textheight = textRef.current.offsetHeight;

            const scrollHandler = () => {
                if (
                    payloadHeight + textheight - blockRef.current.scrollTop <=
                    blockHeight
                ) {
                    setDisplayButton(false);
                } else {
                    setDisplayButton(true);
                }
            };

            blockRef.current.addEventListener('scroll', scrollHandler);

            return () => {
                if (blockRef.current && payloadRef.current && textRef.current) {
                    blockRef.current.removeEventListener(
                        'scroll',
                        scrollHandler
                    );
                }
            };
        }
    }, [blockRef, payloadRef]);

    useEffect(() => {
        return () => {
            if (_data?.videosInfo?.length > 0) {
                _data.videosInfo.map(
                    (el) => el && el.id && addVideoView(el.id)
                );
            }
        };
    }, []);

    const addLike = (videoId, index) => {
        let action = null;

        if (!_data.videosInfo[index].like_from_user) {
            action = addVideoLike(videoId);
        } else {
            action = deleteVideoLike(videoId);
        }

        action
            .then((res) => {
                if (res.status === 200) {
                    setData((prev) => {
                        const tmp = [...prev.videosInfo];
                        tmp[index] = res.data;
                        return { ...prev, videosInfo: tmp };
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setLikeAdding(false);
            });
    };

    return (
        <div ref={blockRef} className={styles.data_container}>
            <button
                onClick={() => {
                    blockRef.current.scrollTo({
                        top: payloadRef.current.scrollHeight,
                        behavior: 'smooth',
                    });
                }}
                style={{
                    display: displaButton ? 'block' : 'none',
                }}
                className={styles.scroll_button}
            >
                <FiChevronsDown color={'white'} size={'70%'} />
            </button>
            <div
                ref={textRef}
                dangerouslySetInnerHTML={{ __html: _data.text }}
                className={styles.text}
            />
            <div ref={payloadRef} className={styles.payload}>
                {_data.images.length > 0 && (
                    <div className={styles.image_container}>
                        <Corusel
                            items={_data.images.map((el) => (
                                <img src={el} />
                            ))}
                        />
                    </div>
                )}
                {_data.videos.length > 0 && (
                    <div className={styles.video_container}>
                        <YouTubeCorusel
                            videos={_data.videos}
                            infos={_data.videosInfo}
                            onLike={(videoId, index) => {
                                setLikeAdding(true);
                                addLike(videoId, index);
                            }}
                            likeAdding={likeAdding}
                        />
                    </div>
                )}
                {_data.audios.length > 0 && (
                    <div className={styles.audio_container}>
                        <AudioPlayer
                            withoutHook={true}
                            audios={_data.audios}
                            containerClassName={styles.audios}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export { Text };
