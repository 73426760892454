import React from 'react';
import styles from './Button.module.css';

export const Button = ({ children, mt, mr, mb, ml, className, ...props }) => {
    return (
        <button
            style={{
                marginTop: mt,
                marginRight: mr,
                marginBottom: mb,
                marginLeft: ml,
            }}
            className={`${styles.button} ${className}`}
            {...props}
        >
            {children}
        </button>
    );
};
