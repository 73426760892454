import React, { useEffect, useState } from 'react';

import { Button } from '@c';
import { Diff } from '../Diff';
import { ButtonsBlock } from '../../ButtonsBlock';

import styles from '../style.module.scss';

const FinishView = ({
    timerSeconds = 0,
    title = '',
    author = '',
    text1 = '',
    text2 = '',
    length = 0,
    speed = 0,
    result = 0,
    onRestart = () => {},
}) => {
    const [wordsWithErrors, setWordsWithErrors] = useState(0);

    const [_timerSeconds, setTimerSeconds] = useState('00');
    const [_timerMinutes, setTimerMinutes] = useState('00');

    useEffect(() => {
        const minutes = Math.floor(timerSeconds / 60).toFixed(0);
        setTimerMinutes(minutes > 9 ? minutes : `0${minutes}`);
        const _seconds = timerSeconds % 60;
        setTimerSeconds(_seconds > 9 ? _seconds : `0${_seconds}`);
    }, [timerSeconds]);

    return (
        <div className={styles.container}>
            <div className={styles.main}>
                {/* <div className={styles.timer}>
                    <p>{_timerMinutes}:{_timerSeconds}</p>
                </div> */}
                <div className={styles.text_container}>
                    <p className={styles.title}>{title}</p>
                    <p className={styles.author}>{author}</p>
                    <Diff
                        text1={text1}
                        text2={text2}
                        onChange={(val) => setWordsWithErrors(val)}
                    />
                    <p className={styles.text_lenght}>{length} слов</p>
                </div>
                <Button onClick={onRestart} className={styles.button}>
                    Попробовать снова
                </Button>
            </div>
            <div className={styles.result}>
                <p className={styles.header}>Результат:</p>
                <div className={styles.stats}>
                    <p>
                        Время чтения:{' '}
                        <b>
                            {_timerMinutes}:{_timerSeconds} минут
                        </b>
                    </p>
                    <p>
                        Скорость чтения: <b>{speed.toFixed(0)} слов/мин</b>
                    </p>
                    <p>
                        Ошибки допущены{' '}
                        <b>в {((1 - result) * 100).toFixed(1)}%</b> слов
                    </p>
                    <p>
                        Слов с ошибками: <b>{wordsWithErrors}</b>
                    </p>
                </div>
            </div>
            <ButtonsBlock />
        </div>
    );
};

export { FinishView };
