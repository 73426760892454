import React, { useEffect, useState } from 'react'
import { Handle, useStoreState } from 'react-flow-renderer'

import { FiTrash2 } from 'react-icons/fi'

import styles from './style.module.css'

const ImageDemonstration = ({
    onDragStart = () => { }
}) => {
    return (
        <div className={styles.container_demonstration} onDragStart={onDragStart} draggable>
            <p>Изображение</p>
        </div>
    )
}

const dataInit = {
    url: '',
    onChange: () => { },
    onRemove: () => { }
}

const Image = ({
    id = '',
    data = {}
}) => {
    const [_data, setData] = useState({ ...dataInit, ...data })

    const [, , zoom] = useStoreState(state => state.transform)
    const showContent = zoom >= 0.6

    useEffect(() => {
        _data.onChange(id, _data)
    }, [_data, id])

    return (
        <div className={styles.container}>
            <Handle type='target' position='left' id='in' className={styles.point_in} />
            <FiTrash2
                className={styles.icon}
                onClick={() => data.onRemove(id)}
            />
            <p>Изображение</p>
            {showContent ?
                <>
                    <input
                        value={_data.url}
                        onChange={({ target: { value } }) => setData(prev => ({ ...prev, url: value }))}
                        placeholder='Ссылка на изображение'
                    />
                    {_data.url ?
                        <img
                            src={_data.url}
                            alt='Изображение'
                        />
                        :
                        <div className={`${styles.skeleton} ${styles.skeleton__image}`} />
                    }
                </>
                :
                <>
                    <div className={`${styles.skeleton} ${styles.skeleton__input}`} />
                    <div className={`${styles.skeleton} ${styles.skeleton__image}`} />
                </>
            }
            <Handle type='source' position='right' id='out' className={styles.point_out} />
        </div>
    )
}

export {
    Image,
    ImageDemonstration
}