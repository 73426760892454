import React, { useState } from 'react'

import { toClassNames } from '@helps'

import { FiInfo } from 'react-icons/fi'

import styles from './style.module.scss'

const TextArea = ({
    value = '',
    onChange = () => { },
    infoElement = <></>
}) => {
    const [infoHover, setInfoHover] = useState(false)
    const [infoTimer, setInfoTimer] = useState(null)

    const onMouseEnter = () => {
        setInfoHover(true)
    }
    const onMouseLeave = () => {
        setInfoHover(false)
    }

    return (
        <div className={styles.container}>
            <FiInfo
                className={styles.info_icon}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            />
            <div
                className={toClassNames(styles.info_block, infoHover && styles.info_block__visable)}
                // className={toClassNames(styles.info_block, styles.info_block__visable)}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {infoElement}
            </div>
            <textarea
                value={value}
                onChange={onChange}
            />
        </div>
    )
}

export { TextArea }