import React from 'react';
import moment from 'moment';

import { toClassNames } from '@helps';

import { FiUser, FiStar } from 'react-icons/fi';

import styles from './style.module.scss';

const Review = ({ review }) => {
    return (
        <div className={styles.review_container}>
            <div className={styles.title}>
                <div className={styles.author_container}>
                    <div className={styles.avatar}>
                        <FiUser />
                    </div>
                    <div className={styles.author}>
                        <span className={styles.name}>{review.user_name}</span>
                        <div className={styles.stars}>
                            {Array(5)
                                .fill(null)
                                .map((_, i) => (
                                    <FiStar
                                        key={i}
                                        className={toClassNames(
                                            styles.star,
                                            i <= review.rating - 1 &&
                                                styles.star__selected
                                        )}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
                <div className={styles.date}>
                    <span>
                        {moment(review.create_date).format('DD.MM.YY HH:MM')}
                    </span>
                </div>
            </div>
            <div className={styles.comment}>
                <span>{review.review || 'Комментарий отсутствует'}</span>
            </div>
        </div>
    );
};

export { Review };
