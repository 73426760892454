import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { rootSaga } from './rootSaga'
import { rootReducer } from './rootReducer'
import createSagaMiddleware from 'redux-saga'

const initialState = {}

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: rootReducer,
  initialState,
  middleware: [...getDefaultMiddleware({ thunk: false }), sagaMiddleware],
})

sagaMiddleware.run(rootSaga)


window.store = store