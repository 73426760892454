import { api } from '../App'

export const login = async img => {
    const fd = new FormData()
    fd.append('file', img)
    return api.post('/login', fd, { withCredentials: true })
}

export const loginByForm = data => {
    return api.post('/login', data, { withCredentials: true })
}

export const checkAuth = () => {
    return api.get('/logincheck')
}

export const logout = () => {
    api.post('/logout')
}

export const changeTypeInformationPercept = data => {
    return api.put('/people/typeinformationperceptset', { typeinformationpercept: data }, { withCredentials: true })
}

export const schoolListRequest = () => api.get('/schools')

export const loginByFace = (data) =>
    api.post('/login', data, { headers: { "Content-Type": "multipart/form-data" } })

export const addFace = data =>
    api.post('/people/addFace', data, { headers: { "Content-Type": "multipart/form-data" } })

export const deleteFace = data =>
    api.put('/people/deleteFace', data, { headers: { "Content-Type": "multipart/form-data" } })