import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { authActions } from '@r/auth/authSlice';
import { useActions } from '@h/useActions';
import { api } from '../../../App';
import logo from '@img/logo.png';
import mil from '@img/mil.png';
import { toClassNames } from '@helps';
import { Loader } from '@c';
import { getAutologout } from '../utils';

import styles from './style.module.scss';
import { FiUser, FiSettings, FiLogOut, FiUserX } from 'react-icons/fi';

export const Header = () => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const {
        data: authData,
        faceScreenshot: face,
        isLoading,
        isFaceDeleted,
    } = useSelector(({ auth }) => auth);
    const {
        logoutStart,
        typeInformationPerceptChange,
        faceDeleteStart,
        setIsFaceDeleted,
    } = useActions(authActions);

    const [img, setImg] = useState('');
    const [autologout, setAutologout] = useState(false);

    useEffect(() => {
        setAutologout(getAutologout());
    }, []);

    useEffect(
        () =>
            !img &&
            authData &&
            authData.uid &&
            api
                .get(`people/photo/${authData?.uid}`, {
                    responseType: 'arraybuffer',
                })
                .then((response) =>
                    Buffer.from(response.data, 'binary').toString('base64')
                )
                .then((base64) => {
                    setImg('data:image/jpeg;base64,' + base64);
                }),
        [authData, img]
    );

    useEffect(() => {
        if (isFaceDeleted) {
            enqueueSnackbar('Лицо успешно отвзяно', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            });
            setIsFaceDeleted(false);
        }
    }, [isFaceDeleted]);

    useEffect(() => {
        window.localStorage.setItem('autologout', autologout);
    }, [autologout]);

    return (
        authData && (
            <div className={styles.header}>
                <img src={mil} alt={'o_O'} className={styles.mil} />
                <div className={styles.main}>
                    <div className={styles.description}>
                        <div className={styles.alesha}>
                            Цифровой голосовой ассистент «Алеша»
                        </div>
                        <p className={styles.student_first}>
                            {authData.school}
                        </p>
                        <p className={styles.student}>
                            {authData.lastname} {authData.firstname} -{' '}
                            {authData.class}
                        </p>
                    </div>
                    <div className={styles.type_select_container}>
                        <FormControl variant="standard">
                            {/* <InputLabel color='secondary' id='typeSelectLabel'>Тип восприятия</InputLabel> */}
                            <div className={styles.select_title}>
                                Тип восприятия:
                            </div>
                            <Select
                                className={styles.type_select}
                                id="typeSelect"
                                disableUnderline={true}
                                labelId="typeSelectLabel"
                                value={
                                    authData?.typeInformationPercept || 'null'
                                }
                                onChange={({ target: { value } }) => {
                                    typeInformationPerceptChange(value);
                                }}
                            >
                                <MenuItem value="null">
                                    <em>Не выбрано</em>
                                </MenuItem>
                                <MenuItem value="Визуал">Визуал</MenuItem>
                                <MenuItem value="Аудиал">Аудиал</MenuItem>
                                <MenuItem value="Кинестетик">
                                    Кинестетик
                                </MenuItem>
                                <MenuItem value="Дискрет">Дискрет</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className={styles.avatar}>
                        {img ? <img src={img} alt={'o_O'} /> : <FiUser />}
                    </div>
                    <div className={styles.settings}>
                        <FiSettings size={20} color="white" />
                        <div className={styles.settings_view}>
                            <div
                                className={toClassNames(
                                    styles.setting,
                                    (!authData.faces || !face) &&
                                        styles.setting__disabled
                                )}
                                onClick={() =>
                                    authData.faces && face && faceDeleteStart()
                                }
                                title={(() => {
                                    if (!authData.faces)
                                        return 'У пользователя нет привязанных лиц. Вы можете привязать свое, для этого необходимо авторизоваться по лицу';
                                    if (!face)
                                        return 'Для того, чтобы отвзяать лицо, необходима повторная авторизация по лицу';
                                })()}
                            >
                                {isLoading ? (
                                    <Loader
                                        className={styles.loader}
                                        color="#006eff"
                                        size={20}
                                        borderWidth={2}
                                    />
                                ) : (
                                    <FiUserX size={20} />
                                )}
                                <span>Отвязать лицо</span>
                            </div>
                            <div
                                className={styles.setting}
                                onClick={() => {
                                    logoutStart();
                                    history.push('/');
                                }}
                            >
                                <FiLogOut size={20} />
                                <span>Выход</span>
                            </div>
                            <div
                                className={styles.setting}
                                title="При перезагрузке страница попадать на экран авторизации"
                            >
                                <input
                                    type="checkbox"
                                    checked={autologout}
                                    onChange={() => setAutologout(!autologout)}
                                />
                                <span>Автоматический выход</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={logo} alt={'o_O'} className={styles.logoLms} />
            </div>
        )
    );
};
