import React, {
    useEffect,
    useState,
    useContext,
    useMemo,
    useCallback,
    useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { Loader, Button } from '@c';
import { useActions } from '@h';
import { getVoiceByAvatar, toClassNames } from '@helps/';
import { useLoadAudioFile } from '@h/useLoadAudioFile';
import { courseActions } from '@r/courses/courseSlice';

import { createReview } from '@a/course';

import * as Blocks from './BlockTypes';
import { Input } from './Input';
import { LessonContext, CONSTANTS } from '../utils';

import { Review } from './Review';
import {
    DialogContext,
    aleshaHeadContextInitState,
} from '../../../../../utils';

import { FiSquare, FiMic, FiChevronLeft } from 'react-icons/fi';
import styles from './style.module.scss';

const defaultVoice = getVoiceByAvatar(aleshaHeadContextInitState.headData.name);

const Course = ({ onCurseEnd = () => {}, onMenu = () => {} }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { record, setRecord, translated, resetText, translatedText, clean } =
        useContext(DialogContext);
    const { setHeadAnim } = useContext(LessonContext);

    const [review, setReview] = useState(false);
    const [reviewValue, setReviewValue] = useState({});

    const { data: authData } = useSelector(({ auth }) => auth);
    const { isLoading, currentNode, currentTheme } = useSelector(
        ({ course }) => course
    );
    const { currentNodeUpdate, currentNodeStart } = useActions(courseActions);

    const [value, setValue] = useState('');
    const [showModal, setShowModal] = useState(true);
    const [cleaning, setCleaning] = useState(false);

    const wordsToNextRegexp = useMemo(
        () => new RegExp(CONSTANTS.WORDS_TO_NEXT.join('|')),
        []
    );
    const wordsToControlRegexp = useMemo(
        () => new RegExp(CONSTANTS.WORDS_TO_CONTROL.join('|')),
        []
    );

    const dataPromiseCancelRef = useRef(null);
    const { getFileUrl } = useLoadAudioFile(dataPromiseCancelRef);

    const audio = useMemo(() => new Audio(), []);

    const stopAudio = () => {
        if (
            !dataPromiseCancelRef.current.finished &&
            !dataPromiseCancelRef.current.cancelled
        ) {
            console.log('canceling');
            dataPromiseCancelRef.current.cancel();
        }
        setHeadAnim(false);
        audio.pause();
        resetText();
    };

    const [warningUrl, setWarningUrl] = useState('');
    const playWarning = () => {
        if (warningUrl === '') {
            getFileUrl(
                {
                    text: 'Для того, чтобы продолжить необходимо выбрать ответы.',
                    options: defaultVoice,
                },
                () => {
                    setRecord(false);
                    audio.pause();
                },
                (url) => (setWarningUrl(url), playAudioByUrl(url))
            );
        } else {
            playAudioByUrl(warningUrl);
        }
    };

    /**
     * @callback getAndPlayAudioCallback
     * @param {String} url
     * @returns {void}
     *
     * @param {String} text
     * @param {getAndPlayAudioCallback} onEnd
     */
    const getAndPlayAudio = (text, onEnd = () => {}) => {
        getFileUrl(
            {
                text: text,
                options: defaultVoice,
            },
            () => {
                record && setRecord(false);
                audio.pause();
            },
            (url) => playAudioByUrl(url, onEnd)
        );
    };
    const playAudioByUrl = useCallback(
        (url, onEnd = () => {}) => {
            audio.currentTime = 0;
            audio.src = url;
            audio.onended = () => {
                setHeadAnim(false);
                setRecord(true);
                onEnd();
            };

            audio.play();
            setHeadAnim(true);
            record && setRecord(false);
        },
        [record, audio]
    );

    const toNext = (fromButton = false) => {
        console.log(isLoading, cleaning);
        if ((!isLoading && !cleaning) || fromButton) {
            stopAudio();
            setShowModal(false);
            currentNodeUpdate({
                courseId: currentTheme.id,
                type: authData.typeInformationPercept,
                answer: value,
            });
            setCleaning(true);
            setValue('');
            resetText();
        }
    };
    const toPrev = () => {
        if (!isLoading) {
            stopAudio();
            setShowModal(false);
            currentNodeUpdate({
                courseId: currentTheme.id,
                type: authData.typeInformationPercept,
                prev: true,
            });
            setValue('');
            resetText();
        }
    };
    const sendReview = useCallback(async () => {
        if (reviewValue?.selected) {
            const result = await createReview(currentTheme.id, reviewValue);

            if (result.status !== 204) {
                enqueueSnackbar(
                    'При создании отзыва произошла ошибка. Отзыв не сохранен',
                    {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                    }
                );
            }
        }

        onCurseEnd();
    }, [reviewValue]);

    useEffect(() => {
        console.log(translated?.alternatives[0].text);
        if (
            typeof translated?.alternatives[0].text === 'string' &&
            !!translated?.alternatives[0].text &&
            cleaning &&
            // !wordsToNextRegexp.test(translated?.alternatives[0]?.text)
            !new RegExp(CONSTANTS.WORDS_TO_NEXT.join('|')).test(
                translated.alternatives[0].text
            )
            // !wordsToNextRegexp.test(translated?.alternatives[0].text)
        ) {
            console.log(
                1222,
                translated?.alternatives[0].text
                // wordsToNextRegexp
            );
            setCleaning(false);
        }
    }, [translated, cleaning]);

    const [textCleanup, setTextCleanup] = useState(null);
    useEffect(() => {
        setShowModal(false);
        if (value === '') {
            clearTimeout(textCleanup);
            setTextCleanup(null);
            setShowModal(false);
            return;
        }
        if (value) {
            if (currentNode?.type === 'question') {
                if (currentNode.data.type !== 'multi') {
                    textCleanup && clearTimeout(textCleanup);
                    setTextCleanup(
                        setTimeout(() => {
                            if (currentNode.data.detailedResponse) {
                                setShowModal(true);
                            } else {
                                toNext();
                            }
                            setTextCleanup(null);
                        }, 3500)
                    );
                }
            }
        }
    }, [value]);
    useEffect(async () => {
        if (showModal) {
            if (translated?.alternatives[0].text.toLowerCase().includes('да')) {
                toNext();
                setShowModal(false);
                setValue('');
            }
            if (
                translated?.alternatives[0].text.toLowerCase().includes('нет')
            ) {
                setShowModal(false);
            }
            return;
        }
        if (currentNode?.type === 'question') {
            if (!isLoading) {
                if (currentNode.data.type !== 'multi') {
                    setShowModal(false);
                    setValue(translated?.alternatives[0].text);
                    return;
                } else {
                    // if (translated?.isFinal) {
                    //     setValue(translated?.alternatives[0].text);
                    // }

                    if (
                        !cleaning &&
                        wordsToNextRegexp.test(
                            translated?.alternatives[0]?.text
                        )
                        // new RegExp(CONSTANTS.WORDS_TO_NEXT.join('|')).test(
                        //     translated?.alternatives[0].text
                        // )
                        // wordsToNextRegexp.test(translated?.alternatives[0].text)
                    ) {
                        if (!Array.isArray(value) || value.length === 0) {
                            if (audio.paused && record) {
                                playWarning();
                            }
                            return;
                        }

                        toNext();
                        return;
                    }
                }
            } else {
                resetText();
                setValue('');
            }
            return;
        }
        if (
            !cleaning &&
            wordsToNextRegexp.test(translated?.alternatives[0]?.text)
            // new RegExp(CONSTANTS.WORDS_TO_NEXT.join('|')).test(
            //     translated?.alternatives[0].text
            // )
            // wordsToNextRegexp.test(translated?.alternatives[0].text)
        ) {
            resetText();
            toNext();
            return;
        }
        if (
            wordsToControlRegexp.test(translated?.alternatives[0]?.text)
            // new RegExp(CONSTANTS.WORDS_TO_CONTROL.join('|')).test(
            // translated?.alternatives[0].text
            // )
            // wordsToControlRegexp.test(translated?.alternatives[0].text)
        ) {
            if (currentNode?.type === 'finish') {
                setRecord(false);
                resetText();
                onCurseEnd();
            } else {
                setRecord(false);
                resetText();
                onMenu();
            }
            clean(CONSTANTS.WORDS_TO_CONTROL);
            return;
        }
    }, [translated, currentNode, cleaning]);

    // useEffect(() => {
    // console.log(record);
    // }, [record]);

    useEffect(() => {
        if (!!currentNode) {
            setCleaning(true);
        }

        if (!currentNode && authData) {
            clearTimeout(textCleanup);
            setShowModal(false);
            currentNodeStart({
                courseId: currentTheme.id,
                type: authData.typeInformationPercept,
            });
        }
    }, [currentTheme, currentNode]);

    useEffect(() => {
        setValue('');
        if (currentNode) {
            // console.log(currentNode.data, currentNode.data?.speech);
            if (currentNode.data?.speech) {
                playAudioByUrl(
                    `${process.env.REACT_APP_API_URL}${currentNode.data.speech}`
                );
                return;
            }
            if (currentNode.type === 'finish') {
                getAndPlayAudio(
                    `Спасибо, что посетил электронное занятие по теме "${currentTheme.theme}"`
                );
            }
        }
    }, [currentNode]);

    useEffect(() => {
        return () => {
            stopAudio();
            setRecord(false);
        };
    }, []);

    return (
        <div className={styles.main}>
            {/* <Button onClick={() => setRecord(!record)} className={styles.test}>
                TEST
            </Button> */}
            {showModal && (
                <div className={styles.modal_container}>
                    <div className={styles.modal_view}>
                        <span>Вы закончили ввод?</span>
                        <div className={styles.buttons}>
                            <Button className={styles.yes} onClick={toNext}>
                                Да
                            </Button>
                            <Button
                                className={styles.no}
                                onClick={() => setShowModal(false)}
                            >
                                Нет
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            <div className={styles.theme}>{currentTheme.theme}</div>
            {JSON.stringify(currentNode) !== '{}' ? (
                <>
                    <div className={styles.container}>
                        {review ? (
                            <Review
                                onRatingSelect={() => onCurseEnd()}
                                themeId={currentTheme.id}
                                onChange={(value) => setReviewValue(value)}
                            />
                        ) : !currentNode?.type || isLoading ? (
                            <Loader
                                color="rgb(0, 110, 255)"
                                className={styles.loader}
                            />
                        ) : (
                            React.createElement(Blocks[currentNode.type], {
                                data: currentNode.data,
                                onAnswerChange: (val) => setValue(val),
                                value: translated?.isFinal
                                    ? translated?.alternatives[0].text
                                    : '',
                            })
                        )}
                    </div>
                    <div className={styles.buttons}>
                        <div className={styles.control_container}>
                            <div
                                className={toClassNames(styles.icon_container)}
                                onClick={() => toPrev()}
                                title="Назад"
                            >
                                <FiChevronLeft
                                    className={toClassNames(styles.icon)}
                                />
                            </div>
                            {currentNode?.type === 'question' ? (
                                currentNode.data.type === 'multi' ? (
                                    <Button
                                        className={styles.button}
                                        onClick={() => {
                                            if (!isLoading) {
                                                if (
                                                    !Array.isArray(value) ||
                                                    value.length === 0
                                                ) {
                                                    playWarning();
                                                } else {
                                                    toNext(true);
                                                }
                                            }
                                        }}
                                        title={'Дальше (ctrl + enter)'}
                                        disabled={isLoading}
                                    >
                                        Дальше
                                    </Button>
                                ) : (
                                    <Input
                                        withYesHelps={
                                            currentNode.data.type === 'yesNo'
                                        }
                                        value={value}
                                        setValue={setValue}
                                        onSend={() => !isLoading && toNext()}
                                    />
                                )
                            ) : currentNode?.type === 'finish' ? (
                                review ? (
                                    <Button
                                        className={styles.button}
                                        onClick={() => sendReview()}
                                        disabled={isLoading}
                                    >
                                        Оставить отзыв
                                    </Button>
                                ) : (
                                    <Button
                                        className={styles.button}
                                        onClick={() => setReview(true)}
                                        disabled={isLoading}
                                    >
                                        Завершить занятие
                                    </Button>
                                )
                            ) : (
                                <Button
                                    className={styles.button}
                                    onClick={() => toNext(true)}
                                    title={'Дальше (ctrl + enter)'}
                                    disabled={isLoading}
                                >
                                    Дальше
                                </Button>
                            )}
                            <div
                                className={toClassNames(
                                    styles.icon_container,
                                    audio.paused &&
                                        styles.icon_container__disable
                                )}
                                onClick={() =>
                                    !audio.paused &&
                                    (stopAudio(), setRecord(true))
                                }
                                title="Пауза"
                            >
                                <FiSquare
                                    className={toClassNames(
                                        styles.icon,
                                        audio.paused
                                            ? styles.icon__pause
                                            : styles.icon__play
                                    )}
                                />
                            </div>
                            <div
                                className={toClassNames(
                                    styles.mic_container,
                                    styles.icon_container,
                                    record && styles.mic_container__active,
                                    !audio.paused &&
                                        styles.icon_container__disable
                                )}
                                title="Вкл/Выкл микрофон"
                            >
                                <FiMic
                                    className={toClassNames(
                                        styles.icon,
                                        record && styles.rec
                                    )}
                                    onClick={() => {
                                        if (!record) setValue('');
                                        setRecord(!record);
                                    }}
                                    size={25}
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className={styles.container}>
                    <div className={styles.not_found}>Курс не найден</div>
                </div>
            )}
        </div>
    );
};

export { Course };
