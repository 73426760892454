import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { PrivateRoute, Layout } from '@c';
import { Auth, Home, TeacherAuth, TeacherHome, TestPage } from '@p';

export const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/teacher" component={TeacherAuth} />
                <Route exact path="/teacherhome" component={TeacherHome} />
                <Route exact path="/test" component={TestPage} />
                <Route
                    path="/"
                    render={(history) => (
                        <Layout history={history}>
                            <Route exact path="/auth" component={Auth} />
                            <PrivateRoute exact path="/" component={Home} />
                        </Layout>
                    )}
                />
            </Switch>
        </BrowserRouter>
    );
};
