import React from 'react'
import styles from './Card.module.css'

export const Card = ({ title, src, onClick, disable = false }) => {
  return (
    <div
      className={`${styles.wrapper} ${disable && styles.disable}`}
      onClick={() => !disable && onClick()}
    >
      <img src={src} alt={'o_O'} />
      <p>{title}</p>
    </div>
  )
}
