import React, { useState, useCallback, useEffect, useContext } from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import MultiBackend, { TouchTransition } from 'react-dnd-multi-backend';

import { Container } from './Container';
import { Item } from './Item';
import { DnDContextProvider } from './dndContext';
import { ButtonsBlock } from '../ButtonsBlock';
import { AleshaHeadContext } from '../../../../../../utils';
import { DnDContext } from './dndContext';

import styles from './style.module.scss';

const types = {
    ITEM: 'item',
};

const HTML5toTouch = {
    backends: [
        {
            backend: HTML5Backend,
        },
        {
            backend: TouchBackend,
            options: { enableMouseEvents: true }, // Note that you can call your backends with options
            preview: false,
            transition: TouchTransition,
        },
    ],
};

const DnDAnswerView = ({
    question = '',
    payload: { description = '', items = [] },
    onChange = () => {},
}) => {
    const [containers, setConatiners] = useState([
        { accepts: [types.ITEM], droppedItems: [] },
        { accepts: [types.ITEM], droppedItems: [] },
    ]);

    const [_items, setItems] = useState([]);
    const [droppedItems, setDroppedItems] = useState([]);
    const [currentIdx, setCurrentIdx] = useState(0);

    const isDropped = (name) => !!droppedItems.find((el) => el.name === name);

    const dropHandler = useCallback(
        (item, idx) => {
            // Обновляем значение уже предметов, которые уже перемещены
            setDroppedItems((prev) => {
                let tmp = [...prev];
                // Если предмет уже есть в спике, ничего не меняем
                if (tmp.find((el) => el.name === item.name)) return prev;
                // Если предмета нет, добавляем
                else tmp.push(item);
                return [...tmp];
            });

            // Устанавливаем значение контейнеров
            setConatiners((prev) => {
                let _prev = [...prev];
                // Обходим массив
                _prev = _prev.map((el, i) => {
                    // Если предмет есть в массиве
                    if (
                        el.droppedItems.find((el) => el.name === item.name) !==
                        undefined
                    ) {
                        // Удаляем из массива
                        el.droppedItems = el.droppedItems.filter(
                            (el) => el.name !== item.name
                        );
                    }
                    if (i === idx) el.droppedItems.push(item);

                    return el;
                });
                return [..._prev];
            });
        },
        [setDroppedItems, setConatiners]
    );

    useEffect(() => {
        onChange({
            like: [],
            notLike: [],
        });

        return () => {
            setConatiners([
                { accepts: [types.ITEM], droppedItems: [] },
                { accepts: [types.ITEM], droppedItems: [] },
            ]);
        };
    }, []);

    useEffect(() => {
        setItems(
            items
                // Эта страка добавлена для упрощения тестирования
                // .slice(0, 4)
                //
                .map((item) => ({
                    ...item,
                    type: types.ITEM,
                }))
        );
    }, [items]);

    useEffect(() => {
        // Вызываем onChange
        const tmp = containers.map((el) =>
            el.droppedItems.map((el) => el.name)
        );

        onChange({
            like: tmp[0],
            notLike: tmp[1],
        });
    }, [containers]);

    useEffect(() => {
        // устанавливаем текущий индекс
        if (droppedItems.length > 0) setCurrentIdx((prev) => prev + 1);
    }, [droppedItems]);

    return (
        <div className={styles.root}>
            <p className={styles.question}>{question}</p>
            <p className={styles.description}>{description}</p>
            <div className={styles.baskets}>
                <DnDContextProvider>
                    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                        <div className={styles.container}>
                            <div className={styles.main}>
                                <Container
                                    key={`0`}
                                    accept={containers[0].accepts}
                                    droppedItems={containers[0].droppedItems}
                                    onDrop={(item) => dropHandler(item, 0)}
                                    description={'СКОРЕЕ НРАВИТСЯ'}
                                />
                                <div className={styles.current_item_container}>
                                    {droppedItems.length < _items.length ? (
                                        <>
                                            <div className={styles.item_name}>
                                                <p>{_items[currentIdx].name}</p>
                                            </div>
                                            <div
                                                className={
                                                    styles.item_container
                                                }
                                            >
                                                <Item
                                                    droppedItemsCount={
                                                        droppedItems.length
                                                    }
                                                    item={_items[currentIdx]}
                                                    isDropped={isDropped(
                                                        _items[currentIdx].name
                                                    )}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <div
                                            className={styles.void_container}
                                        />
                                    )}
                                </div>
                                {/* <div class={styles.item_container}>
                                    {_items?.map((el, i) =>
                                        <Item
                                            item={el}
                                            key={`${i}`}
                                            isDropped={isDropped(el.name)}
                                        />
                                    )}
                                </div> */}
                                <Container
                                    key={`1`}
                                    accept={containers[1].accepts}
                                    droppedItems={containers[1].droppedItems}
                                    onDrop={(item) => dropHandler(item, 1)}
                                    description={'СКОРЕЕ НЕ НРАВИТСЯ'}
                                />
                            </div>
                            {/* {dragsElemens &&
                                <div className={styles.item_name}>
                                    <p>{dragsElemens.name}</p>
                                </div>
                            } */}
                        </div>
                    </DndProvider>
                </DnDContextProvider>
            </div>
            {droppedItems.length === _items.length && <ButtonsBlock />}
        </div>
    );
};

export { DnDAnswerView };
