import {
    createContext,
    useState,
    useContext,
    useMemo,
    useCallback,
} from 'react';

import { AleshaHeadContext, ViewContext } from '../../../../../../utils';

const DnDContext = createContext({
    dragsElemens: [],
    setDragsElements: () => {},
    /**
     * Воспроизведение аудио по ссылке
     * @param {String} url - ссылка
     * @param {Function} onEnd - каллбэк после конца аудио
     */
    playAudio(url, onEnd) {},
    /**
     * Остановка воспроизведения аудио
     */
    stopAudio() {},
});

const DnDContextProvider = ({ children }) => {
    const { lessonPayload } = useContext(ViewContext);
    const { setAleshaSpeaking } = useContext(AleshaHeadContext);

    const [dragsElemens, setDragsElements] = useState([]);

    /**@type {HTMLAudioElement} */
    const audio = useMemo(() => new Audio(), []);

    const stopAudio = (withPayloadStop = true) => {
        // Остановка воспроизведения воспроса
        if (
            withPayloadStop &&
            lessonPayload.stopAudio &&
            typeof lessonPayload.stopAudio === 'function'
        ) {
            console.log(1);
            lessonPayload.stopAudio(false);
        }
        // Остановка воспроизведния текущего аудио
        audio.pause();
        audio.currentTime = 0;
        setAleshaSpeaking(false);
    };

    const playAudio = (url, onEnd = () => {}, withPayloadStop = true) => {
        try {
            console.log(withPayloadStop);
            stopAudio(withPayloadStop);

            audio.onended = () => {
                typeof onEnd === 'function' && onEnd();
                setAleshaSpeaking(false);
            };

            // console.log(url);
            audio.src = url;
            audio.play();
            setAleshaSpeaking(true);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <DnDContext.Provider
            value={{
                dragsElemens,
                setDragsElements,
                playAudio,
                stopAudio,
            }}
        >
            {children}
        </DnDContext.Provider>
    );
};

export { DnDContext, DnDContextProvider };
