import { api } from '../App';
import { store } from '@r/store';

/**
 * Перевод строки в голос
 * @param {string} string строка для перевода
 */
function TTS(string, options = null) {
    let _options;

    if (options === null) {
        _options = store.getState().profile.voiceOptions;
    } else {
        if (typeof options === 'object' && !Array.isArray(options)) {
            _options = options;
        } else {
            const err = Error('Неверный формат параметров для озвучки');
            throw err;
        }
    }

    return api.post(
        '/yc/speech_synthesize',
        {
            text: string,
            ..._options,
        },
        { responseType: 'blob' }
    );
}

export { TTS };
